import { useReducer } from "react";
import React, { useEffect, useState } from "react";

import { Accordion, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";


// import '../../../../style/Compute/shareModal.scss'
import '../../../../../style/Infra/shareModal.scss'
import { commonConstants } from "../../../../../constants/constant";

const initialState = {
  type: "",
  accountId: "",
  accountIdError: "",
  showAccountIds: false,
  enteredAccountNumbers: [],
  allowUsersToCreateVolume: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const ShareModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const handleChange = (type) => {
    setData({ type: type });
  };
  const selectedRow = props?.selectedRows ? props?.selectedRows[0] : {};

  const onShare = () => {
    const postbody = [
      {
        amiId: selectedRow?.imageId,
        newName: selectedRow?.imageName,
        operation: commonConstants.share,
        accountId: selectedRow?.accountNumber,
        shareAccountId: state.accountId,
        destinationRegion: null,
      },
    ];
    props.onShare(postbody);
    props.onHide();
  };

  const onChangeAccountId = (data) => {
    setData({ accountId: data.replace(/[^0-9]/g, "") });
  };

  return (
    <Modal
      {...props}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share Image - Unassigned (ami-0031880ee1405a5a3)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <p>
          This image is currently
          <span className="requiredFieldStar mx-2">*</span>
        </p>
        <div>
          <p className="mb-1 mt-2">
            This image is marked as private, and can only be used by AWS
            accounts that you grant access to below.
          </p>
          {state.showAccountIds && (
            <div className="mx-2">
              <Row className="tagSearchModal_borderBottom">
                <Col xs sm={5} md={5}>
                  <p className="mb-1">Account Number</p>
                </Col>
              </Row>
              {state.enteredAccountNumbers.map((data, ind) => (
                <Row className="mt-2">
                  <Col xs md={5}>
                    <p>{data}</p>
                  </Col>
                  <Col xs md={2}>
                    <p
                      className="shareModal_xIcon"
                      onClick={() => {
                        setData({
                          enteredAccountNumbers:
                            state.enteredAccountNumbers.filter(
                              (data, index) => {
                                return index !== ind; // Return true if you want to keep the element, false to remove it
                              }
                            ),
                        });
                      }}
                    >
                      x
                    </p>
                  </Col>
                </Row>
              ))}
            </div>
          )}
          <div className="d-flex gap-2">
            <input
              placeholder="Enter AWS Account Number"
              className="w-50"
              type="number"
              value={state.accountId}
              onChange={(e) => onChangeAccountId(e?.target?.value)}
            ></input>
            {/* <Button onClick={() => onClickAddPermission()}>
              Add Permission
            </Button>
            {accountIdError && <p>{accountIdError}</p>} */}
          </div>
          {/* <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>SnapShot Settings</Accordion.Header>
                <Accordion.Body>
                  <Form.Check
                    onChange={(e) =>
                      setAllowUsersToCreateVolume(e?.target?.checked)
                    }
                    type="switch"
                    id="custom-switch"
                    label="  Allow users to create volume from the following associated snapshots"
                  />
                  <Table className="mt-2" responsive>
                    <thead>
                      <tr>
                        <th>Snapshot ID</th>
                        <th> Volume Size</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>snap-0db727f9b03cf49db</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
        </div>
        {/* )} */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!state.accountId}
          onClick={() => {
            onShare();
          }}
        >
          Share Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
