import React from "react";
import Select from 'react-select';
function MultiDropDown({ options, handleMultiDropDown, value,placeholder }) {
    return (
      <Select
        // closeMenuOnSelect={false}
        // components={{ ClearIndicator }}
        // styles={{ clearIndicator: ClearIndicatorStyles }}
        value={value}
        onChange={handleMultiDropDown}
        options={options}
        placeholder={placeholder}
      />
    );
  }
  
  export default MultiDropDown;