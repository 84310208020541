import React from 'react';

import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';

import cost from '../../Images/Group 142201.svg'
import staff from '../../Images/Group 142202.svg'
import business from '../../Images/Group 142204.svg'
import operation from '../../Images/Group 142203.svg'

const FirstLayer = () => {

  return (
    <div className="hdfbsDashboard d-flex flex-column align-items-center justify-content-center">
      <div className="hdfbsDashboard-space">
        <div className='d-flex flex-wrap justify-content-center gap-4 cxo-item'>
          <Card sx={{ width: 275, }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                User Acquisition and Retention
              </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>750</p>
                    <p>User Registration Rate</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>26%</p>
                    <p>Customer Churn Rates</p></div>
                </Stack>
              </div>

            </CardContent>
          </Card>

          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Customer Engagement
              </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>72%</p>
                    <p>Active User Metrics</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>40%</p>
                    <p>Feature Utilization</p></div>
                </Stack>
              </div>
            </CardContent>
          </Card>
          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Performance Metrics      </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>97%</p>
                    <p>Application Uptime</p>
                  </div>
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>3 sec</p>
                    <p>Response Time</p>
                  </div>
                </Stack>
              </div>
            </CardContent>
          </Card>
          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Security and Compliance</Typography>
              <div className='d-flex align-items-end mt-4'>

                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>15</p>
                    <p>Security Incident Metrics</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>80%</p>
                    <p>Compliance Adherence</p></div>
                </Stack>
              </div>
            </CardContent>
          </Card>
          <Card sx={{ width: 275, }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Transaction Metrics
              </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>98%</p>
                    <p>Transaction Success Rates</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>4 sec</p>
                    <p>Transaction Processing Time</p></div>
                </Stack>
              </div>

            </CardContent>
          </Card>

          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Cost Efficiency
              </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>0.01 $</p>
                    <p>Infrastructure Costs/ Transaction</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>40%</p>
                    <p>Operational Efficiency improvement</p></div>
                </Stack>
              </div>
            </CardContent>
          </Card>
          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Customer Satisfaction     </Typography>
              <div className='d-flex align-items-end mt-4'>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>8</p>
                    <p>Net Promoter Score (NPS)</p>
                  </div>
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>1 hr</p>
                    <p>Customer Support Resolution Time</p>
                  </div>
                </Stack>
              </div>
            </CardContent>
          </Card>
          <Card sx={{ width: 275 }} className='hdfbsCardDashboard'>
            <CardContent>
              <Typography sx={{ fontSize: 16, fontWeight: 700 }} color="text.secondary" gutterBottom>
                Mobile Banking Metrics</Typography>
              <div className='d-flex align-items-end mt-4'>

                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem sx={{ minHeight: '12rem', background: '#245D7D' }} />}
                  spacing={2}
                  className='w-100 pt-3'
                >
                  <div className='w-50'>
                    <p className='hdfbsCardDashboard_numbers'>60%</p>
                    <p>Mobile App Engagement</p>
                  </div>
                  <div className='w-50'> <p className='hdfbsCardDashboard_numbers'>15000</p>
                    <p>Mobile Transaction Volume</p></div>
                </Stack>
              </div>
            </CardContent>
          </Card>

        </div>
      </div>

      {/* <p className="hdfbsDashboard-paragraph text-light">Differing business requirements, industry needs, and leadership profiles have resulted in unique <br />cloud adoption journeys for each of these organizations. But while the various paths toward<br /> cloud may be different, most cases consist of a combination of four distinct areas of value.</p> */}

      <div className="row justify-content-center mt-4">
        <div className="col-md-3">
          <div className="hdfbsCard">
            <img src={cost} className="hdfbsCard-img-top" alt="Image 1" />
            <div className="hdfbsCard-body">
              <h5 className="hdfbsCard-title">Cost savings</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3 ">
          <div className="hdfbsCard ">
            <img src={staff} className="hdfbsCard-img-top" alt="Image 2" />
            <div className="hdfbsCard-body">
              <h5 className="hdfbsCard-title">Cloud Landscape</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="hdfbsCard">
            <img src={operation} className="hdfbsCard-img-top" alt="Image 3" />
            <div className="hdfbsCard-body">
              <h5 className="hdfbsCard-title">Infrastructure resilience</h5>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="hdfbsCard">
            <img src={business} className="hdfbsCard-img-top" alt="Image 4" />
            <div className="hdfbsCard-body">
              <h5 className="hdfbsCard-title">Faster Deployment     </h5>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default FirstLayer;