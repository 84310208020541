import React, { useEffect, useState } from "react";

import { Button, Modal, Table } from "react-bootstrap";

import warningIcon from "../../../Images/warningCircle.svg";

import "../../../style/Infra/operationsModal.scss";
import { commonConstants } from "../../../constants/constant";

const OperationModal = (props) => {
  const onStartNotebook = () => {
    const startBody = props?.selectedRows?.map((data) => {
      return {
        operations: commonConstants?.start,
        notebookInstanceName: data?.name,
        accountId: data?.accountId,
        tags: {},
      };
    });
    props.onHide();
    props.onStart(startBody);
  };
  const onStartWorkspace = () => {
    const postBody = props?.selectedRows?.map((data) => {
      return {
        accountId: data?.accountId,
        operation: commonConstants.start,
        workspaceId: data?.workspaceId,
        tags: {},
      };
    });
    props.onHide();
    props.onStart(postBody);
  };

  const onStopWorkspace = () => {
    const postBody = props?.selectedRows?.map((data) => {
      return {
        accountId: data?.accountId,
        operation: commonConstants.stop,
        workspaceId: data?.workspaceId,
        tags: {},
      };
    });
    props.onHide();
    props.onStop(postBody);
  };
  const onRemoveWorkspace = () => {
    const postBody = props?.selectedRows?.map((data) => {
      return {
        accountId: data?.accountId,
        operation: commonConstants.remove,
        workspaceId: data?.workspaceId,
        tags: {},
      };
    });
    props.onHide();
    props.onRemove(postBody);
  };

  const onRebootWorkspace = () => {
    const postBody = props?.selectedRows?.map((data) => {
      return {
        accountId: data?.accountId,
        operation: commonConstants.reboot,
        workspaceId: data?.workspaceId,
        tags: {},
      };
    });
    props.onHide();
    props.onReboot(postBody);
  };
  const onStopNotebook = () => {
    const startBody = props?.selectedRows?.map((data) => {
      return {
        operations: commonConstants?.stop,
        notebookInstanceName: data?.name,
        accountId: data?.accountId,
      };
    });
    props.onHide();
    props.onStop(startBody);
  };
  return (
    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="grid-example">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <img src={warningIcon} className="w-25 " alt="warningIcon"></img>
          </div>
          <b className="text-center">{props?.heading}</b>
          <p className="text-center">{props?.subHeading} </p>
          <div className="mx-3">
            <Table>
              <tbody>
                {props?.type === "Notebook" ? (
                  <>
                    {props?.selectedRows?.map((data) => (
                      <tr>
                        <td>Notebook Instance ID</td>
                        <td>{data?.notebookInstanceId}</td>
                      </tr>
                    ))}
                  </>
                ) : props?.type === "Workspace" ? (
                  <>
                    {props?.selectedRows?.map((data) => (
                      <tr>
                        <td>Workbook Instance ID</td>
                        <td>{data?.workspaceId}</td>
                      </tr>
                    ))}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex gap-2 justify-content-center">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          {props?.type === "Notebook" && props?.operation === "start" ? (
            <Button onClick={() => onStartNotebook()}>
              Start Notebook Instance
            </Button>
          ) : props?.type === "Notebook" && props?.operation === "stop" ? (
            <Button onClick={() => onStopNotebook()}>
              Stop Notebook Instance
            </Button>
          ) : props?.type === "Workspace" && props?.operation === "start" ? (
            <Button onClick={() => onStartWorkspace()}>
              Start Workspace Instance
            </Button>
          ) : props?.type === "Workspace" && props?.operation === "stop" ? (
            <Button onClick={() => onStopWorkspace()}>
              Stop Workspace Instance
            </Button>
          ) : props?.type === "Workspace" && props?.operation === "reboot" ? (
            <Button onClick={() => onRebootWorkspace()}>
              Reboot Workspace Instance
            </Button>
          ) : props?.type === "Workspace" && props?.operation === "remove" ? (
            <Button onClick={() => onRemoveWorkspace()}>
              Remove Workspace Instance
            </Button>
          ) : (
            ""
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OperationModal;
