import React, { useEffect } from "react";
import { useState } from "react";

import { Badge, Button, Modal } from "react-bootstrap";

import { Alert } from "@mui/material";

import infoIcon from "../../../../../Images/infoIcon.svg";
import refreshIcon from "../../../../../Images/refresh.svg";
import filterIcon from "../../../../../Images/filterFilled.svg";
import ScanModalDetails from "./ScanModalDetails/scanModalDetails";

import "../../../../../style/Common.scss";
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import CommonTable from "../../../../../components/Infra/Table/table";
import { commonConstants } from "../../../../../constants/constant";
import TableColumnSearch from "../../../../../components/common/TableSearchInput/tableSearch";


const ScanModal = (props) => {
  const [selectedRowsModal, setSelectedRowsModal] = useState();
  const [instanceSelectedValue, setInstanceSelectedValue] = useState("");
  const [loading,setLoading]=useState(false);
  const [tableData, setTableData] = useState([])
 const [isAdded, setIsAdded] = useState(false);
 const [resetPagination, setResetPagination] = useState(false);
 const [currentPage, setCurrentPage] = useState(1);
  const [openPolicyDetailsModal, setOpenPolicyDetailsModal] = useState(false);
  const onConfirm = () => {
    props.onHide();
    const postScanServer={
          accountId: props?.selectedScanData[0]?.instanceData?.accountId,
          operation: commonConstants.scan,
          instanceId: props?.selectedScanData[0]?.instanceData?.instanceId,
        }
    props.getScanBodyServer(postScanServer);

  };
  const columns = [
    {
      name: "Policy Name",
      selector: (row) => row.policyName,
       
    },
    {
      name: "Policy Description",
      selector: (row) => row.policyDescription,
       
    },
    {
      name: "Operating System",
      selector: (row) => row.operatingSystem,
       
    },
    {
      selector: (row) => row.action,
       
    },
  ];

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setTableData(mergedData);
    } else {
      const result = tableData;
      let mergedData = [SearchObject, ...result];
      setTableData(mergedData);
    }
  };

  const SearchObject = {
    policyName: (
      <TableColumnSearch
        column={"policyName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    policyDescription: (
      <TableColumnSearch
        column={"policyDescription"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    operatingSystem: (
      <TableColumnSearch
        column={"operatingSystem"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    )
  };

  const OnFilterClick = () => {
    if (currentPage == 1) {
      const onlyObjectsWithStrings = tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (isAdded) {
        setTableData(onlyObjectsWithStrings);
      } else {
        setTableData(() => {
          return [SearchObject, ...onlyObjectsWithStrings];
        });
      }
    } else {
      if (!isAdded) {
        setTableData((preData) => {
          return [SearchObject, ...preData];
        });
      }
    }
    setIsAdded((prevIsAdded) => !prevIsAdded);
    setResetPagination(true);
  };

  const OnRefresh = () =>{
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }


  useEffect(() => {
    setTableData([
      {
        policyName: "AWS-AmazonLinuxDefaultPatchBaseline",
        policyDescription:
          "Default Patch Baseline for Amazon Linux Provided by AWS.",
        operatingSystem: "Amazon Linux",
        action:
          <img onClick={() => setOpenPolicyDetailsModal(true)} src={infoIcon} alt="eyeIcon1" className="tableButtonIcons"></img>,
      },
    ])
  }, [])
  return (
    <>
      <ScanModalDetails
        show={openPolicyDetailsModal}
        onHide={() => setOpenPolicyDetailsModal(false)}
      />
    
      <Modal
        {...props}
        size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Patch Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div className="d-flex justify-content-between">
            
            <div className="d-flex flex-row ">
              <TableButton
                icon={
                  <>
                    <img
                      className="tableButtonIcons"
                      src={filterIcon}
                       onClick={() => OnFilterClick()}
                      alt="moreIcon"
                    ></img>
                  </>
                }
                title={"Filter"}
              />
              <TableButton
                icon={
                  <>
                    <img
                      className="tableButtonIcons"
                      src={refreshIcon}
                       onClick={() => OnRefresh()}
                      alt="moreIcon"
                    ></img>
                  </>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          {
            loading ? 
            <p>Loading...</p>
            :
          <CommonTable
            columns={columns}
            data={tableData}
            selectableRows={false}
            onSelectedRowsChange={(data) => {
              setSelectedRowsModal(data);
            }}
          ></CommonTable>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onConfirm()}>
           Scan Instance
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScanModal;

