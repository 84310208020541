import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import Colors from '../../../../constants/Colors';

const CXOHorizontalSingleBarChart = ({ data, yAxisData, loading }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    
    const option = {
      xAxis: {
        type: 'value', // x-axis is 'value' as it represents the cost
        interval: 5000,
        axisLabel: {
          formatter: function (value, index) {
            // Hide the first label, assuming index starts from 0
            return value === 0 ? '' : value.toLocaleString(); // Add commas to the numbers;
          }, // Display cost on the x-axis
          color: Colors.ALLBLACK,
        },
      },
      yAxis: {
        type: 'category',
        data: yAxisData, // Names for the y-axis
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: Colors.ALLBLACK,
        },
        axisLine: {
          lineStyle: {
            color: Colors.LAVENDER,
            opacity: 0.05,
          },
        },
      },
      series: [
        {
          type: 'bar',
          data: data,
          itemStyle: {
            color: Colors.PRINCETONORANGE,
          },
          barWidth: 15,
        },
      ],
      label: {
        show: true,
        position: 'right',
        fontWeight: 'bold',
        fontSize: 10,
        formatter: function (params) {
          return params.value.toLocaleString(); // Adds commas to the numbers
        }, // Display only cost in the label
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter : (value, index) =>{
          return `${value} ${data[index]?.currency}`
        },
      },
      grid: {
        top: '8%',
        bottom: '6%',
        left: '3%',
        right: '12%',
        containLabel: true,
      },
    };

    myChart.setOption(option);

    const resizeListener = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
      myChart.dispose();
    };
  }, [data, yAxisData]);

  return (
    <div
      ref={chartRef}
      className='w-100 h-100 overflow-auto'
      progresspending={loading}
    ></div>
  );
};

export default CXOHorizontalSingleBarChart;
