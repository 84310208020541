import React, { useEffect, useState } from "react";
import '../../style/Infra/subHeader.scss'

import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function SubHeader(props) {
  const { pathname } = useLocation();
 
  return (
    <div className="subheader">
    <div className="d-flex gap-4 compute-bar flex-wrap ps-5">
      {props?.SUB_NAV_ITEMS?.map((item) => (
        <NavLink
          to={item?.path} 
          className={`text-decoration-none  subheader_navLinkTxt nav-link ${
            pathname.includes(item?.path) ? 'active' : ''
          }`}
        >
     {item.Title}
        </NavLink>
      ))}
    </div>
  </div>
  

  );
}
