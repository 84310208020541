import React, { useEffect, useState } from "react";

import moment from "moment/moment";

import axios from "../../../../api/axios";
import { ToastError } from "../../../../utils/utils";
import refreshIcon from "../../../../Images/refresh.svg";
import { API_URLS } from "../../../../constants/API_URLS";
import { toastIds } from "../../../../constants/constant";
import CommonTooltip from "../../../../components/Tooltip";
import filterIcon from "../../../../Images/filterFilled.svg";
import CommonTable from "../../../../components/Infra/Table/table";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";

import "../../../../style/Infra/s3.scss";
export default function S3() {
  const [loading, setLoading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCheckboxDisabled, setFilterCheckboxDisabled] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const columns = [
    {
      name: "Bucket Name",
      selector: (row) =>CommonTooltip(row.bucketName) ,
       
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
       
    },

    {
      name: "Account Id",
      selector: (row) => row.accountId,
       
    },
    {
      name: "Bucket Region",
      selector: (row) => row.bucketRegion,
       
    },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
       
    },
  ];
  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setTableData(mergedData);
    } else {
      const result = tableDataRaw.map((data) => {
        let newItem = {
          createdDate: moment(data?.createdDate).format("MM-DD-YYYY HH:mm:ss"),
          ...data,
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setTableData(mergedData);
    }
  };
  const SearchObject = {
    bucketName: (
      <TableColumnSearch
        column={"bucketName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountName: (
      <TableColumnSearch
        column={"accountName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountId: (
      <TableColumnSearch
        column={"accountId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    bucketRegion: (
      <TableColumnSearch
        column={"bucketRegion"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    createdDate: (
      <TableColumnSearch
        column={"createdDate"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    environment: (
      <TableColumnSearch
        column={"environment"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const getTableData = () => {
    setLoading(true);
    axios
      .get(API_URLS.infra.storage.s3.getS3Table)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setTableDataRaw(response?.data?.data[0]);
          let result = response?.data?.data[0];
          result = result.map((data) => {
            let newItem = {
              createdDate: moment(data?.createdDate).format(
                "MM-DD-YYYY HH:mm:ss"
              ),
              ...data,
            };
            return newItem;
          });
          setTableData(result);
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      )
      .finally(() => setLoading(false));
  };

  const OnFilterClick = () => {
    if (currentPage == 1) {
      const onlyObjectsWithStrings = tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (isAdded) {
        setTableData(onlyObjectsWithStrings);
        setFilterCheckboxDisabled(false);
      } else {
        setTableData(() => {
          return [SearchObject, ...onlyObjectsWithStrings];
        });
        setFilterCheckboxDisabled(true);
      }
    } else {
      if (isAdded) {
        setFilterCheckboxDisabled(false);
      } else {
        setTableData((preData) => {
          return [SearchObject, ...preData];
        });
        setFilterCheckboxDisabled(true);
      }
    }
    setIsAdded((prevIsAdded) => !prevIsAdded);
    setResetPagination(true);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className=" commmon_stye  mt-3">
      <h5 className=" text-start ms-1">S3</h5>

      <div className="container_style">
        <div className="d-flex justify-content-end">
          <div className="d-flex flex-wrap">
            <TableButton
              icon={
                <>
                  <img
                    className="s3_tableIcons"
                    src={filterIcon}
                    onClick={() => OnFilterClick()}
                    alt="moreIcon"
                  ></img>
                </>
              }
              title={"Filter"}
            />
            <TableButton
              icon={
                <>
                  <img
                    className="s3_tableIcons"
                    src={refreshIcon}
                    alt="moreIcon"
                    onClick={() => getTableData()}
                  ></img>
                </>
              }
              title={"Refresh"}
            />
          </div>
        </div>

        <CommonTable
          removeSearchCheckbox={filterCheckboxDisabled}
          onChangePage={(data) => {
            setResetPagination(false);
            setCurrentPage(data);
          }}
          resetPagination={resetPagination}
          columns={columns}
          data={tableData || []}
          selectableRows={true}
        />
      </div>
    </div>
  );
}
