import React, { useEffect } from "react";
import { useState } from "react";

import { Button, Modal } from "react-bootstrap";

import infoIcon from "../../../../../Images/infoIcon.svg";
import refreshIcon from "../../../../../Images/refresh.svg";
import filterIcon from "../../../../../Images/filterFilled.svg";
import ScanModalDetails from "./PatchModalDetails/patchModalDetails";
import PatchModalDetails from "./PatchModalDetails/patchModalDetails";
import "../../../../../style/Common.scss";
import CommonTable from "../../../../../components/Infra/Table/table";
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import { commonConstants } from "../../../../../constants/constant";
import TableColumnSearch from "../../../../../components/common/TableSearchInput/tableSearch";

const PatchModal = (props) => {
  const [selectedRowsModal, setSelectedRowsModal] = useState();
  const [loading, setLoading] = useState(false);
  const [openPolicyDetailsModal, setOpenPolicyDetailsModal] = useState(false);
  const [tableData, setTableData] = useState([])
 const [isAdded, setIsAdded] = useState(false);
 const [resetPagination, setResetPagination] = useState(false);
 const [currentPage, setCurrentPage] = useState(1); 
 const onConfirm = () => {

    props.onHide();

    const postPatchServer = {
      accountId: props?.selectedPatchData[0]?.instanceData?.accountId,
      operation: commonConstants.patch,
      instanceId: props?.selectedPatchData[0]?.instanceData?.instanceId,
    }

    props.getPatchBodyServer(postPatchServer);
  };
  const columns = [
    {
      name: "Policy Name",
      selector: (row) => row.policyName,
       
    },
    {
      name: "Policy Description",
      selector: (row) => row.policyDescription,
       
    },
    {
      name: "Operating System",
      selector: (row) => row.operatingSystem,
       
    },
    {
      selector: (row) => row.action,
       
    },
  ];

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setTableData(mergedData);
    } else {
      const result = tableData;
      let mergedData = [SearchObject, ...result];
      setTableData(mergedData);
    }
  };

  const SearchObject = {
    policyName: (
      <TableColumnSearch
        column={"policyName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    policyDescription: (
      <TableColumnSearch
        column={"policyDescription"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    operatingSystem: (
      <TableColumnSearch
        column={"operatingSystem"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    )
  };

  const OnFilterClick = () => {
    if (currentPage == 1) {
      const onlyObjectsWithStrings = tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (isAdded) {
        setTableData(onlyObjectsWithStrings);
      } else {
        setTableData(() => {
          return [SearchObject, ...onlyObjectsWithStrings];
        });
      }
    } else {
      if (!isAdded) {
        setTableData((preData) => {
          return [SearchObject, ...preData];
        });
      }
    }
    setIsAdded((prevIsAdded) => !prevIsAdded);
    setResetPagination(true);
  };

  const onRefresh =() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  useEffect(() => {
    setTableData([
      {
        policyName: "AWS-AmazonLinuxDefaultPatchBaseline",
        policyDescription:
          "Default Patch Baseline for Amazon Linux Provided by AWS.",
        operatingSystem: "Amazon Linux",
        action:
          <img onClick={() => setOpenPolicyDetailsModal(true)} src={infoIcon} alt="eyeIcon1" className="tableButtonIcons"></img>,
      },
    ])
  }, [])
  
  return (
    <>
      <PatchModalDetails
        show={openPolicyDetailsModal}
        onHide={() => setOpenPolicyDetailsModal(false)}
      />

      <Modal
        {...props}
        size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Patch Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div className="d-flex justify-contentf-between">
            <div>
            </div>
            <div className="d-flex flex-row ">
              <TableButton
                icon={
                  <>
                    <img
                      className="tableButtonIcons"
                      src={filterIcon}
                      onClick={() => OnFilterClick()}
                      alt="moreIcon"
                    ></img>
                  </>
                }
                title={"Filter"}
              />
              <TableButton
                icon={
                  <>
                    <img
                      className="tableButtonIcons"
                      src={refreshIcon}
                      onClick={() => onRefresh()}
                      alt="moreIcon"
                    ></img>
                  </>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          {
            loading ?
              <p>Loading...</p>
              :
              <CommonTable
                columns={columns}
                data={tableData}
                selectableRows={false}
                onSelectedRowsChange={(data) => {

                  setSelectedRowsModal(data);
                }}
              ></CommonTable>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => onConfirm()}>
            Patch Instance
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PatchModal;
