import React from 'react';
import { Form } from 'react-bootstrap';
import '../../../style/Infra/Inventory/dropdown.scss';

const InventoryDropdown = (props) => {
  return (
    <div className='dropdown_style'>
      <Form.Select
        className={props.className}
        onChange={props.onSelectChange}
        value={props.selectedValue} // Preselected value
      >
        {props.placeholder && (
          <option key={props.placeholder} hidden value>
            {props.placeholder}
          </option>
        )}
        {props.options.map((i) => (
          <option key={i.value} value={i.value}>
            {i.value}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default InventoryDropdown;
