import React from "react";
// import MultiDropDown from "../../../components/common/MultiSelectDropdown/multiSelectDropDown";
// import "../../../style/Server/activateOnPre.scss";
import { Button } from "react-bootstrap";
import MultiDropDown from "../../../../components/Infra/MultiSelectDropdown/multiSelectDropDown";
// import Button from "../../../components/common/Button/button";
const ActivateOnPrem = (props) => {
  return (
    <>
      <div className="activate-on-prem container">
        <div className="d-flex justify-content-between pt-2 fs-5">
          <p>Self-Service Activation of On-Prem Instances</p>
          <button
            type="button"
            className="btn-close "
            aria-label="Close"
            onClick={props.closeModal}
          ></button>
        </div>

        <div className="row">
          <div className="col-md-7">
            <div className="row mt-4 ms-1">
              <div className="col-md-6">
                <label>Account</label>
                <MultiDropDown placeholder="Account" />
              </div>
              <div className="col-md-6">
                <label>Region</label>
                <MultiDropDown placeholder="Region" />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="d-flex">
              <div className="vertical-line"></div>
              <div>
                <div className="text-center mb-4">
                  <h5>Installation</h5>
                </div>
                <div className="text-muted">
                  <div className="mb-3">
                    To manage physical and virtual machines that reside outside
                    of AWS, DAY2™ requires the deployment of the AWS SSM agent
                    in order to manage the instance.
                  </div>
                  <div className="mb-3">
                    The Self Service Install Kit provides scripts and
                    instructions for interactive and non-interactive bulk
                    installation on supported Windows and Linux platforms, and
                    can be adjusted to suit most deployment and on-premise
                    management systems.
                  </div>
                  <div className="mb-3">
                    For any required assistance with the Install Kit, please
                    contact DAY2™ support.
                  </div>
                </div>
                <Button className="Download-btn ">Download  the Self Service Install Kit</Button>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5 mb-3">
        <Button  className="close-btn"  onClick={props.closeModal}>close</Button>
        </div>
      </div>
    </>
  );
};
export default ActivateOnPrem;
