import React from "react";

import { Outlet } from "react-router";

import Header from "../../header/Header";
import SubHeader from "../../header/SubHeader";

function Compute() {
  const SUB_NAV_ITEMS = [
    {
      Title: "Servers",
      path: "/compute/servers",
    },
    {
      Title: "Servers Groups",
      path: "/compute/servergroup",
    },
    {
      Title: "Images",
      path: "/compute/images",
    },
    {
      Title: "WorkSpace",
      path: "/compute/workspace",
    },
    {
      Title: "NoteBook Instances",
      path: "notebookinstance",
    },
  ];
  return (
    <>
      <Header />
      <SubHeader SUB_NAV_ITEMS={SUB_NAV_ITEMS} />
      <Outlet />
    </>
    // <div className="compute-container ">
    //   <div className="d-flex gap-4 compute-bar flex-wrap ps-5">
    //     <NavLink to="/compute/servers" className={`text-decoration-none text-dark nav-link ${pathname.includes('/compute/servers') ? 'active' : ''}`}>
    //       Servers
    //     </NavLink>
    //     <NavLink to="/compute/servergroup" className={`text-decoration-none text-dark nav-link ${pathname.includes('/compute/servergroup') ? 'active' : ''}`} >
    //       Servers Groups
    //     </NavLink>
    //     <NavLink to="/compute/images" className={`text-decoration-none text-dark nav-link ${pathname.includes('/compute/images') ? 'active' : ''}`}>
    //       Images
    //     </NavLink>
    //     <NavLink to="/compute/workspace" className={`text-decoration-none text-dark nav-link ${pathname.includes('/compute/workspace') ? 'active' : ''}`}>
    //       WorkSpace
    //     </NavLink>
    //     <NavLink to="/compute/notebookinstance" className={`text-decoration-none text-dark nav-link ${pathname.includes('/compute/notebookinstance') ? 'active' : ''}`}>
    //       Notebook Instance
    //     </NavLink>
    //   </div>
    //   <Outlet />
    // </div>
  );
}
export default Compute;
