import React from "react";

import { Pie } from "react-chartjs-2";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";



import '../../../style/Infra/Charts/pie.scss';

ChartJS.register(ArcElement, Tooltip, Legend);
const PieChart = (props) => {
  
  const Pieoptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true, // Optional: hide the legend if needed
        position: props.position,
        labels: {
          boxWidth: 10, // Customize the width of the legend box here
          boxHeight: 10,
        },
      },
    },
  };

  return (
    <div className={props.className||  "pie" }>
      <Pie data={props?.data} options={Pieoptions} />
    </div>
  );
};
export default PieChart;
