import React, { useEffect, useState, useReducer, useRef } from 'react';
import * as echarts from 'echarts';
import Colors from '../../../../constants/Colors';

const ServicesBarExample = ({ barData, yAxisData }) => {
  const chartRef = useRef(null);
  const colours = [
    Colors.CORALRED,
    Colors.HEADERBORDER,
    Colors.TRANSPARENTSKYBLUE,
    Colors.LIMEGREEN,
    Colors.CORNFLOWERBLUE,
    Colors.HOTPINK,
    Colors.MEDIUMORCHID,
  ];

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}', // Display cost on the x-axis
          color: Colors.ALLBLACK,
        },
      },
      yAxis: {
        type: 'category',
        data: yAxisData,
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: Colors.ALLBLACK,
        },
        axisLine: {
          lineStyle: {
            color: '#e8e9f8',
            opacity: 0.05,
          },
        },
      },
      series: barData?.map((item, index) => ({
        name: item.name,
        type: 'bar',
        data: item.data,
        itemStyle: {
          color: colours[index],
        },
        barGap: '0%',
        barCategoryGap: '20%',
      })),
      label: {},
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter : (value) =>{
          return `${value.toFixed(2)} ${barData[0].currency}`
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
    };
    myChart.setOption(option);

    const resizeListener = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
      myChart.dispose();
    };
  }, [barData]);

  return ( 
    <div
      ref={chartRef}
      className='serviceBarGraph'
    ></div>
  );
};

export default ServicesBarExample;
