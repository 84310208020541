import React, { useState } from "react";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";


import '../../../../style/Infra/createSnapshotModalVolumes.scss'
import { ErrorMessage, commonConstants, regex } from "../../../../constants/constant";
import AddTag from "../../../../components/Infra/AddTag/addTag";

const CreateSnapshotModal = (props) => {
  const [openTagSearch, setOpenTagSearch] = useState(false);
  const [keyValuePair, setKeyValuePair] = useState([]);
  const [snapshotdescription, setSnapshotDescription] = useState("");
  const [createImageName, setCreateImageName] = useState("");
  const [createImageNameError, setCreateImageNameError] = useState("");
  const [createImageDescription, setCreateImageDescription] = useState("");

  const onClose = () => {
    props.onHide();
  };
  const onHide = () => {
    setOpenTagSearch(!openTagSearch);
  };
  const onCreate = () => {
    let tags = {};
    for (const item in keyValuePair) {
      tags[keyValuePair[item]?.key] = keyValuePair[item]?.value;
    }
    let createPostBody = props?.selectedVolumeDetails.map((data) => {
      let newItem = {
        volumeId: data?.volumeId,
        newName: null,
        operation: commonConstants.create,
        accountId: data?.accountId,
        createSnapshot: commonConstants?.true,
        addTags: Object.entries(tags)?.length > 0 ? tags : null,
        volumeType: null,
        description: snapshotdescription,
      };
      return newItem;
    });
    props.getCreateBody(createPostBody);
    props.onHide();
  };

  const onCreateImage = () => {
    let tags = {};
    for (const item in keyValuePair) {
      tags[keyValuePair[item]?.key] = keyValuePair[item]?.value;
    }
    const createImageBody = props?.selectedRows?.map((data) => {
      return {
        snapshotId: data?.snapshotId,
        imageName: createImageName, //"akshay test image ",
        accountId: data?.accountId,
        description: createImageDescription, //"creating image with api ",
        operation: commonConstants?.create,
        addTags: Object.entries(tags)?.length > 0 ? tags : null,
      };
    });
    props.onHide();
    props.onCreateImage(createImageBody);
  };

  const handleCreateImageNameChange = (val) => {
    setCreateImageName(val);
    if (regex.imageNameRegex.test(val)) {
      setCreateImageNameError("");
    } else {
      setCreateImageNameError(ErrorMessage.invalidVolumeCreateImageName);
    }
  };

  return (
    <>
      <AddTag
        show={openTagSearch}
        onHide={() => onHide()}
        getKeyValPairs={(data) => {
          setKeyValuePair(data);
        }}
      />
      <Modal
        {...props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.isCreateImage ? "Create Image" : "Create Snapshot"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          {props?.isCreateImage ? (
            <div>
              <div>
                <p className="mb-0 createSnapShotVolumes_snapshotDescTxt">
                  Name
                  <span className="createSnapShotVolumes_requiredIcon">*</span>
                </p>
                <input
                  value={createImageName}
                  onChange={(e) =>
                    handleCreateImageNameChange(e?.target?.value)
                  }
                  className="w-100"
                  placeholder="Enter Name"
                ></input>
              </div>
              {createImageNameError && (
                <p className="errorMessage">{createImageNameError}</p>
              )}

              <div>
                <p className="mb-0 createSnapShotVolumes_snapshotDescTxt">
                  Description
                </p>
                <textarea
                  value={createImageDescription}
                  onChange={(e) => setCreateImageDescription(e?.target?.value)}
                  className="w-100"
                  placeholder="Enter Description"
                ></textarea>
              </div>
            </div>
          ) : (
            <div>
              <p className="mb-0 createSnapShotVolumes_snapshotDescTxt">
                Snapshot Description
                <span className="createSnapShotVolumes_requiredIcon">*</span>
              </p>
              <input
                className="w-75"
                placeholder="Enter Snapshot Description"
                value={snapshotdescription}
                onChange={(e) => setSnapshotDescription(e?.target?.value)}
              ></input>
              <p className="w-75 createSnapShotVolumes_pleaseProvideTxt">
                Please provide a description to enable traceability of the
                snapshot.
              </p>
            </div>
          )}

          {props?.isCreateImage ? (
            <div>
              <p className="mb-0 createSnapShotVolumes_tagTargetTxt">
                Tag Target Images
              </p>
              <Button
                onClick={() => setOpenTagSearch(!openTagSearch)}
                className="mt-2 createSnapShotVolumes_addTagsBtn"
              >
                Add Tags
              </Button>
            </div>
          ) : (
            <div className="d-flex mb-0  w-75 flex-wrap justify-content-between">
              <div className="d-flex align-items-center">
                <p className="mb-0 createSnapShotVolumes_tagTargetTxt">
                  Tag Target Snapshots
                </p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Button
                  onClick={() => setOpenTagSearch(!openTagSearch)}
                  className="createSnapShotVolumes_addTagsBtn"
                >
                  Add Tags
                </Button>
                <p className="mb-0 createSnapShotVolumes_tagsSelected">
                  {keyValuePair?.length} Tag(s) Selected
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {props?.isCreateImage ? (
            <>
              <Button variant="outline-secondary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button
                disabled={!createImageName || createImageNameError}
                onClick={() => onCreateImage()}
              >
                Create Image
              </Button>
            </>
          ) : (
            <Button disabled={!snapshotdescription} onClick={() => onCreate()}>
              Create Snapshot
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateSnapshotModal;
