import React, { useEffect, useState } from 'react';

import semicolon from '../../Images/semicolon.svg';
import grouplayer from '../../Images/lastwatch.svg';
import firstwatch from '../../Images/firstwatch.svg';
import secondwatch from '../../Images/grouplayer.svg';

const FourthLayer = () => {
  const [downtimeDecrease, setDowntimeDecrease] = useState(getRandomNumber());
  const [incidentDecline, setIncidentDecline] = useState(getRandomNumber());
  const [mttrDecrease, setMttrDecrease] = useState(getRandomNumber());
  const [alarm, setAlarm] = useState([]);



  useEffect(() => {
    const interval = setInterval(() => {
      setDowntimeDecrease(getRandomNumber());
      setIncidentDecline(getRandomNumber());
      setMttrDecrease(getRandomNumber());
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  function getRandomNumber() {
    return Math.floor(Math.random() * 100);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 forthlayer text-light ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 forthlayer text-light p-4 pt-3 costSavingContainerLeft p-1">
                <div >
                  <div className="first-layer-blue">Infrastructure resilience</div>
                  <div className="mt-5 text-start first-layer-text">
                    The availability and security with AWS enables customers to improve SLAs while reducing unplanned downtime and risk.
                  </div>
                  <div className="mt-5 first-layer-image d-flex align-items-start gap-3">
                    <img src={semicolon} className="p-0 first-layer-image1 m-0" alt="Image 3" />
                    <div className=" text-start first-layer-sentence ">
                      Through the transformation of our cloud environment, what was once an expansive infrastructure has evolved into a dynamic and streamlined ecosystem. Now, a single administrator can effortlessly navigate and optimize multiple servers, showcasing the efficiency and agility of our revamped setup.   </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-light text-dark mt-4 mb-4" >
                <div className="row">
                  <div className="col-md-12 px-4 pt-4 piclayer1">
                    <img src={firstwatch} className="serviceInsightsImg" alt="Image 3" />
                    <div className="piclayer12">
                      <b>
                        <h1 className="slide-effect operationalHeadTxt">
                          {downtimeDecrease}%
                        </h1>
                      </b>
                      <p className='fs-18'>Decrease in <b>DownTime</b></p>
                      <hr className="line" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pb-0 piclayer1">
                    <img src={secondwatch} className="serviceInsightsImg" alt="Image 3" />
                    <div className="piclayer12">
                      <b>
                        <h1 className="slide-effect operationalHeadTxt">
                          {incidentDecline}%
                        </h1>
                      </b>
                      <p className='fs-18'>Decline in <b>critical<br /> (P1/P0) incidents</b></p>
                      <hr className="line1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 piclayer1">
                    <img src={grouplayer} className="media-fire serviceInsightsImg" alt="Image3" />
                    <div className="piclayer12">
                      <b>
                        <h1 className="slide-effect operationalHeadTxt">
                          {alarm.data}
                          {/* {alarm.status} */}
                        </h1>
                      </b>
                      <p className='fs-18'>Decrease in <b>mean time<br /> to restore (MTTR) from <br /> critical (P1/P0) incidents</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthLayer;
