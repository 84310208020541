import React from 'react';

import SVG from 'react-inlinesvg';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Divider, Stack } from '@mui/material';

import axios from '../../api/axios';
import SidebarMenu from '../SidebarMenu';
import accLogo from '../../Images/accLogo.svg';
import { ToastError } from '../../utils/utils';
import SignOutImage from '../../Images/signOut.svg';
import { API_URLS } from '../../constants/API_URLS';
import { ROUTE_PATH } from '../../constants/Routes';
import sidebarToggle from '../../Images/sidebarToggle.svg';
import SidebarOverlay from '../../components/SidebarOverlay';
import userIconHeader from '../../Images/userIconHeader.svg';
import { SET_LOGIN_RESPONSE } from '../../redux/Slices/AuthSlice';
import { LocalStorageManager } from '../../utils/LocalStorageManager';
import { localStorageHeaders, toastIds } from '../../constants/constant';

import './Sidebar.scss';
const Sidebar = ({
  toggleSidebar,
  isSidebarFixedAndOpen,
  setIsSidebarOpen,
  toggleSmallScreenSidebar,
  isSmallScreenSidebarOpen,
}) => {
  const navigate = useNavigate();
  const handleMouseEnter = () =>
    !isSidebarFixedAndOpen && setIsSidebarOpen(true);
  const handleMouseLeave = () =>
    !isSidebarFixedAndOpen && setIsSidebarOpen(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => {
    return state.auth.loginResponse;
  });
  const OnLogout = () => {
    axios
      .put(`${API_URLS.Logout}?emailID=${user?.email}`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          dispatch(SET_LOGIN_RESPONSE([]));
          navigate(ROUTE_PATH.LOGIN);
        } else {
          ToastError(response?.data?.statusMessage, toastIds.apiError);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.apiError);
      });
  };

  return (
    <>
      {/* SMALL SCREEN SIDEBAR STARTS */}
      <div
        className={`SidebarSmall d-md-none ${
          isSmallScreenSidebarOpen ? 'open' : 'close'
        }`}
      >
        {/* BRANDING STARTS */}
        <div className="SidebarSmall__branding d-flex flex-row justify-content-between align-items-center">
          {/* LOGO STARTS */}
          <div className="SidebarSmall__branding-logo">
            {/* <Link to={APP_ROUTES.ROUTE_ROOT}>ITSM</Link> */}
          </div>
          {/* LOGO ENDS */}
          <SVG className="SidebarBig__branding-logo" src={accLogo} />
          {/* TOGGLE BUTTON STARTS */}
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ minHeight: '12rem', background: '#245D7D' }}
              />
            }
            spacing={2}
            className="w-100 pt-3"
          ></Stack>
          <div className="SidebarSmall__branding-toggle">
            <button
              onClick={toggleSmallScreenSidebar}
              className="btn shadow-none"
            >
              <SVG
                src={sidebarToggle}
                className="SidebarBig__branding-toggle-icon"
              />
            </button>
          </div>
          {/* TOGGLE BUTTON ENDS */}
        </div>
        <div className="d-flex gap-1 flex-row userContainer align-items-center">
          <Image src={userIconHeader} roundedCircle />
          <p className="mb-0">{user?.userName}</p>
        </div>
        {/* BRANDING ENDS */}

        {/* NAVIGATION ITEM STARTS */}
        <SidebarMenu
          isSmallSidebarOpen={isSidebarFixedAndOpen}
          forSmallDevices
        />
        {/* NAVIGATION ITEM ENDS */}
        <div className="logoutBtn">
          <button className="d-flex align-items-center justify-content-center gap-1">
            LOGOUT <SVG className="SidebarSmall_signOut" src={SignOutImage} />
          </button>
        </div>
      </div>
      {/* SMALL SCREEN SIDEBAR ENDS */}

      {/* SMALL SCREEN SIDEBAR OVERLAY STARTS */}
      {isSmallScreenSidebarOpen && (
        <SidebarOverlay
          clx="SidebarSmallOverlay"
          handleClose={toggleSmallScreenSidebar}
        />
      )}
      {/* SMALL SCREEN SIDEBAR OVERLAY ENDS */}

      {/* BIG SCREEN SIDEBAR STARTS */}
      <div
        className={`SidebarBig d-none d-md-flex flex-column`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* BRANDING STARTS */}
        <div className="SidebarBig__branding d-flex flex-row justify-content-between align-items-center">
          {/* LOGO STARTS */}
          <div>
            <SVG className="SidebarBig__branding-logo" src={accLogo} />
          </div>
          {/* LOGO ENDS */}

          {/* TOGGLE BUTTON STARTS */}
          <div className="SidebarBig__branding-toggle">
            <button onClick={toggleSidebar} className="btn shadow-none">
              <SVG
                src={sidebarToggle}
                className="SidebarBig__branding-toggle-icon"
              />
            </button>
          </div>
          {/* TOGGLE BUTTON ENDS */}
        </div>

        <div className="d-flex gap-1 flex-row userContainer align-items-center">
          <Image src={userIconHeader} roundedCircle />
          <p className="mb-0">{user?.userName}</p>
        </div>
        {/* BRANDING ENDS */}

        {/* NAVIGATION ITEM STARTS */}
        <SidebarMenu isSmallSidebarOpen={isSidebarFixedAndOpen} />
        {/* NAVIGATION ITEM ENDS */}
        <div className="logoutBtn">
          <button
            className="d-flex flex-row gap-1 align-items-center justify-content-center"
            onClick={OnLogout}
          >
            <p className="m-0">LOGOUT</p>{' '}
            <SVG className="SidebarBig_signOut" src={SignOutImage} />
          </button>
        </div>
      </div>

      {/* BIG SCREEN SIDEBAR ENDS */}
    </>
  );
};

export default Sidebar;
