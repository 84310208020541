import React from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000,
  // width : '50%'

}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .3)',
  zIndex: 1000
}

export default function Modal({ open, children, closeOnBakgroundClick , portalId = "portal" ,styles,  }) {
  if (!open) return null
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES}   onClick={closeOnBakgroundClick} />
      <div style={styles? styles: MODAL_STYLES} >
        {children}
      </div>
    </>,
    document.getElementById(portalId)
  )
}