import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { graphColors, serverDonutColors } from '../../../constants/constant';

const DonutChart = ({ isServer, data, labels,display }) => {
    const chartContainer = useRef(null);
    let chart = null; // Chart instance

    useEffect(() => {
        if (!Array.isArray(data) || !data.every(val => typeof val === 'number')) {
            console.error('Invalid data provided. Please ensure data is an array of numbers.');
            return;
        }

        const ctx = chartContainer.current.getContext('2d');

        chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    backgroundColor: isServer ? serverDonutColors : graphColors,
                }],
            },
            options: {
                legend: {display: false},
                cutout: '65%',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: {
                        display: false
                      },
                    legend: {
                        display: display,
                        position: isServer?'right':'bottom'
                    }
                }
            }
        });

        const sum = data.reduce((total, value) => total + value, 0);
        document.getElementById('totalValue').textContent = sum.toString();

        return () => chart.destroy();
    }, [data, labels]);

    const sum = data.reduce((total, value) => total + value, 0);

    return (
        <>
            {
                (data && sum !== '0[object Object]') &&
                (<div>
                    <div style={{ position: 'relative', width: isServer?'355px':'230px', height: isServer?'300px':'212px' }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: isServer?'36%':'50%',
                                transform: 'translate(-50%, -50%)',
                                textAlign: 'center',
                            }}
                        >
                            <div style={{ fontSize: '24px',color:'black', fontWeight: 'bold' }} id="totalValue">
                                {sum.toString()}
                            </div>
                        </div>
                        <canvas ref={chartContainer} />
                    </div>
                </div>
                )}
        </>
    );
};

export default DonutChart;
