import React from "react";

import "../../../style/Infra/Inventory/inventorycard.scss";
const InventoryCard = (props) => {
  return (
    <div className="inventorycard">
      <div className="card manage-card ">
        <label className="title">{props.title}</label>
        <div className={`d-flex justify-content-center  ${props?.isDougnut?'displaycomponents_Dougnut':'displaycomponents'}`} >
          {props.displaycomponents ? props.displaycomponents : " No Records Found "}
        </div>
      </div>
    </div>
  );
};
export default InventoryCard;
