import React, { useState } from 'react';
import semicolon from '../../Images/semicolon.svg';
import clocksis from '../../Images/clockvsis.svg';
import coinsec from '../../Images/raincode.svg';
import '../../../src/style/Pages/CXO/dashboard.scss'; // Import the CSS file for styling

const FifthLayer = () => {
  const [timeToMarketDecrease, setTimeToMarketDecrease] = useState(getRandomNumber());
  const [codeDeploymentIncrease, setCodeDeploymentIncrease] = useState(getRandomNumber());
  const [showPopup, setShowPopup] = useState(false);

  function getRandomNumber() {
    return Math.floor(Math.random() * 100);
  }

  function handleValueClick() {
    setShowPopup(true);
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-12 fifthlayer p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 bg-light">
                <div className="row adjust-media">
                  <div className="col-lg-5">
                    <div className="col-md-12">
                      <div className="row justify-content-center">
                        <img src={clocksis} className="players" alt="Image 3" />
                      </div>
                      <div className="col-md-12 fontchange1">
                        <b>
                          <h1 className="slide-effect auditHeadTxt" onClick={handleValueClick}>
                            {timeToMarketDecrease}%
                          </h1>
                        </b>
                        <p className='fs-18'>Decrease in<br /><b>time to Market</b></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 next-line1">
                    <br />
                    <div className="vertical-line"></div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row justify-content-center">
                      <img src={coinsec} className="players" alt="Image 3" />
                    </div>
                    <div className="row fontchange1">
                      <div className="col-md-12 justify-content-center">
                        <b>
                          <h1 className="slide-effect auditHeadTxt" onClick={handleValueClick}>
                            {codeDeploymentIncrease}%
                          </h1>
                        </b>
                      </div>
                      <div className="col-md-12 justify-content-center">
                        <p className='fs-18'>Increase in <b>Code deployment Frequency</b></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-light p-6">
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='dropdown' id='report'>

                    </div>

                  </div>
                </div>

                <div className="text-start px-4">
                  <h1>Faster Deployment</h1>
                  <p className="thirdpart">
                    AWS customers are able to accelerate time to market, with an increased pace of experimentation and faster deployment of new features and applications with fewer errors
                  </p>
                </div>
                <div className='w-100 d-flex align-items-center auditCodeContent  flex-row gap-0 align-items-start pt-3'>
                  <div className='d-flex gap-3 flex-wrap'>
                    <img src={semicolon} className="img-top3" alt="Image 3" />
                    <div className="secondlayertext w-75 text-center justify-content-start d-flex gap-3 flex-wrap">
                      <div className='text-start'>
                        <h5 className='m-0'>
                          I'm proud to share that during disruptions, our cloud architecture stands as a beacon of stability. Downtime is now a distant concern, and our operations persist with unwavering reliability. The cloud isn't merely a platform for us; it functions as our shield against uncertainties.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FifthLayer;
