import React from "react";
import "../../../style/Components/Common/BreadCrumb.scss";
const BreadCrumb = ({ parentHeader, onClickParent, onClickChild, childHeader, onClickSecondChild, secondChild, thirdChild }) => {
  return (
    <div className="d-flex flex-wrap cursor-pointer">
      <p className="breadCrumb_parentHeader mb-0" onClick={() => { onClickParent && onClickParent() }}>
        {parentHeader + " >>"}&nbsp;
      </p>
      <p className="breadCrumb_childHeader mb-0" onClick={() => { onClickChild && onClickChild() }}>{childHeader}</p>
      {secondChild && (
        <p onClick={() => onClickSecondChild && onClickSecondChild()} className="breadCrumb_childHeader mb-0 mx-1">
          {" >> " + secondChild}
        </p>
      )}
      {thirdChild && (
        <p className="breadCrumb_childHeader mb-0 mx-1">
          {" >> " + thirdChild}
        </p>
      )}
    </div>
  );
};

export default BreadCrumb;