import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify';

import axios from '../../api/axios';
import Loader from '../../components/Loader';
import { API_URLS } from '../../constants/API_URLS';
import { getOnlyTwoDigitsAfterDecimal } from '../../utils/utils';
import ServicesTable from '../../components/common/Table/CXOServicesTable/table';
import ServicesBarChart from '../../components/common/Charts/CXOHorizontalBarChart'
import { ErrorMessage, ServicesInsightLayerTableHead } from '../../constants/constant';

const ServicesInsightLayer = () => {
    const [serviceInsightsData, setServiceInsightsData] = useState({ insightsKey: [], labels: [], barData: [], tableCols: [], tableData: [] });

    const getServiceInsightData = async () => {
        try {
            const response = await axios.get(API_URLS?.CXO?.getServiceCost);
            getInsights(response);
            const result = response?.data?.data;
            const labels = result.map(({ service }) => service);
            let years = Object.keys(result[0]?.monthsCost).sort();
            const barData = years.map((year, index) => ({
                name: year,
                currency:result[index]?.currency,
                data: result.map(({ monthsCost }) => monthsCost[year] || 0)
            }));
            setServiceInsightsData((prevState) => ({ ...prevState, labels, barData }));
        } catch (err) {
            setServiceInsightsData((prevState) => ({ ...prevState, barData: [] }));
            toast.error(ErrorMessage?.serviceCost);
        }
    };

    const getServiceTableData = async () => {
        try {
            const response = await axios.get(API_URLS?.CXO?.getServiceCostTable);
            const result = response?.data?.data;
            const tableCols = [...ServicesInsightLayerTableHead, ...Object.keys(result[0]?.serviceCost).map((data) => ({ text: data, dataKey: data, colSpan: 1 }))]
            const tableData = result?.map((data) => {
                const obj = {};
                Object.entries(data?.serviceCost).forEach(([key, val]) => {
                    obj[key] = getOnlyTwoDigitsAfterDecimal(val)
                })
                return {
                    month: data?.month,
                    accountId: data?.accountId,
                    ...obj
                }
            });
            setServiceInsightsData((prevState) => ({
                ...prevState,
                tableCols,
                tableData
            }))
        } catch (error) {
            toast.error(ErrorMessage?.serviceCost)
            setServiceInsightsData((prevState) => ({
                ...prevState,
                tableData: []
            }))
        }
    }

    const getInsights = async (postParams) => {
        try {
            const result = await axios.post(API_URLS?.CXO?.getYearInsights, postParams);
            setServiceInsightsData(prevState => ({
                ...prevState,
                insightsKey: result?.data?.data[0]?.message.filter(data => data)
            }));
        }
        catch (er) {
            toast.error(ErrorMessage?.getYearInsights)
            setServiceInsightsData(prevState => ({
                ...prevState,
                insightsKey: []
            }));
        }
    }

    useEffect(() => {
        getServiceInsightData();
        getServiceTableData();
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 thirdLayer text-light px-4 ">
                    <div className="container-fluid">
                        <div className="d-flex flex-row serviceInsightsWrapper">
                            <div className='serviceInsightsWrapper_graphWrapper'>
                                <div className="bg-light mt-4 mb-4" >
                                    <div>
                                        {
                                            serviceInsightsData.barData.length <= 0 ?
                                                <div className='loaderDiv'>
                                                    <Loader />
                                                </div>
                                                :
                                                <ServicesBarChart
                                                    barData={serviceInsightsData?.barData}
                                                    yAxisData={serviceInsightsData?.labels}
                                                />
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className=" bg-light mb-4" >
                                        <div className='serviceInsight_table'>
                                            {
                                                serviceInsightsData?.tableData.length <= 0 ?
                                                    <div className='loaderDiv'>
                                                        <Loader />
                                                    </div>
                                                    :
                                                    <div className='serviceInsightsTableWrapper'>
                                                        <ServicesTable tableHead={serviceInsightsData?.tableCols} tableData={serviceInsightsData?.tableData} />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="thirdLayer text-light">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="first-layer-blue pt-2 px-3">Insights</div>
                                        {
                                            serviceInsightsData?.insightsKey?.length <= 0
                                                ?
                                                <div className='loaderDiv'>
                                                    <Loader />
                                                </div>
                                                :
                                                <div className='px-3'>
                                                    <ul className=' serviceInsightsPoints'>
                                                        {serviceInsightsData?.insightsKey?.map(data => <li>{data}</li>)}
                                                    </ul>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesInsightLayer;
