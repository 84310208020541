import React, { useEffect, useReducer } from "react";

import { Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Tabs from "react-bootstrap/Tabs";

import axios from "../../../../../api/axios";
import { ToastError } from "../../../../../utils/utils";
import infoIcon from "../../../../../Images/infoIcon.svg";
import refreshIcon from "../../../../../Images/refresh.svg";
import { API_URLS } from "../../../../../constants/API_URLS";
import { ROUTE_PATH } from "../../../../../constants/Routes";
import CommonTooltip from "../../../../../components/Tooltip";
import filterIcon from "../../../../../Images/filterFilled.svg";
import BreadCrumb from "../../../../../components/common/BreadCrumb";
import CommonTable from "../../../../../components/Infra/Table/table";
import ViewInstanceModal from "../ViewInstanceModal/viewInstanceModal";
import { LocalStorageManager } from "../../../../../utils/LocalStorageManager";
import { localStorageHeaders, toastIds } from "../../../../../constants/constant";
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import TableColumnSearch from "../../../../../components/common/TableSearchInput/tableSearch";

import "../../../../../style/Infra/serverGroupName.scss";
const initialState = {
  selectedServerGroup: null,
  tableData: [],
  infoData: null,
  isAdded: false,
  openViewInstanceModal: false,
  currentPage: 1,
  filterCheckboxDisabled: false,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function ServerGroupId() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const columns = [
    {
      name: "Instance ID",
      selector: (row) => CommonTooltip(row.instanceId),
       
    },
    {
      name: "Instance Name",
      selector: (row) => CommonTooltip(row.instanceName),
       
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
       
    },
    {
      name: "Account Number",
      selector: (row) => row.accountId,
       
    },
    {
      name: "Region Name",
      selector: (row) => row.regionName,
       
    },
    {
      name: "Region Code",
      selector: (row) => row.regionCode,
       
    },
    {
      name: "Path Policy",
      selector: (row) => row.pathPolicy,
       
    },
    {
      name: "Operating System",
      selector: (row) => row.operatingSystem,
       
    },
    {
      name: "Operation Type",
      selector: (row) => row.operationType,
       
    },
    {
      name: "Operation Start Time",
      selector: (row) => row.operationStartTime,
       
    },
    {
      name: "Operation End Time",
      selector: (row) => row.operationEndTime,
       
    },
    {
      name: "Failed Patches Count",
      selector: (row) => row.failedPatchesCount,
       
    },
    {
      name: "Missing Patches Count",
      selector: (row) => row.missingPatchesCount,
       
    },
    {
      name: "Not Applicable Patches Count",
      selector: (row) => row.notApplicablePatchesCount,
       
    },
    {
      name: "Installed Patches Count",
      selector: (row) => row.installedPatchesCount,
       
    },
    {
      name: "Installed Other Patches Count",
      selector: (row) => row.installedOtherPatchesCount,
       
    },
    {
      name: "Installed Rejected Patches Count",
      selector: (row) => row.installedRejectedPatchesCount,
       
    },
    {
      name: "",
      selector: (row) => row.action,
       
    },
  ];

  const getInstances = () => {
    const selectedServer = JSON.parse(
      LocalStorageManager.getValue(localStorageHeaders.selectedServerGroup)
    );
    let postBody = {
      group_id: selectedServer?.group_id ? selectedServer?.group_id : null,
      groupName: null,
      Description: null,
      created_by: null,
      created_at: null,
      updated_by: null,
      updated_at: null,
      instanceIDModelList: null,
    };
    axios
      .post(
        API_URLS.infra.compute.servers.getInstancesOfParticularServerGroup,
        postBody
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          let result = response?.data?.data?.map((data) => {
            return {
              ...data,
              action: (
                <img
                  onClick={() => {
                    setData({
                      infoData: data,
                      openViewInstanceModal: !state.openViewInstanceModal,
                    });
                  }}
                  src={infoIcon}
                  className="serverGroupName_dotIcon"
                  alt="awsIcon"
                />
              ),
            };
          });
          setData({ tableData: result });
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId);
      });
  };

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableData.map((data, index) => {
        let newItem = {
          ...data,
          action: (
            <img
              onClick={() => {
                setData({
                  infoData: data,
                  openViewInstanceModal: !state.openViewInstanceModal,
                });
              }}
              src={infoIcon}
              className="serverGroupName_dotIcon"
              alt="awsIcon"
            />
          ),
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    instanceId: (
      <TableColumnSearch
        column={"instanceId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    instanceName: (
      <TableColumnSearch
        column={"instanceName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountName: (
      <TableColumnSearch
        column={"accountName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountId: (
      <TableColumnSearch
        column={"accountId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionName: (
      <TableColumnSearch
        column={"regionName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionCode: (
      <TableColumnSearch
        column={"regionCode"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    pathPolicy: (
      <TableColumnSearch
        column={"pathPolicy"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    operatingSystem: (
      <TableColumnSearch
        column={"operatingSystem"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    operationType: (
      <TableColumnSearch
        column={"operationType"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    operationStartTime: (
      <TableColumnSearch
        column={"operationStartTime"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    operationEndTime: (
      <TableColumnSearch
        column={"operationEndTime"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    failedPatchesCount: (
      <TableColumnSearch
        column={"failedPatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    missingPatchesCount: (
      <TableColumnSearch
        column={"missingPatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    notApplicablePatchesCount: (
      <TableColumnSearch
        column={"notApplicablePatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    installedPatchesCount: (
      <TableColumnSearch
        column={"installedPatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    installedOtherPatchesCount: (
      <TableColumnSearch
        column={"installedOtherPatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    installedRejectedPatchesCount: (
      <TableColumnSearch
        column={"installedRejectedPatchesCount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  useEffect(() => {
    const selectedServer = JSON.parse(
      LocalStorageManager.getValue(localStorageHeaders.selectedServerGroup)
    );
    setData({ selectedServerGroup: selectedServer });
    getInstances();
  }, []);

  return (
    <div className="serverGroupName">
      <ViewInstanceModal
        show={state.openViewInstanceModal}
        serverDetails={state.infoData}
        onHide={() => setData({ openViewInstanceModal: false })}
      />
      <div className="container-fluid px-4 serverGroupName">
        <div className="mt-2 mb-2">
          <BreadCrumb
            parentHeader={"Server Groups"}
            onClickParent={() => navigate("/compute/servergroup")}
            childHeader={state.selectedServerGroup?.groupName}
          />
        </div>{" "}
        <div className="d-flex justify-content-around shadow pt-4">
          <div className="">
            <label className="titles">Name</label>
            <p className="subdata">
              {state.selectedServerGroup?.groupName || "-"}
            </p>
          </div>
          <div className="">
            <label className="titles">Description</label>
            <p className="subdata">
              {state.selectedServerGroup?.description || "-"}
            </p>
          </div>
          <div className="">
            <label className="titles">Instance Count</label>
            <p className="subdata">
              {state.selectedServerGroup?.instanceIDModelList?.length === 1
                ? state.selectedServerGroup?.instanceIDModelList?.length +
                  " instance"
                : state.selectedServerGroup?.instanceIDModelList?.length +
                  " instances"}
            </p>
          </div>
        </div>
        <div className="mt-4">
          <Tabs variant="underline" defaultActiveKey="Offers" className="mb-3">
            <Tab eventKey="Offers" title="Instances" className="tab"></Tab>
          </Tabs>
        </div>
        <div className="container_style">
          <div className="d-flex flex-wrap justify-content-between top-container mt-4">
            <div className="d-flex gap-3 flex-wrap"></div>

            <div className="d-flex flex-wrap">
              <TableButton
                icon={
                  <img
                    onClick={() => OnFilterClick()}
                    className="serverGroupName_tableIcons"
                    src={filterIcon}
                    alt="copyIcon"
                  ></img>
                }
                title={"Filter"}
              />
              <TableButton
                icon={
                  <img
                    className="serverGroupName_tableIcons"
                    src={refreshIcon}
                    onClick={() => getInstances()}
                    alt="copyIcon"
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          <CommonTable
            removeSearchCheckbox={state.filterCheckboxDisabled}
            onChangePage={(data) => {
              setData({ resetPagination: false, currentPage: data });
            }}
            resetPagination={state.resetPagination}
            columns={columns}
            onSelectedRowsChange={(data) =>
              setData({ selectedRowsTable: data })
            }
            data={state.tableData}
            selectableRows={true}
            loading={state.loading}
          />
        </div>
      </div>
    </div>
  );
}
