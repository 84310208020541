import React, { useState } from "react";
import deleteIcon from "../../../../Images/deleteIcon.svg";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { commonConstants } from "../../../../constants/constant";
import '../../../../style/Infra/deleteModalSnapshot.scss';

const DeleteModal = (props) => {
  const onConfirm = () => {
    const deleteBody = props?.selectedRows?.map((data) => {
      return {
        snapshotId: data?.snapshotId,
        imageName: null,
        accountId: data?.accountId,
        description: null,
        operation: commonConstants?.delete,
        key: null,
        value: null,
      };
    });
    props.onDelete(deleteBody);
    props.onHide();
  };
  return (
    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="grid-example">
        <div className="text-center">
          <img
            src={deleteIcon}
            alt="deleteIcon"
            className="deleteModal_deleteIcon"
          ></img>
        </div>
        <p className="deleteModal_deleteTxt text-center">
          Are you sure you want to delete {props?.selectedRows?.length } selected snapshots?
        </p>
        <div className="deleteModal_list">
          {props?.selectedRows?.map((data) => (
            <div className="mt-2 d-flex justify-content-between flex-wrap">
              <p>{data?.snapshotId}</p>
              <p>-</p>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-center gap-3">
          <Button
            onClick={() => {
              onConfirm();
            }}
            variant="outline-secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm();
            }}
            variant="danger"
          >
            Yes, Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
