import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { localStorageValues } from "../../../constants/constant";

const Select = ({ data, getSelectedValue, restrictInitialCall, placeholder, defaultSelectedVal,notFirstSelected }) => {
  const [valueSelected, setValueSelected] = useState('');
  useEffect(() => {
    defaultSelectedVal && getSelectedValue(defaultSelectedVal)
  }, [defaultSelectedVal])

  useEffect(() => {
    const storedSelectedValue = localStorage.getItem(localStorageValues.SelectedValueSelect);
    if(notFirstSelected)
    {
      setValueSelected('');
      getSelectedValue();
    }
    if (storedSelectedValue) {
      setValueSelected(storedSelectedValue);
      getSelectedValue(storedSelectedValue);
    }
    else
    {
      setValueSelected(data[0]);
      getSelectedValue(data[0]);  
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(localStorageValues.SelectedValueSelect, valueSelected);
  }, [valueSelected]);

  return (
    <Form.Select
      size="sm"
      onChange={(e) => { setValueSelected(e?.target?.value); getSelectedValue(e?.target?.value) }}
      className="CreateRule_RuleScope_Dropdown"
      aria-label="Default select example"
      value={defaultSelectedVal ? defaultSelectedVal : valueSelected}
    >
      {
        placeholder ?
          <>
            <option value={placeholder} selected disabled>{placeholder}</option>
            {data?.map((data) => (
              <option value={data?.value}>{data?.key}</option>
            ))}
          </>
          : data?.map((data) => (
            <option value={data?.value}>{data?.key}</option>
          ))
      }
    </Form.Select>
  );
};

export default Select;
