import React from "react";
import '../../../style/Components/Common/tableSearch.scss'
const TableColumnSearch = ({ column, onChange }) => {
  return (
    <div className="table-search">
      <input
        className="table-input-search form-control"
        name={column}
        onChange={(e) => {
          onChange && onChange(e?.target?.value, column);
        }}
        placeholder="Search...."
        //onKeyUp={processChange}
      />
    </div>
  );
};
export default TableColumnSearch;
