import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

export default function CheckBoxDropdown({
  Options,
  placeholder,
  identifier,
  className,
  getSelectedValue,
  clear,
  getIsSelectAllValue
}) {
  const [selectValue, setSelectValue] = useState([]);
  const [selectLabel, setSelectedLabel] = useState([]);

  const isAllSelected =
    (Options.length > 0 && selectValue.length) === Options.length;
  const optionValue = Options.map((item) => item.value);
  const optionLabel = Options.map((item) => item.label);

  const handleValue = (e) => {
    const value = e.target.value;
    if (value.includes("all")) {
      getSelectedValue &&
        getSelectedValue(
          (selectValue && selectValue.length) === (Options && Options.length)
            ? []
            : optionValue
        );
      setSelectValue(
        (selectValue && selectValue.length) === (Options && Options.length)
          ? []
          : optionValue
      );
      setSelectedLabel(
        (selectValue && selectValue.length) === (Options && Options.length)
          ? []
          : optionLabel
      );
      return;
    }
    setSelectValue(value);
    getSelectedValue && getSelectedValue(value);
    setSelectedLabel(
      value.map((opval) => {
        const option = Options.find((item) => item.value === opval);
        return option ? option.label : "";
      })
    );
  };

  useEffect(() => {
    if (Array.isArray(selectLabel) && selectLabel.length > 0) {
      document.querySelector(`#multi-select-${identifier}`).innerHTML =
        selectLabel.join(", ");
    } else if (!Array.isArray(selectLabel)) {
      document.querySelector(`#multi-select-${identifier}`).innerHTML =
        selectLabel;
    } else {
      document.querySelector(`#multi-select-${identifier}`).innerHTML =
        placeholder;
    }
  }, [selectLabel, identifier]);
  useEffect(() => {
    setSelectValue([]);
    setSelectedLabel([]);
    getSelectedValue && getSelectedValue([]);
  }, [clear]);

  return (
    <div>
      <FormControl className={`${className ? className : "checkbox_style"}`}>
        <Select
          value={selectValue}
          multiple
          id={`multi-select-${identifier}`} // Add an identifier to the id attribute
          onChange={handleValue}
          renderValue={(selected) => {
            selected.join(" ");
          }}
          className="pading_tyle "
        >
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox onClick={(e)=>{getIsSelectAllValue(e?.target?.checked)}} checked={isAllSelected}></Checkbox>
            </ListItemIcon>
            <ListItemText primary="Select All"></ListItemText>
          </MenuItem>
          {Options.map((op) => (
            <MenuItem key={op.id} value={op.value}>
              <ListItemIcon>
                <Checkbox
                  name="select-checkbox"
                  checked={selectValue.includes(op.value)}
                ></Checkbox>
              </ListItemIcon>
              <ListItemText primary={op.label}></ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
