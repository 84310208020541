import React, { useEffect, useReducer } from "react";

import { BiDotsHorizontalRounded, BiTag } from "react-icons/bi";

import { Images } from "./imagesconst";
import AccordionBody from "./accordionBody";
import awsIcon from "../../Images/awsIcon.svg";
import Modal from "../common/Modal/InfraModal/Modal";
import tagIconEnabled from "../../Images/tagEnabled.svg";
import cloudShieldIcon from "../../Images/cloudShield.svg";
import cloudFormationIcon from "../../Images/cloudFormation.svg";
import { inventoryDropdownValues } from "../../constants/constant";
import EnvironmentModal from "../../pages/Infra/inventory/envrionmentModal";

import "../../style/Infra/Inventory/accordion.scss";

const initialState = {
  activeIndex: null,
  loading: true,
  selectedValueDropdown: "",
  isEnvironmentOpen: false,
  istagOpen: false,
};

const accordionReducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const Accordion = ({
  vpcData,
  items,
  selectedValue,
  masterAccountDetails,
  selectedAccountIds,
  selectedResource,
  selectedRegion,
}) => {
  const [state, dispatch] = useReducer(accordionReducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };
  const ENVRIONMENT_STYLE = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    zIndex: 1000,
    width: "100%",
    height: "100%",
  };

  const openModalTag = (modalName) => {
    modalName === "Tag"
      ? setData({ isTagOpen: true })
      : setData({ isEnvironmentOpen: true });
  };
  const closeModalTag = (modalName) => {
    modalName === "Tag"
      ? setData({ isTagOpen: false })
      : setData({ isEnvironmentOpen: false });
  };

  const handleAccordionClick = (index) => {
    setData({ activeIndex: state.activeIndex === index ? null : index });
  };

  const handleIconClick = (event) => {
    event.stopPropagation(); // Stop event propagation to prevent accordion from opening
  };

  useEffect(() => {
    setData({ selectedValueDropdown: selectedValue });
  }, [selectedValue]);

  return (
    <div className="accordion  accordion_style" id="myAccordion">
      {items?.length > 0 ? (
        <>
          {items?.map((item, index) => (
            <div
              className="border accordion-item accordionBody_shadow mb-3 mx-3"
              key={index}
            >
              <h2 className="accordion-header" id={`heading${index}`}>
                <div>
                  <button
                    className={`accordion-button ${
                      state.activeIndex === index ? "" : "collapsed"
                    }`}
                    type="button"
                    onClick={() => handleAccordionClick(index)}
                    aria-expanded={
                      state.activeIndex === index ? "true" : "false"
                    }
                    aria-controls={`collapse${index}`}
                  >
                    <div className="d-flex justify-content-between space_btween">
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="d-flex align-items-center">
                          {state.selectedValueDropdown ===
                          inventoryDropdownValues.tags ? (
                            <div className="acc_img">
                              <img
                                src={tagIconEnabled}
                                alt="img"
                                className="img_style"
                              ></img>
                            </div>
                          ) : state.selectedValueDropdown ===
                            inventoryDropdownValues.VPC ? (
                            <div className="acc_img">
                              <img
                                src={cloudShieldIcon}
                                alt="img"
                                className="img_style"
                              ></img>
                            </div>
                          ) : state.selectedValueDropdown ===
                            inventoryDropdownValues.allResources ? (
                            <div className="acc_img">
                              <img
                                src={awsIcon}
                                alt="img"
                                className="img_style"
                              ></img>
                            </div>
                          ) : (
                            <div className="acc_img">
                              <img
                                src={cloudFormationIcon}
                                alt="img"
                                className="img_style"
                              ></img>
                            </div>
                          )}
                          <div className="acc_title">
                            {item?.resourceType.replaceAll("::", " ")}
                          </div>
                        </div>
                        {selectedValue ===
                          inventoryDropdownValues.allResources && (
                          <div className="acc_count">
                            Count:&nbsp;<span>{item?.count}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </button>
                </div>
              </h2>
              {state.activeIndex === index && ( // Conditionally render AccordionBody
                <div
                  id={`collapse${index}`}
                  className={`accordion-collapse collapse ${
                    state.activeIndex === index ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#myAccordion"
                >
                  <div className="accordion-body accordion_wrapper">
                    <AccordionBody
                      selectedResource={selectedResource}
                      selectedRegion={selectedRegion}
                      selectedAccountIds={selectedAccountIds}
                      masterAccountDetails={masterAccountDetails}
                      vpcData={vpcData && vpcData?.data[state.activeIndex]}
                      value={state.selectedValueDropdown}
                      data={items[state.activeIndex]}
                    />
                  </div>
                </div>
              )}
              <Modal
                open={state.isEnvironmentOpen}
                closeOnBackgroundClick={true}
                portalId="portal"
                styles={ENVRIONMENT_STYLE}
              >
                <EnvironmentModal closeModalTag={() => closeModalTag("ENV")} />
              </Modal>
            </div>
          ))}
        </>
      ) : (
        <p>
          <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
        </p>
      )}
    </div>
  );
};

export default Accordion;
