import React from "react";
import { useState } from "react";

import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";

import '../../../../../../style/Infra/scanModalDetails.scss';

const ScanModalDetails = (props) => {
  const Descriptions = () => {
    return (
      <div className="d-flex scanModalDetails_tableWrapper flex-row gap-2">
        <Table>
          <tbody>
            <tr>
              <td>Policy ID</td>
              <td>
                arn:aws:ssm:us-east-2:733109147000:patchbaseline/pb-0e392de35e7c563b7
              </td>
            </tr>
            <tr>
              <td>Policy Description</td>
              <td>Default Patch Baseline for Amazon Linux Provided by AWS.</td>
            </tr>
            <tr>
              <td>Created Date (UTC)</td>
              <td>2018-05-03 16:41:30.787000+00:00</td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <tbody>
            <tr>
              <td>Policy Name</td>
              <td>AWS-AmazonLinuxDefaultPatchBaseline</td>
            </tr>
            <tr>
              <td>Operating System</td>
              <td>AMAZON_LINUX</td>
            </tr>
            <tr>
              <td>Modified Date (UTC)</td>
              <td>2018-05-03 16:41:30.787000+00:00</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };
  const ApprovalRules = () => {
    return (
      <div className="d-flex flex-row gap-2">
        <Table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Classification</th>
              <th>Severity</th>
              <th> Auto approval delay</th>
              <th> Compliance level</th>
              <th>Include non-security updates</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>*</td>
              <td>Security </td>
              <td>Critical, Important</td>
              <td>7 </td>
              <td>UNSPECIFIED</td>
              <td>No</td>
            </tr>
            <tr>
              <td>*</td>
              <td>Security </td>
              <td>Critical, Important</td>
              <td>7 </td>
              <td>UNSPECIFIED</td>
              <td>No</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };
  return (
    <>
      <Modal
        {...props}
        size="xl"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Policy Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div className="scanModalDetails">
            <div className="d-flex justify-content-between px-3 shadow pt-4 ">
              <div className="">
                <label className="titles">Policy Name</label>
                <p className="subdata">AWS-AmazonLinuxDefaultPatchBaseline</p>
              </div>
              <div className="">
                <label className="titles">Operating System</label>
                <p className="subdata">AMAZON_LINUX</p>
              </div>
              <div className="">
                <label className="titles">Owner</label>
                <p className="subdata">AWS</p>
              </div>
            </div>
            <div className="mt-4">
              <Tabs defaultActiveKey="Descriptions" className="mb-3">
                <Tab
                  eventKey="Descriptions"
                  title="Descriptions"
                  className="tab"
                >
                  <Descriptions></Descriptions>
                </Tab>
                <Tab
                  eventKey="ApprovalRules"
                  title="Approval Rules"
                  className="tab"
                >
                  <ApprovalRules />
                </Tab>
                <Tab
                  eventKey="PatchExceptions"
                  title="Patch Exceptions"
                  className="tab"
                >
                  <p>stu</p>

                  {/* <RemoteSessions /> */}
                </Tab>
                <Tab
                  eventKey="PatchSource"
                  title="Patch Source"
                  className="tab"
                >
                  <p>stu</p>

                  {/* <RemoteSessions /> */}
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.onHide()}>Hide Details </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScanModalDetails;
