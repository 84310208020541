import React, { useEffect, useState } from 'react'
import semicolon from '../../Images/semicolon.svg'
import bulb from '../../Images/bulb.svg'



const SecondLayer = () => {
  const [costData, setCostData] = useState([])
  
 
  function getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }



    return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 color-combo text-light px-4 ">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-6 color-combo text-light p-1 costSavingContainerLeft">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="first-layer-blue pt-2">Cost savings</div>
                    <div className="mt-5 first-layer-text">Customers who move to AWS reduce the percentage of IT spend on infrastructure, freeing budget for reinvestment in other areas.</div>
                    <div className="mt-5 d-flex align-items-start gap-3 mx-0">
                      <img src={semicolon}  className="first-layer-image1 p-0" alt="Image3" />
                      <div className="text-start first-layer-sentence w-100">Conscious optimization of infrastructure in Cloud has helped realize 53% reduction in overall operational expenses. Thanks to smart optimization mechanisms offered, we're soaring with significant cost savings which empowers us to invest more in innovation and growth.</div>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-light h-100 mt-4 mb-4">
                <div className="row ">
                  <div className="col-12 col-md-6 bulb-top">
                    <div className="bulb-top">
                      <h4>
                        <b>Overall AWS Billing</b>
                      </h4>
                      <h1 className="slide-effect dollar-value">
                        <b> {costData.currentCost ? "$" + costData.currentCost : "$"+122} </b>
                      </h1>
                      <p className='fs-18'>Total cost across AWS accounts</p>
                    </div>
                    <div className="bulb-top">
                      <p>Decreases as customers mature and scale on AWS</p>
                      <h1 className={`slide-effect dollar-value ${costData.compareState === "negative" ?'negativeCompareStateTxt':'positiveCompareStateTxt'}`}>
                      <b> {costData.costCompare ? costData.costCompare+"%" : 10+"%"} </b>
                      </h1>
                      <p className='fs-18'>Reduction in <b>overall spend per mature user</b></p>
                    </div>
                    <h1 className="slide-effect firstLayerServiceData"><b>{costData.serviceData ? costData?.serviceData?.length : 8+"%"}</b></h1>
                    <h4 className='fs-20'><b>Lower on AWS</b></h4>
                    <p className='fs-20'>vs. multi-cloud customers</p>
                  </div>
                  <div className="col-6">
                    <img src={bulb} className="bulb" alt="Image2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondLayer;
