import React, { useEffect } from "react";
import { get } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createGlobalStyle } from "styled-components";

import { DefaultTheme } from "../../constants/constant";

const ApplicationThemeProvider = () => {
  const { applicationTheme } = useSelector(
    (store) => ({
      applicationTheme: get(store, "theme.data", DefaultTheme),
    }),
    shallowEqual
  );

  const CSSProvider = createGlobalStyle`
  :root {
    --base-font: ${() => applicationTheme.base.baseFont};
    --base-header-font: ${() => applicationTheme.base.headerFont};
    --background: ${() => applicationTheme.base.backgroundColor};
    --base-color: ${() => applicationTheme.base.baseColor};
    --base-header-color: ${() => applicationTheme.base.headerColor};

    --sidebar-background: ${() => applicationTheme.sidebar.backgroundColor};
    --sidebar-active-background: ${() =>
      applicationTheme.sidebar.activeBackgroundColor};
    --sidebar-color: ${() => applicationTheme.sidebar.color};
    --sidebar-active-color: ${() => applicationTheme.sidebar.activeColor};

    --card-background: ${() => applicationTheme.card.backgroundColor};
    --card-color-primary: ${() => applicationTheme.card.primaryColor};
    --card-color-primary-light: ${() =>
      applicationTheme.card.lightPrimaryColor};
    --card-color-secondary: ${() => applicationTheme.card.secondaryColor};
    --card-color-secondary-light: ${() =>
      applicationTheme.card.lightSecondaryColor};

    --table-background-header: ${() =>
      applicationTheme.table.headerBackgroundColor};
    --table-background-body: ${() =>
      applicationTheme.table.bodyBackgroundColor};
    --table-color-header: ${() => applicationTheme.table.headerColor};
    --table-color-body: ${() => applicationTheme.table.bodyColor};
    --table-background-body-hover: ${() =>
      applicationTheme.table.hoverBackgroundColor};
    --table-color-body-hover: ${() => applicationTheme.table.hoverColor};

    --tile-background: ${() => applicationTheme.tile.backgroundColor};
    --tile-background-active: ${() =>
      applicationTheme.tile.activeBackgroundColor};
    --tile-color: ${() => applicationTheme.tile.color};
    --tile-color-active: ${() => applicationTheme.tile.activeColor};

    --button-primary-background: ${() =>
      applicationTheme.button.primaryBackgroundColor};
    --button-primary-color: ${() => applicationTheme.button.primaryColor};
    --button-secondary-background: ${() =>
      applicationTheme.button.secondaryBackgroundColor};
    --button-secondary-color: ${() => applicationTheme.button.secondaryColor};
  }
  `;

  return <CSSProvider />;
};

export default ApplicationThemeProvider;
