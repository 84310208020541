import React, { PureComponent } from "react";

import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Colors from "../../../../constants/Colors";
import { CustomYAxisTick } from "../CustomYAxisTick";
import { graphDataManipulationForLengthData } from "../../../../utils/utils";


export default class Example extends PureComponent {
  render() {
    const receivedData = this?.props?.data;
    const isPosture= this?.props?.isPosture;
    const keysToKeep = Object.keys(receivedData?.[0])?.filter(
      (key) => key !== "name"
    );
    const manipulatedData = graphDataManipulationForLengthData(receivedData);

    const allValues = manipulatedData.reduce((acc, entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "name" && entry[key] >= 0) {
          acc.push(entry[key]);
        }
      });
      return acc;
    }, []);

    const minValue = Math.min(...allValues);
    const maxValue = Math.ceil(Math.max(...allValues)); // Round up to the nearest integer

    // Calculate padding for better visualization
    const padding = (maxValue - minValue) * 0;

    // Calculate the maximum value for Y-axis ticks in increments of 10
    const maxYAxisValue = Math.ceil(maxValue / 10) * 10;

    const newArray = manipulatedData?.map((item) => {
      const newItem = {};
      keysToKeep.forEach((key) => {
        newItem[key] = item[key];
      });
      return newItem;
    });
    const uniqueKeys = newArray.reduce((keys, obj) => {
      Object.keys(obj).forEach((key) => keys.add(key));
      return keys;
    }, new Set());
    const colors = [
      Colors.PINK,
      Colors.YELLOW,
      Colors.OLIVEGREEN,
      Colors.LIGHTSKYBLUE,
      Colors.LAVENDERBLUE,
      Colors.PEACH,
      Colors.ORANGE,
    ];
    let UniqueKeysArray = Array.from(uniqueKeys);
    UniqueKeysArray.push('others');
    const arrayOfObjects = UniqueKeysArray.map((key, index) => {
      const color = index < colors.length ? colors[index] : null;
      return { key, color };
    });
    const { currency, xAxisLegend, yAxisLegend } = this.props;
    return (
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={manipulatedData}
          margin={{
            top: 20,
            right: 50,
            bottom: 20,
            left: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          {
            isPosture?
          <YAxis
            tickCount={maxYAxisValue / 10 + 1}
            domain={[Math.max(0, minValue - padding), maxYAxisValue + padding]}
            tickFormatter={(value) => Math.round(value)}
            unit={currency || ""}
            tick={<CustomYAxisTick currency={currency} />}
          >
            <Label value={yAxisLegend} position="insideLeft" angle={-90} dx={1} dy={-17} />
          </YAxis>:
          <YAxis
            unit={currency || ""}
            tick={<CustomYAxisTick currency={currency} />}
          >
            <Label value={yAxisLegend} position="insideLeft" angle={-90} dx={1} dy={-17} />
          </YAxis>
          }
          <Tooltip
            formatter={(value) => `${currency || ''}${value} `}
          />
          {manipulatedData.length > 0 && uniqueKeys < 20 && (
            <Legend />
          )}
          {manipulatedData ? (
            arrayOfObjects?.map((data) => (
              <Line
                // unit={currency || ""}
                type="monotone"
                dataKey={data?.key}
                stroke={data?.color}
                name={xAxisLegend}
                dot={false}

              />

            ))
          ) : (
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" dot={false} />

          )}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
