import * as Yup from "yup";
import { ARTICLE_MODES } from "../../constants/constant";


export const CreateUserValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    contact: Yup.string().required("Contact is required."),
    role_id: Yup.string().required("Role is required."),
    project_ids: Yup.array().of(Yup.mixed()),
  });

export const CreateChangeRequestValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    effort: Yup.string().required("Effort is required."),
    man_days: Yup.number().nullable().required("Man days is required."),
    resource_count: Yup.number()
      .nullable()
      .required("Resource Count is required."),
    project_id: Yup.mixed().required("Project is required."),
  });

export const CreatePriorityValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    etc: Yup.number()
      .nullable()
      .min(21, "ETC Should be more than 20 minutes.")
      .required("ETC is required."),
    tat: Yup.number()
      .nullable()
      .min(21, "TAT Should be more than 20 minutes.")
      .required("TAT is required."),
    pat: Yup.number()
      .nullable()
      .min(21, "PTAT Should be more than 20 minutes.")
      .required("PTAT is required."),
    color: Yup.string().required("Badge Color is required."),
    bg_color: Yup.string().required("Badge BG Color is required."),
  });

export const CreateRoleValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),
    permissions: Yup.array().of(Yup.mixed()).notRequired(),
  });

export const CreateCustomerValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateProjectValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),
    banner: Yup.string().notRequired(),
    customer_id: Yup.string().required("Customer is required."),
    code: Yup.string()
      .matches(
        /^[A-Z]{3}\d{3}$/,
        "Project Code should be in format - ABC123. First three characters should be in range A-Z and next three characters should be in range 0-9."
      )
      .required("Project code is required"),
    sla_configuration: Yup.array().of(
      Yup.object().shape({
        priority_id: Yup.number().required(),
        etc: Yup.number()
          .nullable()
          .min(21, "ETC Should be more than 20 minutes.")
          .required("ETC is required."),
        tat: Yup.number()
          .nullable()
          .min(21, "TAT Should be more than 20 minutes.")
          .required("TAT is required."),
        pat: Yup.number()
          .nullable()
          .min(21, "PTAT Should be more than 20 minutes.")
          .required("PTAT is required."),
      })
    ),
  });

export const CreateCategoryValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateStatusValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateHRTicketValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateFieldValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateInventorySourceValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateInventoryCategoryValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    source_id: Yup.mixed().required("Source is required."),
  });

export const CreateWebhookConsumerValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    description: Yup.mixed().required("Description is required."),
  });

export const CreateCloudProviderValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
  });

export const CreateCloudServiceValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    cloud_type_id: Yup.string().required("Cloud Provider is required."),
  });

export const CreateCloudServiceFieldValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    cloud_service_id: Yup.string().required("Cloud Service is required."),
    field_id: Yup.string().required("Field Type is required."),
    values: Yup.mixed().notRequired(),
  });

export const LoginValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    password: Yup.string().required("Password is required."),
  });

export const SignupValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    contact: Yup.string().required("Contact is required."),
  });

export const ForgotPasswordValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
  });

export const ResetPasswordValidationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required."),
    new_password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Password is too weak."
      )
      .required("New Password is required"),
    token: Yup.string().notRequired(),
    password: Yup.string().when("token", {
      is: (token) => !token,
      then: Yup.string().required("Old Password is required."),
      otherwise: Yup.string().notRequired(),
    }),
  });

export const CreateArticleValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Article Name is required."),
    description: Yup.string().required("Article Description is required."),
    article: Yup.string().required("Article is required."),
    status: Yup.string()
      .oneOf([ARTICLE_MODES.DRAFT, ARTICLE_MODES.PUBLISHED])
      .required("Article Status is required."),
    tags: Yup.array().notRequired(),
    attachments: Yup.array().notRequired(),
  });

export const CreateTicketValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Ticket Name is required."),
    description: Yup.string().required("Ticket Description is required."),
    category_id: Yup.string().required("Category is required."),
    priority_id: Yup.string().required("Priority is required."),
    project_id: Yup.mixed().required("Project is required."),
    hr_ticket_type_id: Yup.string().nullable().notRequired(),
    cloud_service_id: Yup.mixed().nullable().notRequired(),
    is_new_cloud_service: Yup.boolean().notRequired(),
    cloud_data: Yup.mixed().nullable().notRequired(),
    start_date: Yup.string().nullable().notRequired(),
    end_date: Yup.string().nullable().notRequired(),
    notify_client: Yup.boolean().notRequired(),
    assign_to: Yup.mixed().required("Asignee is required."),
    tags: Yup.array().notRequired(),
    observers: Yup.array().notRequired(),
  });

export const UpdateTicketValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Ticket Name is required."),
    description: Yup.string().required("Ticket Description is required."),
    category_id: Yup.string().required("Category is required."),
    priority_id: Yup.string().required("Priority is required."),
    project_id: Yup.mixed().required("Project is required."),
    hr_ticket_type_id: Yup.string().nullable().notRequired(),
    cloud_service_id: Yup.mixed().nullable().notRequired(),
    is_new_cloud_service: Yup.boolean().notRequired(),
    cloud_data: Yup.mixed().nullable().notRequired(),
    start_date: Yup.string().nullable().notRequired(),
    end_date: Yup.string().nullable().notRequired(),
    notify_client: Yup.boolean().notRequired(),
    tags: Yup.array().notRequired(),
    observers: Yup.array().notRequired(),
  });

export const CreateWebhookValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Webhook Name is required."),
    description: Yup.string().required("Webhook Description is required."),
    category_id: Yup.string().required("Category is required."),
    priority_id: Yup.string().required("Priority is required."),
    webhook_consumer_id: Yup.string().required("Webhook Consumer is required."),
    project_id: Yup.mixed().required("Project is required."),
    hr_ticket_type_id: Yup.string().nullable().notRequired(),
    assign_to: Yup.mixed().required("Asignee is required."),
    observers: Yup.array().notRequired(),
    account_id: Yup.string().notRequired(),
  });

export const CreateRCASchema = () =>
  Yup.object().shape({
    incident: Yup.string().required("Incident is required."),
    incident_detection_details: Yup.string().required(
      "Incident Detection information is required."
    ),
    incident_detection_time_improvement_details: Yup.string().notRequired(),
    incident_response_handeling: Yup.string().notRequired(),

    root_cause_diagnosis: Yup.string().notRequired(),
    root_cause_diagnosis_time_improvement_details: Yup.string().notRequired(),
    sop_followed: Yup.string().notRequired(),
    corrective_actions: Yup.string().notRequired(),

    qna_details: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required("Question is required."),
          answer: Yup.string().required("Answer is required."),
        })
      )
      .notRequired(),

    event_timelines: Yup.array()
      .of(
        Yup.object().shape({
          event: Yup.string().required("Event is required."),
          description: Yup.string().required("Event Description is required."),
          scope_of_correction: Yup.string().notRequired(),
          date: Yup.string().required("Date is required."),
          time: Yup.string().required("Time is required."),
        })
      )
      .notRequired(),
    lessons_learned: Yup.string().notRequired(),
  });

export const CreateRCASchema1 = () =>
  Yup.object().shape({
    incident: Yup.string().required("Incident is required."),
    incident_detection_details: Yup.string().required(
      "Incident Detection information is required."
    ),
    incident_detection_time_improvement_details: Yup.string().notRequired(),
    incident_response_handeling: Yup.string().notRequired(),
  });

export const CreateRCASchema2 = () =>
  Yup.object().shape({
    root_cause_diagnosis: Yup.string().notRequired(),
    root_cause_diagnosis_time_improvement_details: Yup.string().notRequired(),
    sop_followed: Yup.string().notRequired(),
    corrective_actions: Yup.string().notRequired(),
  });

export const CreateRCASchema3 = () =>
  Yup.object().shape({
    qna_details: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required("Question is required."),
          answer: Yup.string().required("Answer is required."),
        })
      )
      .notRequired(),
    event_timelines: Yup.array()
      .of(
        Yup.object().shape({
          event: Yup.string().required("Event is required."),
          description: Yup.string().required("Event Description is required."),
          scope_of_correction: Yup.string().notRequired(),
          date: Yup.string().required("Date is required."),
          time: Yup.string().required("Time is required."),
        })
      )
      .notRequired(),
    lessons_learned: Yup.string().notRequired(),
  });

export const CreateBudgetSchema = () =>
  Yup.object().shape({
    staffing_budget_description: Yup.string().notRequired(),
    staffing_budget_date: Yup.string().notRequired(),
    staffing_budget_cost: Yup.number().notRequired(),

    consultation_budget_description: Yup.string().notRequired(),
    consultation_budget_date: Yup.string().notRequired(),
    consultation_budget_cost: Yup.number().notRequired(),

    asstets_budget_description: Yup.string().notRequired(),
    asstets_budget_date: Yup.string().notRequired(),
    asstets_budget_cost: Yup.number().notRequired(),

    tech_budget_description: Yup.string().notRequired(),
    tech_budget_date: Yup.string().notRequired(),
    tech_budget_cost: Yup.number().notRequired(),

    other_budget_description: Yup.string().notRequired(),
    other_budget_date: Yup.string().notRequired(),
    other_budget_cost: Yup.number().notRequired(),
  });

export const CreateInventryValidationSchema = () =>
  Yup.object().shape({
    category_id: Yup.string().required("Category is required."),
    asset_id: Yup.string().required("Asset ID is required"),
    name: Yup.string().required("Name is required."),
  });

export const CreateConfigValidationSchema = () =>
  Yup.object().shape({
    arn_id: Yup.string().required("ARN Id is required."),
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),
    category_id: Yup.string().required("Category is required."),
  });
// added login details
  export const LoginInitialValues = {
    email: "",
    password: "",
  };

