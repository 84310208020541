import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import '../../../../style/Infra/volumemodal.scss';
import { commonConstants, modifyVolumeDropdownOptions } from "../../../../constants/constant";
export default function VolumeModal(props) {
  const [selectedVolumeType, setSelectedVolumeType] = useState("");
  const onModify = () => {
    let modifyBody;
    if (props?.selectedRows?.length > 0) {
      const selectedRow = props?.selectedRows;
      modifyBody = selectedRow.map((data) => {
        return {
          volumeId: data?.volumeId,
          newName: null,
          operation: commonConstants?.modify,
          accountId: data?.accountId,
          createSnapshot: null,
          value: null,
          key: null,
          volumeType: selectedVolumeType,
          description: null,
        };
      });
    } else {
      const selectedRow = props?.singleVolume;
      modifyBody = [
        {
          volumeId: selectedRow?.volumeId,
          newName: null,
          operation: commonConstants?.modify,
          accountId: selectedRow?.accountId,
          createSnapshot: null,
          value: null,
          key: null,
          volumeType: selectedVolumeType,
          description: null,
        },
      ];
    }
    props?.closeModal();
    props?.onModifyClick(modifyBody);
  };
  return (
    <div className="container VolumeModal">
      <div className="d-flex justify-content-between pt-2 fs-5">
        <p className="title">Modify Volume Type</p>
        <button
          type="button"
          className="btn-close "
          aria-label="Close"
          onClick={props.closeModal}
        ></button>
      </div>
      <div className="Modify_volume_Modal">
        <label className="subLables pt-3">Selected Volumes</label>
        <br></br>
        {props?.volume?.map((data) => (
          <label className="subLables pt-2 px-2"> &#8226; {data}</label>
        ))}
      </div>
      <div className="mt-3 row">
        <div className="col-md-6">
          <label className="subLables">Volume Type</label>
          <Form.Select
            onChange={(e) => setSelectedVolumeType(e?.target?.value)}
            aria-label="Default select example"
          >
            <option>Select Volume Type</option>
            {modifyVolumeDropdownOptions.map((data) => (
              <option value={data?.value}>{data?.key}</option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className="btn_place">
        <Button onClick={props.closeModal} className="close_style">
          Close
        </Button>
        <Button
          disabled={!selectedVolumeType}
          onClick={() => onModify()}
          className="modify_style"
        >
          Modify Volume Type
        </Button>
      </div>
    </div>
  );
}
