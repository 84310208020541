import { combineReducers, configureStore } from "@reduxjs/toolkit";
import paginationReducer from "../Slices/PaginationSlice";
import periodCheckboxReducer from "../Slices/periodCheckboxSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import loginReducer from '../Slices/LoginSlice';
import openSidebarReducer from '../Slices/OpenSidebar';
import authReducer from '../Slices/AuthSlice';


const persistConfig = {
  key: "root",
  storage,
  // specify which reducers to persist, or omit to persist all
  whitelist: ["pagination", "login", 'auth'],
  blacklist: [],
};

const reducer = combineReducers({
  pagination: paginationReducer,
  selectedPeriods: periodCheckboxReducer,
  login: loginReducer,
  openSidebar: openSidebarReducer,
  auth: authReducer
});

const rootReducer = (state, action) => {
  if (action.type === "login/USER_LOGOUT") {
    storage.removeItem("persist:root");
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export default store;
