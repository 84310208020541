import React from "react";
import "../../../style/Infra/serverGroupCard.scss";
import { RiDeleteBinFill } from "react-icons/ri";
const ServerGroupCard = (props) => {
  return (
    <div className="serverGroupCard">
      <div className="card manage-card shadow">
        <label className="delete" onClick={props.deleteFunction}>
          <RiDeleteBinFill />
        </label>
        <label className="titles" onClick={props.clickFunction}>
          {props.title?.length > 15 ? `${props.title.slice(0,15)}...` : props.title}
        </label>
        <p className="subdata" onClick={props.clickFunction}>
          {" "}
          {props.subdata || "-"}
        </p>
        <div className="pt-3" onClick={props.clickFunction}>
          <hr></hr>
        </div>

        <div className="instant" onClick={props.clickFunction}>
          {props.instace}
        </div>
      </div>
    </div>
  );
};

export default ServerGroupCard;
