import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import { GrClose } from 'react-icons/gr';
import { TiTick } from 'react-icons/ti';
import { FaCaretRight } from 'react-icons/fa';
import { FaCaretLeft } from 'react-icons/fa';
import '../../../style/Infra/environment.scss';
import MultiDropDown from '../../../components/Infra/MultiSelectDropdown/multiSelectDropDown';
import Modal from '../../../components/common/Modal/InfraModal/Modal';
import CommonTable from '../../../components/Infra/Table/table';

export default function EnvironmentModal(props) {
    const [flag, setFlag] = useState('Details');
    const [name, setName] = useState('');
    const [envtype, setEnvType] = useState('');
    const [descrioption, setDescription] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const MODIFY = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#FFF',
        zIndex: 1000,
        width: '45%',
        height: 'auto',
    }
    const columns = [
        {
            name: 'Instance State',
            selector: row => row.title,
             
        },
        {
            name: 'Instance Id',
            selector: row => row.director,
             
        },
        {
            name: 'Name',
            selector: row => row.title,
             
        },

        {
            name: 'DAY2 Manage',
            selector: row => row.year,
             
        },
    ]
    const data = [
        {
            id: 1,
            title: 'Beetlejuice',
            year: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters',
            year: '1984',
        },
    ]
    const detailsList = [
        {
            key: 1,
            data: 'data1',
            values: "value1"
        },
        {
            key: 2,
            data: 'data2',
            values: "value2"
        },
        {
            key: 3,
            data: 'data3',
            values: "value3"
        },
        {
            key: 4,
            data: 'data4',
            values: "value4"
        }
    ]

    return (
        <div className=' environment'>

            <div className="">
                <nav className="nav">
                    <div className="start-content">
                        <label className='head_size'>Create Environment : </label>
                    </div>
                    <div className="end-content">
                        {/* <Button onClick={props.closeModal}>Close</Button> */}
                        <GrClose onClick={props.closeModal}></GrClose>
                    </div>
                </nav>
            </div>

            <div className='container-fluid '>
                <div className='row'>
                    <div className='col-md-2 bg-light side_style'>

                        <Button className={`details text-nowrap btn ${flag == 'Details' ? 'active' : ''}`}
                            onClick={() => { setFlag('Details') }}
                        >
                            <span className={`span_style ${flag == 'Details' ? 'active' : ''}`}>1</span>Environment Details
                        </Button>
                        <Button className={`selected btn ${flag == 'Resources' ? 'active' : ''}`} disabled={name === ''}
                            onClick={() => { setFlag('Resources') }}
                        >
                            <span className={`span_style ${flag == 'Resources' ? 'active' : ''}`}>2</span>Resources
                        </Button>
                        <Button className={`selected btn ${flag == 'Monitoring' ? 'active' : ''}`} disabled={name === ''}
                            onClick={() => { setFlag('Monitoring') }}
                        >
                            <span className={`span_style ${flag == 'Monitoring' ? 'active' : ''}`}>3</span>Monitoring
                        </Button>
                        <Button className={`selected btn ${flag == 'Permission' ? 'active' : ''}`} disabled={name === ''}
                            onClick={() => { setFlag('Permission') }}
                        >
                            <span className={`span_style ${flag == 'Permission' ? 'active' : ''}`}>4</span>Permission
                        </Button>
                        <Button className={`selected btn ${flag == 'Review' ? 'active' : ''}`} disabled={name === ''}
                            onClick={() => { setFlag('Review') }}
                        >
                            <span className={`span_style ${flag == 'Review' ? 'active' : ''}`}>5</span>Review
                        </Button>
                    </div>
                    <div className='col-md-10 bg-light'>

                        {flag === 'Details' ? (
                            <div className='row'>
                                <div className='col-md-5'>
                                    <h5 className='head_style'>Enter Name And Description</h5>

                                    <div>
                                        <label className='label_style'>Name</label>
                                        <input type="text" className="form-control" placeholder="Enter the name"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                        />

                                    </div>

                                    <div>
                                        <label className='label_style'>Description</label>
                                        <textarea className="form-control " placeholder="Enter Description"
                                            onChange={(e) => setDescription(e.target.value)}
                                            value={descrioption}
                                        ></textarea>
                                    </div>

                                    <div>
                                        <label className='label_style'>Environment Type</label>
                                        <input type="text" className="form-control" placeholder="Enter Environment Type"
                                            onChange={(e) => setEnvType(e.target.value)}
                                            value={envtype}
                                        />

                                    </div>



                                </div>
                                <div className='col-md-7 custom-column'>
                                    <div className='bottom-right-content'>
                                        <Button className='btn_style' disabled={name === ''}
                                            onClick={() => { setFlag('Resources') }}
                                        >
                                            <FaCaretRight /> Next : Resources</Button>
                                    </div>

                                </div>
                            </div>

                        ) :
                            flag === 'Resources' ? (
                                <div className='custom-column'>
                                    <h5 className='head_style'> The following Resources will be added to the environment</h5>
                                    <div className='d-flex flex-wrap justify-content-between top-container '>
                                        <div className='d-flex gap-3 flex-wrap'>
                                        </div>
                                        <div className='d-flex gap-3 flex-wrap '>
                                            <lable className='top-content'>Filter</lable>
                                            <lable className='top-content'>Refresh</lable>
                                        </div>
                                    </div>
                                    <CommonTable columns={columns} data={data} selectableRows={true} />

                                    <div className='bottom-right-contents pt-2'>
                                        <Button className='previous_btn'
                                            onClick={() => { setFlag('Details') }}
                                        ><FaCaretLeft />Previous</Button>
                                        <Button className='btn_style' disabled={name === ''}
                                            onClick={() => { setFlag('Monitoring') }}
                                        >
                                            <FaCaretRight /> Next : Monitoring</Button>

                                    </div>
                                </div>
                            ) :
                                flag === 'Monitoring' ? (
                                    <div className='container-fluid'>
                                        <h5 className='head_style'> Monitoring</h5>
                                        <div className='pt-3'>
                                            <Button className='btn_style'
                                                onClick={openModal}
                                            > Add Monitoring Metrics </Button>
                                        </div>
                                        <div className='bottom-right-contents pt-2'>
                                            <Button className='previous_btn'
                                                onClick={() => { setFlag('Resources') }}
                                            ><FaCaretLeft />Previous</Button>
                                            <Button className='btn_style' disabled={name === ''}
                                                onClick={() => { setFlag('Monitoring') }}
                                            >
                                                <FaCaretRight /> Next : Monitoring</Button>

                                        </div>
                                        <Modal open={isModalOpen} closeOnBackgroundClick={true} portalId="portal" styles={MODIFY}>
                                            <div className='container VolumeModal'>
                                                <div className="d-flex justify-content-between pt-2 fs-5">
                                                    <p className='title'>Add Metric</p>
                                                    <button
                                                        type="button"
                                                        className="btn-close "
                                                        aria-label="Close"
                                                        onClick={closeModal}
                                                    ></button>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-md-10 '>
                                                        <label className='subLables'>Resource Type</label>
                                                        <MultiDropDown placeholder="Select Resource Type" />
                                                    </div>

                                                    <div className='col-md-10 mt-2'>
                                                        <label className='subLables'>Resources</label>
                                                        <MultiDropDown placeholder="Select Resources" />
                                                    </div>
                                                    <div className='col-md-10 mt-2'>
                                                        <label className='subLables'>Metric</label>
                                                        <MultiDropDown placeholder="Select Metric" />
                                                    </div>
                                                </div>
                                                <div className='btn_place' >
                                                    <Button onClick={closeModal} className='close_style'>Cancel</Button>
                                                    <Button className='modify_style'>Save</Button>
                                                </div>
                                            </div>
                                        </Modal>

                                    </div>
                                )
                                    :
                                    flag === 'Permission' ? (
                                        <div className='permission container'>
                                        
                                            <div>
                                                <h5 className='head_style'> Establish Permission </h5>
                                                <p>
                                                    Executing tasks for this Environment will need a new IAM role With the
                                                    required Permission. You canwither copy permission for this from an existing role,
                                                    or create a new one from the scratch.
                                                </p>
                                                <div>
                                                    <div className="custom-switch switch_style">
                                                        <label className="left-label" htmlFor="flexSwitchCheckDefault">Copy from existing role</label>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input " type="checkbox" id="flexSwitchCheckDefault" />
                                                            {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label> */}
                                                        </div>
                                                        <label className="right-label" htmlFor="flexSwitchCheckDefault">Create new role</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-5 role_style'>
                                                <label>Select the role you would like to copy permission from </label>
                                                <MultiDropDown placeholder="Select I AM Role" />
                                            </div>
                                            <div className='bottom-right-contents pt-2'>

                                                <Button className='previous_btn'
                                                    onClick={() => { setFlag('Monitoring') }}
                                                ><FaCaretLeft />Previous</Button>
                                                <Button className='btn_style' disabled={name === ''}
                                                    onClick={() => { setFlag('Review') }}
                                                >
                                                    <FaCaretRight /> Next : Review</Button>

                                            </div>
                                        </div>
                                    ) :
                                        flag === 'Review' ? (
                                            <div className='container review'>
                                                <div className='row mt-3'>
                                                    <div className='col-md-12 heading'>
                                                        <label>Environment Details</label>
                                                    </div>
                                                    <div className='col-md-12 padding_style'>
                                                        <table className="table table-striped table-bordered">
                                                            <tbody>
                                                                {detailsList.map((dataObject) => (
                                                                    <tr key={dataObject.id}>
                                                                        <th scope="row" className='w-25 row_style'>{dataObject.data}</th>
                                                                        <td>{dataObject.values}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                <div className='bottom-right-contents pt-2'>
                                                    <Button className='previous_btn'
                                                        onClick={() => { setFlag('Permission') }}
                                                    ><FaCaretLeft />Previous</Button>
                                                    <Button className='create_btn'><TiTick />Create Environment</Button>

                                                </div>
                                            </div>
                                        ) : ""
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}