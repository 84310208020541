import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import moment from "moment";
import { API_URLS } from '../../../constants/API_URLS';
import { ToastSuccess } from '../../../utils/utils';
import { STATUSCODE } from '../../../constants/constant';

const initialState = {
  loginResponse: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    SET_LOGIN_RESPONSE: (state, action) => {
      state.loginResponse = action.payload;
    },
  },
});

export default authSlice.reducer;
export const { SET_LOGIN_RESPONSE } = authSlice.actions;
