import store from "../redux/Store";
export class LocalStorageManager {
  static getValue(key) {
    return localStorage.getItem(key);
  }

  static getToken() {
    const state = store.getState();
    const loginResponse = state.auth.loginResponse;
    return loginResponse.token;
  }
}
