import React from 'react'
import BreadCrumb from '../../components/common/BreadCrumb'
import CommonTable from '../../components/Infra/Table/table'
import { Button } from 'react-bootstrap';
import editIcon from '../../Images/editIcon.svg';
import deleteIcon from '../../Images/deleteIconBlue.svg';
import './Users.scss'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/Routes';
import CreateUpdateModal from './CreateUpdateModal';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from '../../api/axios';
import { API_URLS } from '../../constants/API_URLS';
import { ToastError, ToastSuccess } from '../../utils/utils';
import { toastIds } from '../../constants/constant';
import moment from 'moment';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import Loader from '../../components/Loader';

const Users = () => {
  const [modalShow, setModalShow] = useState(false);
  const [userActionType, setUserActionType] = useState('create');
  const [userList, setUserList] = useState([]);
  const [UpdatedData, setUpdatedData] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };

  const deleteUserApiCall = (email, name) => {
    axios.delete(`${API_URLS.USERS.deletUsers}?emailID=${email}`)
      .then((response) => {
        if (response?.data?.statusCode == 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.apiSuccess);
          setPage(0);
          getUserList(rowsPerPage, 0);
          Swal.fire({
            title: "Deleted!",
            text: `${name} has been deleted.`,
            icon: "success"
          });
        }
        else {
          ToastError(response?.data?.statusMessage, toastIds.apiSuccess);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.apiSuccess);
      })
  }

  const openDeleleModal = (email, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserApiCall(email, name);
      }
    });

  }

  const getUserList = (limit, offset) => {
    setLoading(true);
    axios.get(`${API_URLS.USERS.getUserList}${limit}/${offset}`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          let result = response?.data?.data;
          setTotalCount(response?.data?.totalCount)
          result = result.map((data) => {
            return {
              ...data,
              status: data?.active ? 'Active' : 'InActive',
              createdAt: moment(data?.createdAt).format('DD-MM-YYYY hh:mm:ss'),
              actions: <div className='d-flex gap-3 '>
                <img className='users_icons' src={deleteIcon} alt='deleteIcon' onClick={() => {
                  openDeleleModal(data?.empEmail, data?.empUsername);
                }} />
              </div>
            }
          })
          setUserList(result);
        }
        else {
          ToastError(response?.data?.statusMessage, toastIds.apiError)
        }
      })
      .catch(error => {
        ToastError(error?.response?.data?.statusMessage, toastIds.apiError)
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const createApiCall = (data) => {
    const postBody = {
      "empUsername": data?.userName,
      "empEmail": data?.email,
      "createdBy": "Keshav",
      "password": data?.password,
      "updatedBy": null,
      "isActive": true
    }
    axios.post(API_URLS.USERS.createUser, postBody)
      .then((response) => {
        if (response?.data?.statusCode === 201) {
          ToastSuccess(response?.data?.statusMessage, toastIds.apiSuccess);
          setPage(0);
          getUserList(rowsPerPage, 0);
        }

        else {
          ToastError(response?.data?.statusMessage, toastIds.apiError)
        }
      })
      .catch(error => {
        ToastError(error?.response?.data?.statusMessage, toastIds.apiError)
      })
  }


  useEffect(() => {
    const offset = page * rowsPerPage;
    getUserList(rowsPerPage, offset)
  }, [page, rowsPerPage])

  return (
    <div className='users'>
      <CreateUpdateModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        actionType={userActionType}
        getData={(data) => {
          createApiCall(data)
        }}
        updateData={UpdatedData}
      />
      <div className='mx-3 '>
        <div className='d-flex flex-wrap justify-content-between align-items-center'>
          <div>
            <p className='users_headText mb-0'>Users</p>
          </div>
          <div>
            <Button variant="primary" size='sm' onClick={() => { setModalShow(!modalShow); setUserActionType('create') }}>Create User</Button>
          </div>
        </div>
        <div className='mt-3'>
          <div>
            {
              loading ? <div className='loaderDiv'>
                <Loader />
              </div>
                :
                <>
                  <TableContainer component={Paper} className='users_tableContainer'>
                    <Table >
                      <TableHead>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>Username</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Created At</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userList.map((row) => (
                          <TableRow key={row.empEmail}>
                            <TableCell >{row.empEmail}</TableCell>
                            <TableCell>{row.empUsername}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.createdAt}</TableCell>
                            <TableCell>
                              <TableCell component="div" scope="row">
                                {row.actions}
                              </TableCell>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    labelRowsPerPage="Rows per page"
                  />
                </>
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default Users