import React from "react";
import "../../../../../style/Infra/serverDetails.scss";
export default function Details({data}) {
  const detailsList1 = [
    {
      key: 1,
      data: "Instance State",
      values: data?.instanceState,
    },
    {
      key: 2,
      data: "Name",
      values: data?.instanceName,
    },
    {
      key: 3,
      data: "Account Name",
      values: data?.accountName,
    },
    {
      key: 4,
      data: "Region Name",
      values: data?.regionName,
    },
    {
      key: 5,
      data: "Instance Type",
      values: data?.instanceType,
    },
    {
      key: 6,
      data: "Operating System",
      values: data?.operatingSystem,
    },
    {
      key: 7,
      data: "Availability Zone",
      values: data?.availabilityZone,
    },
    {
      key: 8,
      data: "Alarm Status",
      values: data?.alarmStatus,
    },
    {
      key: 9,
      data: "IPv4 Public IP",
      values: data?.ipv4PublicIp,
    },
    {
      key: 10,
      data: "Monitoring",
      values: data?.monitoring,
    },
    {
      key: 11,
      data: "VPC ID",
      values: data?.vpcId,
    },
    {
      key: 12,
      data: "Root Device Type",
      values: data?.rootDeviceType,
    },
    {
      key: 13,
      data: "Elastic IPs",
      values: data?.elasticIPs,
    },
    {
      key: 14,
      data: "Private DNS Name",
      values: data?.privateDNSName,
    },
    {
      key: 15,
      data: "Virtualization Type",
      values: data?.virulizationType,
    },
    {
      key: 16,
      data: "Lifecycle",
      values: data?.lifecycle,
    },
    {
      key: 17,
      data: "State Transition Reason",
      values: data?.stateTransitionReason,
    },
    {
      key: 18,
      data: "Root Device Name",
      values: data?.rootDeviceName,
    },
    {
      key: 19,
      data: "Tenancy",
      values: data?.tenancy,
    },
    {
      key: 20,
      data: "Affinity",
      values: data?.affinity,
    },
    {
      key: 21,
      data: "Reservation",
      values: data?.reservation,
    },
    {
      key: 22,
      data: "RAM Disk ID",
      values: data?.ramDiskId,
    },
  ];
  const detailsList2 = [
    {
      key: 1,
      data: "Instance Id",
      values: data?.instanceId,
    },
    {
      key: 2,
      data: "DAY2™ Managed",
      values: "-",
    },
    {
      key: 3,
      data: "Account Number",
      values: data?.accountNumber,
    },
    {
      key: 4,
      data: "Region Code",
      values: data?.regionCode,
    },
    {
      key: 5,
      data: "Server Group",
      values: '-',
    },
    {
      key: 6,
      data: "Platform",
      values: data?.platform,
    },
    {
      key: 7,
      data: "Status Checks",
      values: data?.statusChecks,
    },
    {
      key: 8,
      data: "Public DNS IPv4",
      values: data?.publicDNSIPV4,
    },

    {
      key: 9,
      data: "IPv6 IPS",
      values: "-",
    },
    {
      key: 10,
      data: "Launch Time",
      values: data?.lanuchTime,
    },
    {
      key: 11,
      data: "Subnet Id",
      values: data?.subnetId,
    },
    {
      key: 12,
      data: "IAM Instance Profile Name",
      values:data?.iaminstanceProfileName
    },
    {
      key: 13,
      data: "Image Id",
      values: data?.imageId,
    },
    {
      key: 14,
      data: "Private IP Address",
      values: data?.privateIpAddress,
    },
    {
      key: 15,
      data: "Architecture",
      values: data?.architecture,
    },
    {
      key: 16,
      data: "EBS-Optimized",
      values: data?.ebsOptimized,
    },

    {
      key: 17,
      data: "State Transition Message",
      values: data?.stateTransitionMessage,
    },
    {
      key: 18,
      data: "Placement Group",
      values: data?.placementGroup,
    },
    {
      key: 19,
      data: "Host ID",
      values: data?.hostId,
    },
    {
      key: 20,
      data: "AMI Launch Index",
      values: data?.amiLaunchIndex,
    },
    {
      key: 21,
      data: "Kernal ID",
      values: data?.kernalId,
    },
  ];
  return (
    <div className="container-fluid details">
      <div className="row">
        <div className="col-md-6">
          <table className="table table-bordered">
            <tbody>
              {detailsList1.map((dataObject) => (
                <tr key={dataObject.id}>
                  <th scope="row" className="w-25 row_style">
                    {dataObject.data} 
                  </th>
                  <td>{dataObject.values}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <table className="table table-bordered">
            <tbody>
              {detailsList2.map((dataObject) => (
                <tr key={dataObject.id}>
                  <th scope="row" className="w-25 row_style">
                    {dataObject.data}
                  </th>
                  <td>{dataObject.values}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
