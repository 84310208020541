import React, { useEffect, useState } from "react";

import SVG from "react-inlinesvg";
import { Button, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import axios from '../../api/axios';
import { ROUTE_PATH } from "../../constants/Routes";
import { API_URLS } from "../../constants/API_URLS";
import { toastIds } from "../../constants/constant";
import sidebarToggle from '../../Images/sidebarToggle.svg';
import { ToastError, ToastSuccess } from "../../utils/utils";

import '../../style/Components/Header/Header.scss';

const Header = ({ isSmallScreenSidebarOpen, toggleSmallScreenSidebar }) => {
  const [loading, setLoading] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  const onSyncWithCloud = () => {
    setLoading(true);
    axios
      .get(API_URLS.SyncWithCloud)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.apiSuccess);
          window.location.reload(false);
        } else {
          ToastError(response?.data?.statusMessage, toastIds.apiError);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.apiError);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Listen for route changes and update the header accordingly
    if (location.pathname.includes(ROUTE_PATH?.CXO?.dashboard)) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showHeader && (
        <div className="mainHeader mb-3 w-100 d-flex justify-content-between align-items-center px-3">
          <div>
            {!isSmallScreenSidebarOpen && (
              <button onClick={toggleSmallScreenSidebar} className="btn shadow-none">
                <SVG src={sidebarToggle} className="mainHeader_toggleIcon" />
              </button>
            )}
          </div>
          <div className="d-flex flex-row gap-2">
            <Button
              size="sm"
              variant="primary"
              disabled={loading}
              onClick={() => onSyncWithCloud()}
            >
              {loading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  &nbsp; Loading...
                </>
              ) : (
                "Sync with cloud"
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
