import React from "react";
import '../../../style/Components/Common/tableheader.scss';
function TableButton(props) {
  return (
    <>
      <div className="button-container">
        <button className="d-grid justify-content-center align-item-center p-2 manage-icon" onClick={props.onClick} disabled={props.disabled}>
          <div className=" d-flex iconWrapper justify-content-center align-item-center">
            {props?.icon}
          </div>

          <span className={props.className || "title"}>{props?.title}</span>
        </button>

      </div>
    </>
  );
}
export default TableButton;
