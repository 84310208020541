import React from "react";


import "../../../style/Infra/computecard.scss";

const ComputeCards = (props) => {
  return (
    <div className={props.className || "card manage-card shadow"}>
      <label className="title">{props?.title}</label>
      <div className="d-flex justify-content-center align-item-center">
        {props?.displaycomponents}
      </div>
    </div>
  );
};
export default ComputeCards;
