import React, { useEffect, useReducer, useState } from "react";

import { Button } from "react-bootstrap";

import { TiTick } from "react-icons/ti";
import { GrClose } from "react-icons/gr";
import { FaCaretLeft } from "react-icons/fa";
import { FaCaretRight } from "react-icons/fa";

import axios from "../../../../api/axios";
import awsIcon from "../../../../Images/awsIcon.svg";
import infoIcon from "../../../../Images/infoIcon.svg";
import refreshIcon from "../../../../Images/refresh.svg";
import filterIcon from "../../../../Images/filterFilled.svg";
import successDotIcon from "../../../../Images/successDot.svg";
import pendingDotIcon from "../../../../Images/pendingDot.svg";
import terminatedDotIcon from "../../../../Images/terminatedDot.svg";

import "../../../../style/Infra/createServer.scss";
import ViewInstanceModal from "./ViewInstanceModal/viewInstanceModal";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import CommonTable from "../../../../components/Infra/Table/table";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import { toastIds } from "../../../../constants/constant";
import { API_URLS } from "../../../../constants/API_URLS";

const initialState = {
  flag: true,
  tableData: [],
  isAdded: false,
  infoData: null,
  tableDataRaw: null,
  name: "",
  loading: false,
  description: "",
  selectedRows: [],
  openViewInstanceModal: false,
  currentPage: 1,
  filterCheckboxDisabled: false,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SLIDER":
      return { ...state, showSlider: !state.showSlider };
    case "TOGGLE_SLIDER_COLUMN":
      return { ...state, showSliderColumn: !state.showSliderColumn };
    case "TOGGLE_SLIDER_FILTER":
      return { ...state, showSliderFilter: !state.showSliderFilter };
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const CreateServer = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const columns = [
    {
      name: "Instance State",
      selector: (row) => row.instanceState,
       
    },
    {
      name: "Instance Id",
      selector: (row) => row.instanceId,
       
    },
    {
      name: "Name",
      selector: (row) => row.instanceName,
       
    },

    {
      name: "DAY2 Manage",
      selector: (row) => row.year,
       
    },

    {
      name: "Region Code",
      selector: (row) => row.regionCode,
       
    },
    {
      name: "Region Name",
      selector: (row) => row.regionName,
       
    },
    {
      name: "Platform",
      selector: (row) => row.platform,
       
    },

    {
      name: "Account Number",
      selector: (row) => row.accountNumber,
       
    },
    {
      name: "",
      selector: (row) => row.info,
    },
  ];
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const getTableData = () => {
    setData({ loading: true });
    let body = {
      tag: null,
      value: null,
    };
    axios
      .get(API_URLS.infra.compute.servers.getComputeServers, body)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          setData({ tableDataRaw: result?.data[0]?.server[0] });
          let tableData = result?.data[0]?.server[0].map((data) => {
            let newItem = {
              instanceState:
                data?.instanceState === "running" ? (
                  <p className="mb-0">
                    <img
                      src={successDotIcon}
                      className="createServer_dotIcon"
                      alt="successDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ) : data?.instanceState != "stopped" ? (
                  <p className="mb-0">
                    <img
                      src={pendingDotIcon}
                      className="createServer_dotIcon"
                      alt="pendingDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ) : (
                  <p className="mb-0">
                    <img
                      src={terminatedDotIcon}
                      className="createServer_dotIcon"
                      alt="terminatedDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ),
              instanceId: (
                <div className=" d-flex gap-2">
                  <img
                    src={awsIcon}
                    className="createServer_dotIcon"
                    alt="awsIcon"
                  />
                  <p className="mb-0 server-container_instanceId">
                    {data?.instanceId}
                  </p>
                </div>
              ),
              id: data?.instanceId,
              instanceName: data?.instanceName,
              operatingSystem: data?.operatingSystem,
              instanceType: data?.instanceType,
              accountNumber: data?.accountId,
              accountName: data?.accountName,
              regionCode: data?.regionCode,
              regionName: data?.regionName,
              platform: data?.platform,
              // servergroup,
              info: (
                <img
                  onClick={() => {
                    setData({
                      infoData: data,
                      openViewInstanceModal: !state.openViewInstanceModal,
                    });
                  }}
                  src={infoIcon}
                  className="createServer_dotIcon"
                  alt="awsIcon"
                />
              ),
            };
            return newItem;
          });
          setData({ tableData: tableData });
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const CloseModal = () => {
    const postData = {
      groupName: state.name,
      instanceWithAccountDTOList: state.selectedRowsTable?.selectedRows?.map(
        (data) => {
          return {
            instanceID: data?.id,
            accountID: data?.accountNumber,
          };
        }
      ),
      groupDescription: state.description,
    };
    axios
      .post(API_URLS.infra.compute.servers.saveServerGroup, postData)
      .then((response) => {
        if (response?.data?.statusCode == 201) {
          ToastSuccess(response?.data?.statusMessage, toastIds?.successToastId);
          props?.closeModal();
        } else {
          ToastError(response?.data?.statusMessage, toastIds?.errorToastId);
        }
      })
      .catch((error) => {
        ToastError(
          error?.response?.data?.statusMessage,
          toastIds?.errorToastId
        );
      });
  };

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableDataRaw.map((data, index) => {
        let newItem = {
          instanceState:
            data?.instanceState === "running" ? (
              <p className="mb-0">
                <img
                  src={successDotIcon}
                  className="createServer_dotIcon"
                  alt="successDotIcon"
                />
                {data?.instanceState}
              </p>
            ) : data?.instanceState != "stopped" ? (
              <p className="mb-0">
                <img
                  src={pendingDotIcon}
                  className="createServer_dotIcon"
                  alt="pendingDotIcon"
                />
                {data?.instanceState}
              </p>
            ) : (
              <p className="mb-0">
                <img
                  src={terminatedDotIcon}
                  className="createServer_dotIcon"
                  alt="terminatedDotIcon"
                />
                {data?.instanceState}
              </p>
            ),
          instanceId: (
            <div className=" d-flex gap-2">
              <img
                src={awsIcon}
                className="createServer_dotIcon"
                alt="awsIcon"
              />
              <p className="mb-0 server-container_instanceId">
                {data?.instanceId}
              </p>
            </div>
          ),
          id: data?.instanceId,
          instanceName: data?.instanceName,
          operatingSystem: data?.operatingSystem,
          instanceType: data?.instanceType,
          accountNumber: data?.accountNumber,
          accountName: data?.accountName,
          regionCode: data?.regionCode,
          regionName: data?.regionName,
          platform: data?.platform,
          // servergroup,
          info: (
            <img
              onClick={() => {
                setData({
                  infoData: data,
                  openViewInstanceModal: !state.openViewInstanceModal,
                });
              }}
              src={infoIcon}
              className="createServer_dotIcon"
              alt="awsIcon"
            />
          ),
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    instanceState: (
      <TableColumnSearch
        column={"instanceState"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    instanceId: (
      <TableColumnSearch
        column={"instanceId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    instanceName: (
      <TableColumnSearch
        column={"instanceName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    year: (
      <TableColumnSearch
        column={"year"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionCode: (
      <TableColumnSearch
        column={"regionCode"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionName: (
      <TableColumnSearch
        column={"regionName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    platform: (
      <TableColumnSearch
        column={"platform"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountNumber: (
      <TableColumnSearch
        column={"accountNumber"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };
  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className="createServer_modalContainer">
      <ViewInstanceModal
        show={state.openViewInstanceModal}
        serverDetails={state.infoData}
        onHide={() => setData({ openViewInstanceModal: false })}
      />
      <div className="createServer">
        <div className="">
          <nav className="nav">
            <div className="start-content">
              <label className="head_size">Create Server Groups</label>
            </div>
            <div className="end-content">
              {/* <Button onClick={props.closeModal}>Close</Button> */}
              <GrClose onClick={props.closeModal}></GrClose>
            </div>
          </nav>
        </div>

        <div className="container-fluid ">
          <div className="row">
            <div className="col-md-2 bg-light side_style">
              <Button
                className={`details btn ${state.flag ? "active" : ""}`}
                onClick={() => {
                  setData({ flag: true });
                }}
                variant="outline"
              >
                <span className={`span_style ${state.flag ? "active" : ""}`}>
                  1
                </span>{" "}
                Details
              </Button>
              <Button
                className={`selected btn ${state.flag ? "" : "active"}`}
                disabled={!state.name}
                onClick={() => {
                  setData({ flag: false });
                }}
                variant="outline"
              >
                <span className={`span_style ${state.flag ? "active" : ""}`}>
                  2
                </span>
                Select Instances
              </Button>
            </div>
            <div className="col-md-10 bg-light">
              {state.flag ? (
                <div className="row">
                  <div className="col-md-5">
                    <h5 className="head_style">Enter Name And Description</h5>

                    <div>
                      <label className="label_style">
                        Name<span className="createServer_requiredStar">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter the name"
                        onChange={(e) => setData({ name: e.target.value })}
                        value={state.name}
                      />
                    </div>

                    <div>
                      <label className="label_style">Description</label>
                      <textarea
                        className="form-control "
                        placeholder="Enter the Description"
                        onChange={(e) =>
                          setData({ description: e.target.value })
                        }
                        value={state.description}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-7 custom-column">
                    <div className="bottom-right-content">
                      <Button
                        className="btn_style"
                        disabled={state.name === ""}
                        onClick={() => {
                          setData({ flag: false });
                        }}
                      >
                        <FaCaretRight /> Next : Select Instance
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="custom-column">
                  <h5 className="head_style"> Select Instance</h5>
                  <div className="d-flex flex-wrap justify-content-between top-container ">
                    <div className="d-flex gap-3 flex-wrap"></div>
                    <div className="d-flex gap-3 flex-wrap ">
                      <TableButton
                        icon={
                          <img
                            onClick={() => OnFilterClick()}
                            className="images-container_tableIcons"
                            src={filterIcon}
                            alt="filterIcon"
                          ></img>
                        }
                        title={"Filter"}
                      />
                      <TableButton
                        icon={
                          <img
                            onClick={() => getTableData()}
                            className="images-container_tableIcons"
                            src={refreshIcon}
                            alt="refreshIcon"
                          ></img>
                        }
                        title={"Refresh"}
                      />
                    </div>
                  </div>
                  <div className=" createServer_secondTab">
                    <CommonTable
                      removeSearchCheckbox={state.filterCheckboxDisabled}
                      onChangePage={(data) => {
                        setData({ resetPagination: false, currentPage: data });
                      }}
                      resetPagination={state.resetPagination}
                      columns={columns}
                      onSelectedRowsChange={(data) =>
                        setData({ selectedRowsTable: data })
                      }
                      data={state.tableData}
                      selectableRows={true}
                      loading={state.loading}
                    />
                  </div>
                  <div className=" mt-3">
                    <Button
                      className="previous_btn"
                      onClick={() => {
                        setData({ flag: true });
                      }}
                    >
                      <FaCaretLeft />
                      Previous
                    </Button>
                    <Button className="create_btn" onClick={() => CloseModal()}>
                      <TiTick />
                      Create Server Group
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateServer;
