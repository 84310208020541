import React, { useEffect, useReducer } from "react";

import { Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Outlet, useNavigate } from "react-router-dom";

import { BiRefresh } from "react-icons/bi";

import axios from "../../../../api/axios";
import CreateServer from "./createServer";
import search from "../../../../Images/search.svg";
import { API_URLS } from "../../../../constants/API_URLS";
import ServerDeleteModal from "./ServerGroupId/serverDeleteModal";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import Modal from "../../../../components/common/Modal/InfraModal/Modal";
import ServerGroupCard from "../../../../components/common/serverGroupCard/serverGroupCard";
import { localStorageHeaders, serverGroupLimit, toastIds } from "../../../../constants/constant";

import "../../../../style/Infra/serverGroup.scss";

const initialState = {
  currentPage: 0,
  serverGroupDetails: [],
  serverCardDetails: [],
  serverCardDetailsTemp: [],
  pageCount: 0,
  instanceNameForDelete: "",
  isModalOpen: false,
  loading: false,
  openDeleteConfirmationModal: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function ServerGroups() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const openModal = () => {
    setData({ isModalOpen: true });
  };

  const closeModal = () => {
    setData({ isModalOpen: false });
    getServerList();
  };

  const navigate = useNavigate();
  const clickFunction = (cardId) => {
    const selectedGroupId = state?.serverGroupDetails?.filter(
      (data) => data?.group_id === cardId
    )[0];
    localStorage.setItem(
      localStorageHeaders?.selectedServerGroup,
      JSON.stringify(selectedGroupId)
    );
    navigate(`./${cardId}`);
  };
  const CREATE_SERVER = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    zIndex: 1000,
    width: "100%",
    height: "100%",
  };

  // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setData({ currentPage: selected });
  };

  const getServerList = () => {
    setData({ loading: true });
    axios
      .get(
        `${API_URLS.infra?.compute?.servers?.getServerGroups}${serverGroupLimit}/${serverGroupLimit * state.currentPage
        }`
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const result = response?.data?.data;
          setData({
            pageCount: Math.ceil(response?.data?.totalCount / serverGroupLimit),
            serverGroupDetails: result,
          });
          const cardDetails = result?.map((data) => {
            return {
              id: data?.group_id,
              title: data?.groupName,
              subdata: data?.description,
              instace: `${data?.instanceIDModelList?.length > 1
                  ? data?.instanceIDModelList?.length + " instances"
                  : data?.instanceIDModelList?.length + " instance"
                }`,
            };
          });
          if (cardDetails?.length > 0) {
            setData({
              serverCardDetails: cardDetails,
              serverCardDetailsTemp: cardDetails,
            });
          } else {
            setData({ serverCardDetails: [] });
          }
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const onDelete = () => {
    setData({ openDeleteConfirmationModal: false });
    axios
      .delete(
        `${API_URLS.infra.compute.servers.deleteServerGroup}groupName=${state.instanceNameForDelete}`
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          ToastSuccess(response.data.statusMessage, toastIds.successToastId);
          getServerList();
        } else {
          ToastError(response.data.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId);
      });
  };

  const onSearchResource = (data) => {
    let filteredCardDetails;
    if (data) {
      filteredCardDetails = state.serverCardDetails.filter((innerdata) =>
        innerdata.title.includes(data)
      );
    } else {
      filteredCardDetails = state.serverCardDetailsTemp;
    }
    setData({ serverCardDetails: filteredCardDetails });
  };

  useEffect(() => {
    getServerList();
  }, [state.currentPage]);

  useEffect(() => {
    localStorage.removeItem(localStorageHeaders.selectedServerGroup);
  }, []);

  return (
    <>
      <div className=" mx-4 server_group pt-3">
        <div className="container-fluid">
          <div className="pt-3">
            {/*sticky-top */}
            <div className="d-flex justify-content-between ">
              <h5 className=" text-start ms-1">Server Groups</h5>
              <Button className="createServer" onClick={openModal}>
                + Create Server
              </Button>
            </div>
          </div>
          <Modal
            open={state.isModalOpen}
            closeOnBackgroundClick={true}
            portalId="portal"
            styles={CREATE_SERVER}
          >
            <CreateServer closeModal={closeModal} />
          </Modal>
          <ServerDeleteModal
            instanceName={state.instanceNameForDelete}
            show={state.openDeleteConfirmationModal}
            onHide={() => setData({ openDeleteConfirmationModal: false })}
            deleteFunction={() => onDelete()}
          ></ServerDeleteModal>
          <div className="input-group pt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="search">
                <img className="tableIcons" src={search} alt="search"></img>
              </span>
            </div>
            <input
              type="text"
              className="form-control search_input"
              onChange={(e) => onSearchResource(e?.target?.value)}
              placeholder="search Resource"
            />
            <div className="input-group-append">
              <span className="input-group-text" id="refresh">
                <BiRefresh onClick={() => getServerList()} />
              </span>
            </div>
          </div>

          {state.loading ? (
            <p className="mt-2">
              <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
            </p>
          ) : (
            <div className="row pt-3">
              {state.serverCardDetails.length > 0 ? (
                state.serverCardDetails.map((item) => {
                   // Add this line to log the item
                  return (
                    <div className="col-md-3 mb-3" key={item.id}>
                      <ServerGroupCard
                        title={item.title}
                        subdata={item.subdata}
                        instace={item.instace}
                        deleteFunction={() => {
                          setData({
                            instanceNameForDelete: item.title,
                            openDeleteConfirmationModal: true,
                          });
                        }}
                        clickFunction={() => clickFunction(item.id)}
                      />
                    </div>
                  );
                })
              ) : (
                <p className="text-center mt-3">
                  No data to display. Try searching or refreshing.
                </p>
              )}
            </div>

          )}

          <div></div>

          <div className="d-flex justify-content-center pt-5">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={state.pageCount}
              pageRangeDisplayed={1}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageLinkClassName={"page-link"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              disabledClassName={"disabled"}
              nextClassName={"page-item"}
              previousClassName={"page-item"}
              pageClassName={"page-item"}
            />
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
