import React, { useEffect, useState } from "react";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";

import warningIcon from "../../../../../Images/warningCircle.svg";

import "../../../../../style/Infra/serverDeleteModal.scss";

const ServerDeleteModal = (props) => {
  const [instanceName, setInstanceName] = useState("");
  useEffect(() => {
    setInstanceName(props.instanceName);
  }, [props.instanceName]);

  return (
    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="grid-example">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <img src={warningIcon} className="w-25 " alt="warningIcon"></img>
          </div>
          <b className="text-center">Delete Server Group</b>
          <p className="text-center">
            Are you sure you want to delete the Server Group - {instanceName} ?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex gap-2 justify-content-center">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button onClick={props.deleteFunction}>Delete</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ServerDeleteModal;
