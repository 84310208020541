import React, { useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { BarController, BarElement, CategoryScale, Chart, LinearScale } from "chart.js";
import { graphColors } from "../../../constants/constant";
import "../../../style/Infra/Charts/bar.scss";
Chart.register(LinearScale, BarElement, BarController, CategoryScale);
Chart.defaults.borderColor = "black";
Chart.defaults.color = "black";
const HorizontalBarChart = (props) => {
  
  const data = {
    labels: props?.labels,
    datasets: [
      {
        label: "", 
        data: props?.data,
        backgroundColor: graphColors, 
        maxBarThickness: 25,
        
        
        
      },
    ],
  };
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, 
        },
        beginAtZero: true,
        grid: {
          borderColor: "blue",
          display: false, 
          lineWidth: 2,
        },
      },
      y: {
        ticks: {
          display: true, 
          font: {
            size: 10,
            color: "black", 
          },
        },
        beginAtZero: true,
        grid: {
          color: "blue",
          display: false, 
        },
      },
    },
    layout: {
      padding: {
        bottom: 50, 
      },
    },
  };
  return (
    <div>
      <div className="horizontal-bar-chart">
        <Bar data={data} options={options} width={290} height={260} />
      </div>
    </div>
  );
};
export default HorizontalBarChart;
