const DATE_FORMAT = {
  default: "yyyy-MM-dd",
  date_month: "dd MMM",
  DD_MM_YYYY: "dd/MM/yyyy",
  year_month: "YYYY-MM",
  month_year: "MMM YYYY",
  full_month_year: "MM YYYY",
  date_short_month: "dd MMM",
};

export default DATE_FORMAT;
