import React, { useEffect, useState } from "react";
import LineChart from "../../../../../components/common/Charts/Linechart/index";
import { Form } from "react-bootstrap";
import axios from "../../../../../api/axios";
import moment from "moment";
import { ToastError } from "../../../../../utils/utils";
import { API_URLS } from "../../../../../constants/API_URLS";
import { CommonConstants, commonConstants, monitoringDropdownOptions, toastIds } from "../../../../../constants/constant";
import Loader from "../../../../../components/Loader";

export default function Monitoring(props) {
  const [selectedValue, setSelectedValue] = useState(
    monitoringDropdownOptions[0].value
  ); // Set the initial value

  const [loading, setLoading] = useState(false);
  const [monitoringData, setMonitoringData] = useState([]);

  const getMonitoringData = (startDate, endDate) => {
    setLoading(true);

    axios
      .get(
        `${API_URLS.infra.compute.servers.serverMonitoring}startDate=${startDate}&endDate=${endDate}&instanceId=${props.instanceId}&accountId=${props?.accountId}`
      )
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          let temp = [];
          let tableData = Object.keys(result.data[0]).map((item) => {
            let label = item;
            let data = [];
            if (
              result?.data[0][item] != null &&
              result?.data[0][item][0]?.values?.length > 0
            ) {
              result?.data[0][item][0]?.values.map((res, index) => {
                data.push({
                  name: moment(
                    result?.data[0][item][0]?.timestamps[index]
                  ).format("HH:mm"),
                  value: JSON.parse(
                    result?.data[0][item][0]?.values[index].toFixed(2)
                  ),
                });
              });
            } else {
              data.push({ name: 0, value: 0 });
            }
            temp.push({ label: label, data: data });
          });
          setMonitoringData(temp);
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to calculate end date based on value (hours or weeks)
  const calculateEndDate = (startDate, value) => {
    if (value.endsWith("hrs")) {
      const hours = parseInt(value);
      return moment(startDate).utc().subtract(hours, "hours").subtract(1, "hours").format("DD-MM-YYYY HH:mm");
    } else if (value.endsWith("w")) {
      const weeks = parseInt(value);
      return moment(startDate).utc().subtract(weeks, "weeks").subtract(1, "hours").format("DD-MM-YYYY HH:mm");
    }
    return ""; // Return a default value if the format is not recognized
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedValue(selectedValue);

    const today = moment();

    const endDate = today.utc().format("DD-MM-YYYY HH:mm");
    const startDate = calculateEndDate(today, selectedValue);

    getMonitoringData(startDate, endDate);
  };

  useEffect(() => {
    const today = moment().utc().subtract(1, "hour").format("DD-MM-YYYY HH:mm");
    const endDate = moment().utc().format("DD-MM-YYYY HH:mm");
    getMonitoringData(today, endDate);
  }, [props]);

  return (
    <div className="container-fluid monitoring">
      <div className="row w-25">
        <Form.Select
          onChange={handleSelectChange}
          value={selectedValue}
          aria-label="Default select example"
        >
          {monitoringDropdownOptions.map((data) => (
            <option value={data.value}>{data.key}</option>
          ))}
        </Form.Select>
      </div>
      {loading ? <Loader/>:
      
      <div className="row pt-4">
        {monitoringData?.map((data) => (
          <div className="col-md-4 gridStyle">
            <label className="graph_label text-left">{data?.label}</label>
           <LineChart xAxisLegend={commonConstants?.time} currency="" small data={data?.data} />
          </div>
        ))}
      </div>
      }
    </div>
  );
}
