import React, { useState } from "react";
import warningIcon from "../../../../../Images/warningCircle.svg";

import { Badge, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { commonConstants } from "../../../../../constants/constant";

const ConvertToManagedModal = (props) => {
  const convertToManaged = () => {
    const serverDetails = props?.selectedData[0];
    const postBody = {
      accountId: serverDetails?.instanceData?.accountId,
      instanceId: serverDetails?.instanceData?.instanceId,
      operation: commonConstants.managed,
      iamRoleArn: serverDetails?.instanceData?.roleArn,
    };
    props.convertInstance(postBody);
    props?.onHide();
  };
  return (
    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Body className="grid-example">
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <img src={warningIcon} className="w-25 " alt="warningIcon"></img>
          </div>
          <b className="text-center">Unmanaged Instance</b>
          <p className="text-center">
            You will need to convert this instance to a managed instance in
            order to start a remote connection.
            {/* Are you sure you want to delete the Server Group - {instanceName} ? */}
          </p>
          <div className="text-center">
            <Badge bg="secondary">
              {props?.selectedData[0]?.instanceData?.instanceId}
            </Badge>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={() => convertToManaged()}>Convert TO Managed</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConvertToManagedModal;
