import { useEffect } from "react";
import React, { useState } from "react";

import moment from "moment/moment";

import axiosInstance from "../../../../../api/axios";
import refreshIcon from "../../../../../Images/refresh.svg";
import filterIcon from "../../../../../Images/filterFilled.svg";
import CommonTable from "../../../../../components/Infra/Table/table";

import "../../../../../style/Infra/images.scss";
import TableColumnSearch from "../../../../../components/common/TableSearchInput/tableSearch";
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import { dateConstants, toastIds } from "../../../../../constants/constant";
import { API_URLS } from "../../../../../constants/API_URLS";
import { ToastError } from "../../../../../utils/utils";

export default function RemoteSessions(props) {
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(null);
  const [isAdded, setIsAdded] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCheckboxDisabled, setFilterCheckboxDisabled] = useState(false);
  const columns = [
    {
      name: "Session Status",
      selector: (row) => row.status,
       
    },
    {
      name: "Session Id",
      selector: (row) => row.sessionId,
       
    },
    {
      name: "Started At",
      selector: (row) => row.startDate,
       
    },
    {
      name: "Ended At",
      selector: (row) => row.endDate,
       
    },
  ];
  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setTableData(mergedData);
    } else {
      const result = tableData;
      let mergedData = [SearchObject, ...result];
      setTableData(mergedData);
    }
  };

  const SearchObject = {
    status: (
      <TableColumnSearch
        column={"status"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    sessionId: (
      <TableColumnSearch
        column={"sessionId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    startDate: (
      <TableColumnSearch
        column={"startDate"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    endDate: (
      <TableColumnSearch
        column={"endDate"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const OnFilterClick = () => {
    if (currentPage == 1) {
      const onlyObjectsWithStrings = tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (isAdded) {
        setTableData(onlyObjectsWithStrings);
        setFilterCheckboxDisabled(false);
      } else {
        setTableData(() => {
          return [SearchObject, ...onlyObjectsWithStrings];
        });
        setFilterCheckboxDisabled(true);
      }
    } else {
      if (isAdded) {
        setFilterCheckboxDisabled(false);
      } else {
        setTableData((preData) => {
          return [SearchObject, ...preData];
        });
        setFilterCheckboxDisabled(true);
      }
    }
    setIsAdded((prevIsAdded) => !prevIsAdded);
    setResetPagination(true);
  };

  const getRemoteSessionData = () => {
    axiosInstance
      .get(
        `${API_URLS?.infra?.compute?.servers?.remoteSession}?accountID=${
          props?.accountId
        }&roleArn=${props?.roleArn}&limit=${limit || 10}&instanceID=${
          props?.instanceId
        }`
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          let result = response?.data?.data;
          result = result.map((data) => {
            return {
              sessionId: data?.sessionId,
              status: data?.status,
              startDate: moment(data?.startDate).format(
                dateConstants.fullDateWithTime
              ),
              endDate: moment(data?.endDate).format(
                dateConstants.fullDateWithTime
              ),
            };
          });
          setTableData(result);
        } else {
          ToastError(response?.data?.statusMessage, toastIds?.errorToastId);
        }
      })
      .catch((error) => {
        ToastError(
          error?.response?.data?.statusMessage,
          toastIds?.errorToastId
        );
      });
  };

  useEffect(() => {
    getRemoteSessionData();
  }, []);

  return (
    <div className="">
      <div className="images-container  ">
        <div className=" border rounded shadow-lg mt-3  mx-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap"></div>
            <div className="d-flex flex-wrap">
              <TableButton
                icon={
                  <img
                    className="tableButtonIcons"
                    src={filterIcon}
                    alt="editIcon"
                    onClick={() => {
                      OnFilterClick();
                    }}
                  ></img>
                }
                title={"Filter"}
              />
              <TableButton
                icon={
                  <img
                    className="tableButtonIcons"
                    src={refreshIcon}
                    alt="editIcon"
                    onClick={() => getRemoteSessionData()}
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          <div className="m-4 mt-0">
          <CommonTable
            removeSearchCheckbox={filterCheckboxDisabled}
            onChangePage={(data) => {
              setResetPagination(false);
              setCurrentPage(data);
            }}
            resetPagination={resetPagination}
            columns={columns}
            data={tableData || []}
            selectableRows={true}
          />
          </div>
          {/* <Pagination
            getSelectedLimit={(data) => {
              setLimit(data);
            }}
            onClickPrevBtn={() => onPreviousClick()}
            onClickNextBtn={() => getRemoteSessionData()}
            prevDisabled={isPrevButtonDisabled}
            nextDisabled={isNextBtnDisabled}
          /> */}
        </div>
      </div>
    </div>
  );
}
