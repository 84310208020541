import { createSlice } from "@reduxjs/toolkit";

const paginationSlice = createSlice({
  name: "pagination",
  initialState: 0,
  reducers: {
    increment: (state) => {
      return state + 1;
    },
    decrement: (state) => {
      return state - 1;
    },
    incrementByValue: (state, actions) => {
      return state + actions?.payload;
    }
  },
});

export default paginationSlice.reducer;
export const { increment, decrement, incrementByValue } = paginationSlice.actions;
