import { useReducer } from "react";
import React, { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { BiPowerOff } from "react-icons/bi";
import { FaPlay, FaStop } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";

import axios from "../../../../api/axios";
import ActivateOnPrem from "./activateOnPrem";
import ScanModal from "./ScanModal/scanModal";
import PatchModal from "./PatchModal/patchModal";
import awsIcon from "../../../../Images/awsIcon.svg";
import searchIcon from "../../../../Images/search.svg";
import chipIcon from "../../../../Images/chipIcon.svg";
import moreIcon from "../../../../Images/moreIcon.svg";
import refreshIcon from "../../../../Images/refresh.svg";
import { API_URLS } from "../../../../constants/API_URLS";
import CommonTooltip from "../../../../components/Tooltip";
import connectIcon from "../../../../Images/connectIcon.svg";
import successDotIcon from "../../../../Images/successDot.svg";
import pendingDotIcon from "../../../../Images/pendingDot.svg";
import Button from "../../../../components/Infra/Button/button";
import BarChart from "../../../../components/InfraCharts/Bar/bar";
import PieChart from "../../../../components/InfraCharts/Pie/pie";
import CreateImageModal from "./CreateImageModal/createImageModal";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import CommonTable from "../../../../components/Infra/Table/table";
import terminatedDotIcon from "../../../../Images/terminatedDot.svg";
import moreIconDisabled from "../../../../Images/moreIconDisabled.svg";
import Modal from "../../../../components/common/Modal/InfraModal/Modal";
import ConvertToManagedModal from "./ConvertToManaged/convertToManageModal";
import ResizeInstanceModal from "./ResizeInstanceModal/resizeInstanceModal";
import CreateSnapshotModal from "../../Storage/volumes/createSnapshotModal";
import DonutChart from "../../../../components/InfraCharts/ApexDoughnutChart";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import DoughnutChart from "../../../../components/InfraCharts/Doughnut/doughnut";
import ComputeCards from "../../../../components/InfraCards/compute/computecard";
import ManageTagModal from "../../../../components/Infra/ManageTagModal/manageTagModal";
import TagSearchModal from "../../../../components/Infra/TagSearchModal/tagSearchModal";
import {
  commonConstants,
  localStorageHeaders,
  operatingSystemColor,
  Piedata,
  toastIds,
} from "../../../../constants/constant";

import "../../../../style/Infra/server.scss";
const initialState = {
  tableData: [],
  isAdded: false,
  isdisabled: false,
  isModalOpen: false,
  showMoreOptions: false,
  open: false,
  serverData: null,
  loading: false,
  tagValue: [],
  selectedRow: [],
  openConvertToManageModal: false,
  openCreateImageModal: false,
  openCreateSnapshotModal: false,
  openManageTagsModal: false,
  openScanModal: false,
  openPatchModal: false,
  imageModalData: null,
  openResizeInstanceModal: false,
  resizeModalData: null,
  hideGraph: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function Servers() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const navigate = useNavigate();
  const columns = [
    {
      name: "Instance State",
      selector: (row) => row.instanceState,
    },
    {
      name: "Instance Id",
      selector: (row) => CommonTooltip(row.instanceId),
    },
    {
      name: "Name",
      selector: (row) => row.instanceName,
    },
    {
      name: "DAY2 Managed",
      selector: (row) => row.managed,
    },
    {
      name: "Oprating System",
      selector: (row) => row.operatingSystem,
    },
    {
      name: "Instance Type",
      selector: (row) => row.instanceType,
    },
    {
      name: "Account Number",
      selector: (row) => row.accountId,
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
    },
  ];

  const handleRowClick = (rowData) => {
    localStorage.setItem(
      localStorageHeaders?.serverDetails,
      JSON.stringify(rowData)
    );
    navigate(`/compute/servers/${rowData.instanceId}`);
  };

  const openModal = () => {
    setData({ isModalOpen: true });
  };

  const closeModal = () => {
    setData({ isModalOpen: false });
  };
  const ACTIVATE_ON_PREMISE = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    zIndex: 1000,
    width: "80%",
    height: "auto",
  };

  const getServerData = (searchValue) => {
    let url = API_URLS.infra.compute.servers.getComputeServers;
    if (searchValue) {
      url += `?name=${searchValue}`;
    }
    setData({ loading: true });
    let body = {
      value: state.tagValue ? state.tagValue : null,
    };

    axios
      .get(url, body)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          setData({ serverData: result?.data[0] });
          const serverDetails = result?.data[0]?.server[0];
          let tableData = serverDetails.map((data) => {
            let newItem = {
              instanceState:
                data?.instanceState === "running" ? (
                  <p className="mb-0">
                    <img
                      src={successDotIcon}
                      className="server-container_dotIcon"
                      alt="successDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ) : data?.instanceState != "stopped" ? (
                  <p className="mb-0">
                    <img
                      src={pendingDotIcon}
                      className="server-container_dotIcon"
                      alt="pendingDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ) : (
                  <p className="mb-0">
                    <img
                      src={terminatedDotIcon}
                      className="server-container_dotIcon"
                      alt="terminatedDotIcon"
                    />
                    {data?.instanceState}
                  </p>
                ),
              instanceId: (
                <div
                  className=" d-flex gap-2"
                  onClick={() => handleRowClick(data)}
                >
                  <img
                    src={awsIcon}
                    className="server-container_dotIcon"
                    alt="awsIcon"
                  />
                  <p className="mb-0 server-container_instanceId">
                    {data?.instanceId}
                  </p>{" "}
                </div>
              ),
              instanceName: data?.instanceName,
              operatingSystem: data?.operatingSystem,
              instanceType: data?.instanceType,
              accountNumber: data?.accountNumber,
              accountName: data?.accountName,
              accountId: data?.accountId,
              managed: data?.managed,
              instanceData: data,
            };
            return newItem;
          });
          serverDetails.length == 0
            ? setData({ hideGraph: true })
            : setData({ hideGraph: false });
          setData({ tableData: tableData });
        } else {
          setData({ hideGraph: true, tableData: [] });
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        setData({ hideGraph: true, tableData: [] });
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const serverOperation = (value) => {
    setData({ loading: true });
    axios
      .post(API_URLS.infra.compute.servers.serverOperation, value)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          ToastSuccess(result?.statusMessage, toastIds.errorToastId);
          getServerData();
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const scanPatchOperation = (value) => {
    setData({ loading: true });
    axios
      .post(API_URLS.infra.compute.servers.PatchOperation, value)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          ToastSuccess(result?.statusMessage, toastIds.errorToastId);
          getServerData();
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const convertTOManagedOperation = (value) => {
    setData({ loading: true });
    axios
      .post(API_URLS.infra.compute.servers.convertToManaged, value)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          ToastSuccess(result?.statusMessage, toastIds.errorToastId);
          getServerData();
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };
  useEffect(() => {
    // Hiding First row checkbox when the filter is active
    const element = document.getElementsByName("select-row-undefined");
    element.forEach((element) => {
      element.disabled = !element.disabled;
      setData({ isDisabled: !element.disabled });
    });
  }, [state.isAdded]);

  useEffect(() => {
    getServerData();
  }, [state.tagValue]);

  //sweet alert for start button
  const startService = () => {
    Swal.fire({
      title: "Start EC2 Instances",
      html: '<p style="font-size:1rem;">This operation is going to run on all the following instances:</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#547FFF",
      cancelButtonColor: "red",
      confirmButtonText: "Start",
      customClass: "sweet-warning",
    }).then((result) => {
      if (result.isConfirmed) {
        let opr = [];
        opr.push({
          operation: "start",
          accountId: state?.selectedRow[0]?.instanceData?.accountId,
          instanceId: state?.selectedRow[0]?.instanceData?.instanceId,
        });
        serverOperation(opr);
      }
    });
  };
  //sweet alert for terminate service
  const terminateService = () => {
    Swal.fire({
      title: "Terminate EC2 Instances",
      html: '<p style="font-size:1rem;">This operation is going to run on all the following instances:</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0390fc",
      cancelButtonColor: "red",
      confirmButtonText: "Terminate",
    }).then((result) => {
      if (result.isConfirmed) {
        const terminateOperations = state.selectedRow.map(
          (selectedInstance) => ({
            operation: "terminate",
            accountId: selectedInstance?.instanceData?.accountId,
            instanceId: selectedInstance?.instanceData?.instanceId,
          })
        );
        serverOperation(terminateOperations);
      }
    });
  };
  //sweet alert for stop service
  const stopService = () => {
    Swal.fire({
      title: "Stop EC2 Instances",
      html: '<p style="font-size:1rem;">This operation is going to run on all the following instances:</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0390fc",
      cancelButtonColor: "red",
      confirmButtonText: "Stop",
    }).then((result) => {
      if (result.isConfirmed) {
        let opr = [];
        opr.push({
          operation: "stop",
          accountId: state?.selectedRow[0]?.instanceData?.accountId,
          instanceId: state?.selectedRow[0]?.instanceData?.instanceId,
        });
        serverOperation(opr);
      }
    });
  };
  // sweet alert for connect service
  const restartService = () => {
    Swal.fire({
      title: "Restart EC2 Instances",
      html: '<p style="font-size:1rem;">This operation is going to run on all the following instances:</p>',
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0390fc",
      cancelButtonColor: "red",
      confirmButtonText: "Restart",
    }).then((result) => {
      if (result.isConfirmed) {
        let opr = [];
        opr.push({
          operation: "restart",
          accountId: state?.selectedRow[0]?.instanceData?.accountId,
          instanceId: state?.selectedRow[0]?.instanceData?.instanceId,
        });
        serverOperation(opr);
      }
    });
  };
  return (
    <>
      <TagSearchModal
        show={state.open}
        title={"Search By Servers"}
        type={"Name"}
        onHide={() => setData({ open: false })}
        getKeyValPairs={(data) => {
          getServerData(data);
        }}
      />
      <ConvertToManagedModal
        show={state.openConvertToManageModal}
        onHide={() => setData({ openConvertToManageModal: false })}
        selectedData={state.selectedRow}
        convertInstance={(data) => convertTOManagedOperation(data)}
      />
      <ScanModal
        show={state.openScanModal}
        onHide={() => setData({ openScanModal: false })}
        selectedScanData={state.selectedRow}
        getScanBodyServer={(value) => {
          scanPatchOperation(value);
        }}
      />
      <CreateImageModal
        show={state.openCreateImageModal}
        onHide={() => setData({ openCreateImageModal: false })}
        selectedRow={state.selectedRow}
        getImageBodyServer={(value) => {
          serverOperation(value);
          setData({ openCreateImageModal: false });
        }}
      />

      <ResizeInstanceModal
        show={state.openResizeInstanceModal}
        onHide={() => setData({ openResizeInstanceModal: false })}
        selectedResizeData={state.selectedRow}
        getResizeBodyServer={(value) => {
          serverOperation(value);
        }}
        getValues={(data) => {
          setData({ resizeModalData: data });
        }}
      />
      <PatchModal
        show={state.openPatchModal}
        onHide={() => setData({ openPatchModal: false })}
        selectedPatchData={state.selectedRow}
        getPatchBodyServer={(value) => {
          scanPatchOperation(value);
        }}
      />
      <CreateSnapshotModal
        show={state.openCreateSnapshotModal}
        onHide={() => setData({ openCreateSnapshotModal: false })}
        selectedVolumeDetails={state.selectedRow}
        getCreateBody={(value) => {
          serverOperation(value);
          setData({ OpenCreateSnapshotModal: false });
        }}
      />
      <ManageTagModal
        show={state.openManageTagsModal}
        onHide={() => setData({ openManageTagsModal: false })}
        selectedVolumeDetails={state.selectedRow}
        getmanageBodyServer={(value) => {
          serverOperation(value);
        }}
      />

      <div className="server-container mt-3">
        <h5 className=" text-start ms-4 d-flex align-items-center">
          <img src={chipIcon} className="mx-1" alt="chipIcon"></img>
          Servers
        </h5>

        <div className="card-container ">
          {state.serverData && Object.keys(state.serverData)?.length > 0 ? (
            <ComputeCards
              className="oprating-system card"
              title="Server Count"
              displaycomponents={
                state.hideGraph ? (
                  <div className="Server_Graph_hide">
                    <p>No Data Found</p>{" "}
                  </div>
                ) : (
                  <DonutChart
                    isServer
                    data={[
                      state.serverData?.runningCount,
                      state.serverData?.stoppedCount,
                      state.serverData?.pendingCount,
                    ]}
                    labels={["Running", "Stopped", "Pending"]}
                  />
                )
              }
            />
          ) : (
            <p>
              <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
            </p>
          )}
          {state.serverData && Object.keys(state.serverData)?.length > 0 ? (
            <ComputeCards
              title="Operating System "
              className="oprating-system card"
              displaycomponents={
                state.hideGraph && state.serverData?.operatingSystemCounts ? (
                  <div className="Server_Graph_hide">
                    {" "}
                    <p>No Data Found</p>
                  </div>
                ) : (
                  <BarChart
                    server
                    data={{
                      labels: Object.keys(
                        state.serverData?.operatingSystemCounts
                      ),
                      datasets: Object.entries(
                        state.serverData?.operatingSystemCounts
                      ).map((data, index) => {
                        let newItem = {
                          label: data[0], // Legend label for the first dataset
                          data: [data[1]],
                          backgroundColor: operatingSystemColor[index], // Bar fill color
                          borderWidth: 0.2,
                          barPercentage: 1, // Adjust the width of the bars
                          categoryPercentage: 1.2, // Adjust the spacing between bars
                        };
                        return newItem;
                      }),
                    }}
                  />
                )
              }
            />
          ) : (
            <p>
              <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
            </p>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-end  mt-4 mx-4 "></div>
        <Modal
          open={state.isModalOpen}
          closeOnBackgroundClick={true}
          portalId="portal"
          styles={ACTIVATE_ON_PREMISE}
        >
          <ActivateOnPrem closeModal={closeModal} />
        </Modal>
        <div className=" border rounded shadow border border-0 mt-3  mx-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              <TableButton
                icon={
                  <FaPlay
                    className="manage-icon"
                    style={{
                      color:
                        state.selectedRow?.length == 1 &&
                        state.selectedRow[0]?.instanceData?.instanceState ==
                          "stopped" &&
                        !state.hideGraph
                          ? "black"
                          : "lightgrey",
                      fontSize: "18px", // Add this line to set the font size to 18px
                    }}
                  ></FaPlay>
                }
                title={"Start"}
                disabled={
                  state.selectedRow?.length == 1 &&
                  state.selectedRow[0]?.instanceData?.instanceState ==
                    "stopped" &&
                  !state.hideGraph
                    ? false
                    : true
                }
                onClick={() => startService()}
              />
              <TableButton
                icon={
                  <FaStop
                    className="manage-icon"
                    style={{
                      color:
                        state.selectedRow?.length == 1 &&
                        state.selectedRow[0]?.instanceData?.instanceState ==
                          "running" &&
                        !state.hideGraph
                          ? "black"
                          : "lightgrey",
                      fontSize: "18px", // Add this line to set the font size to 18px
                    }}
                  ></FaStop>
                }
                title={"Stop"}
                disabled={
                  state.selectedRow?.length == 1 &&
                  state.selectedRow[0]?.instanceData?.instanceState ==
                    "running" &&
                  !state.hideGraph
                    ? false
                    : true
                }
                onClick={() => {
                  stopService();
                }}
              />
              <TableButton
                icon={
                  <VscDebugRestart
                    className="server-container_tableIcons"
                    style={{
                      color:
                        state.selectedRow?.length == 1 &&
                        state.selectedRow[0]?.instanceData?.instanceState ==
                          "running" &&
                        !state.hideGraph
                          ? "black"
                          : "lightgrey",
                      fontSize: "18px", // Add this line to set the font size to 18px
                    }}
                  ></VscDebugRestart>
                }
                title={"Restart"}
                disabled={
                  state.selectedRow?.length == 1 &&
                  state.selectedRow[0]?.instanceData?.instanceState ==
                    "running" &&
                  !state.hideGraph
                    ? false
                    : true
                }
                onClick={() => restartService()}
              />

              <TableButton
                icon={
                  <BiPowerOff
                    className="server-container_tableIcons"
                    style={{
                      color:
                        state.selectedRow?.length > 0 && !state.hideGraph
                          ? "black"
                          : "lightgrey",
                      fontSize: "18px", // Add this line to set the font size to 18px
                    }}
                    onClick={() => terminateService()}
                  ></BiPowerOff>
                }
                title={"Terminate"}
                disabled={
                  state.selectedRow?.length > 0 && !state.hideGraph
                    ? false
                    : true
                }
                onClick={() => terminateService()}
              />
              <TableButton
                icon={
                  <img
                    className="server-container_tableIcons"
                    src={connectIcon}
                    alt="connectIcon"
                  ></img>
                }
                title={"Connect"}
              />

              <div className="server-container_tableButtons">
                {state.showMoreOptions && !state.hideGraph && (
                  <div
                    className="server-container_listItems"
                    onMouseLeave={() => setData({ showMoreOptions: false })}
                  >
                    <ListGroup defaultActiveKey="#link1">
                      <ListGroup.Item
                        action
                        onClick={() =>
                          setData({
                            openConvertToManageModal:
                              !state.openConvertToManageModal,
                          })
                        }
                      >
                        Convert to DAY2 Managed Instance
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        onClick={() =>
                          setData({
                            openCreateSnapshotModal:
                              !state.openCreateSnapshotModal,
                          })
                        }
                      >
                        Snapshot Volumes
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        onClick={() =>
                          setData({
                            openManageTagsModal: !state.openManageTagsModal,
                          })
                        }
                      >
                        Manage Tags
                      </ListGroup.Item>
                      <ListGroup.Item
                        onClick={() =>
                          setData({
                            openCreateImageModal: !state.openCreateImageModal,
                          })
                        }
                        action
                      >
                        Create Image
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        disabled={state.selectedRow?.length > 1}
                        onClick={() =>
                          setData({
                            openResizeInstanceModal:
                              !state.openResizeInstanceModal,
                          })
                        }
                      >
                        Resize Instance
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        disabled={state.selectedRow?.length > 1}
                        onClick={() =>
                          setData({ openScanModal: !state.openScanModal })
                        }
                      >
                        Scan Instance
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        disabled={state.selectedRow?.length > 1}
                        onClick={() =>
                          setData({ openPatchModal: !state.openPatchModal })
                        }
                      >
                        Patch Instance
                      </ListGroup.Item>
                      <ListGroup.Item
                        onClick={() => {
                          const postBody = {
                            accountId:
                              state.selectedRow[0]?.instanceData?.accountId,
                            instanceId:
                              state.selectedRow[0]?.instanceData?.instanceId,
                            operation: commonConstants.deregister,
                            iamRoleArn:
                              state.selectedRow[0].instanceData?.roleArn,
                          };
                          convertTOManagedOperation(postBody);
                        }}
                        action
                      >
                        Deregister
                      </ListGroup.Item>
                    </ListGroup>
                  </div>
                )}
                {state.selectedRow?.length > 0 && !state.hideGraph ? (
                  <TableButton
                    icon={
                      <>
                        <img
                          className="server-container_tableIcons"
                          src={moreIcon}
                          alt="moreIcon"
                          onClick={() =>
                            setData({ showMoreOptions: !state.showMoreOptions })
                          }
                        ></img>
                      </>
                    }
                    title={"More"}
                  />
                ) : (
                  <TableButton
                    icon={
                      <>
                        <img
                          className="server-container_tableIcons"
                          src={moreIconDisabled}
                          alt="moreIconDisabled"
                        ></img>
                      </>
                    }
                    title={"More"}
                  />
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <TableButton
                icon={
                  <img
                    className="workspace-container_tableIcons"
                    src={searchIcon}
                    alt="searchIcon"
                    onClick={() => setData({ open: true })}
                  ></img>
                }
                title={"Search"}
              />
              <TableButton
                icon={
                  <img
                    className="workspace-container_tableIcons"
                    src={refreshIcon}
                    alt="shareIcon"
                    onClick={() => getServerData()}
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          <div className="m-4 mt-0">
            <CommonTable
              columns={columns}
              data={state.tableData}
              selectableRows={true}
              loading={state.loading}
              onSelectedRowsChange={(e) => {
                setData({ selectedRow: e.selectedRows });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
