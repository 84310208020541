import React,{ useState,useEffect } from "react";
import "../../../../style/Infra/deleteVolumeModal.scss";
import { Button, Modal, Table } from "react-bootstrap";
import { Switch } from "@mui/material";
import { commonConstants } from "../../../../constants/constant";

const DeleteVolumeModal = (props) => {
  const [selectedRowsTable, setSelectedRowsTable] = useState();
  const onClose = () => {
    props.onHide();
  };
  const onDelete = () => {
    let deleteBody = selectedRowsTable.map((data) => {
      return {
        volumeId: data?.volumeId,
        newName: null,
        operation: commonConstants?.delete,
        accountId: data?.accountId,
        createSnapshot: data?.createSnapShot ? data?.createSnapShot : null,
        addTags: null,
        volumeType: null,
        description: null,
      };
    });
    props?.onDelete(deleteBody);
    props?.onHide();
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleChangeSwitch = (value, index) => {
    setSelectedRowsTable((prevSelectedRowsTable) => {
      const updatedSelectedRowsTable = [...prevSelectedRowsTable];
      updatedSelectedRowsTable[index] = {
        ...updatedSelectedRowsTable[index],
        createSnapShot: value,
      };
      return updatedSelectedRowsTable;
    });
  };

  useEffect(() => {
    if (props.selectedRows) {
      const updatedSelectedRows = props.selectedRows.map((data) => ({
        ...data,
        createSnapShot: false,
      }));
      setSelectedRowsTable(updatedSelectedRows);
    }
  }, [props.selectedRows]); // Add props.selectedRows as a dependency

  return (
    <>
      <Modal
        size={"lg"}
        {...props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Volume
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Table responsive>
            <thead>
              <tr>
                <th>Volume ID</th>
                <th>Volume Name</th>
                <th>Volume Type</th>
                <th>Create Snapshot</th>
              </tr>
            </thead>
            <tbody>
              {props?.selectedRows?.map((data, index) => (
                <tr>
                  <td>{data?.volumeId}</td>
                  <td>{data?.volumeName}</td>
                  <td>{data?.volumeType}</td>
                  <td>
                    <div className="d-flex align-items-center gap-2 flex-wrap">
                      <p className="mb-0">No</p>
                      <Switch
                        {...label}
                        onChange={(e) =>
                          handleChangeSwitch(e?.target?.checked, index)
                        }
                      />
                      <p className="mb-0">Yes</p>
                    </div>
                  </td>
                </tr>
              ))} 
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2 flex-wrap">
            <Button variant="outline" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => onDelete()}>
              Delete Modal
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteVolumeModal;

