import React from "react";

import { Bar } from "react-chartjs-2";
import "../../../style/Infra/Charts/bar.scss";

const BarChart = ({ data,server }) => {
  // Sample data for the bar chart
  // const data = {
  //   labels: ["Unknown"],
  //   datasets: [
  //     {
  //       label: "Unknown",
  //       data: [null, 245],
  //       backgroundColor: ["#335CCB"],
  //       borderWidth: 0.2,
  //       barPercentage: 1,
  //       categoryPercentage: 1.2,
  //     },
  //   ],
  // };
  // Bar chart options
  const options = {
    responsive: true,
    // maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "right", // Display legend on the right side
        labels: {
          boxWidth: 10,
          boxHeight: 10,
        },
      },
    },
    indexAxis: "x", // Display bars horizontally (optional, remove this if you want vertical bars)
    scales: {
      x: {
        offset: true,
        borderWidth: 2,
        ticks: {
          display: false, // Hide Y-axis ticks
        },
        beginAtZero: true,

        grid: {
          borderColor: "red",

          display: false, // Remove x-axis grid lines (optional)
          lineWidth: 2,
        },
      },
      y: {
        ticks: {
          display: false, // Hide Y-axis ticks
        },

        beginAtZero: true,

        grid: {
          color: "blue",
          display: false, // Display y-axis grid lines (optional, set to false to remove)
          lineWidth: 2,
        },
      },
    },
  };

  return (
    <div>
      <div className={server?"server_bar_chart mt-5":"bar-chart mt-5"}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};
export default BarChart;
