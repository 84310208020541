import React, { useState } from "react";

import { Accordion, Button, Form, Modal, Tab, Tabs } from "react-bootstrap";

import { Alert } from "@mui/material";

import filterIcon from "../../../../../Images/filterFilled.svg";

import '../../../../../style/Infra/deleteModal.scss'
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import CommonTable from "../../../../../components/Infra/Table/table";
import { commonConstants } from "../../../../../constants/constant";
const DeleteModal = (props) => {
  const onDelete = () => {
    const postData = props?.selectedRows?.map((data) => {
      return {
        amiId: data?.imageId,
        newName: data?.imageName,
        operation: commonConstants.delete,
        accountId: data?.accountNumber,
        shareAccountId: null,
      };
    });
    props?.onDelete(postData);
    props?.onHide();
  };
  const columns = [
    {
      name: "Snapshot Id",
      selector: (row) => row.snapShotId,
       
    },
    {
      name: "Volume Size",
      selector: (row) => row.volumeSize,
       
    },
    {
      name: "Description",
      selector: (row) => row.description,
       
    },
    {
      name: "Status",
      selector: (row) => row.status,
       
    },
    {
      name: "Account Number",
      selector: (row) => row.accountNumber,
       
    },
    {
      name: "Account Name",
      selector: (row) => row.accountName,
       
    },
    {
      name: "Region Code",
      selector: (row) => row.regionCode,
       
    },
    {
      name: "Region Name",
      selector: (row) => row.regionName,
       
    },
    {
      name: "Project",
      selector: (row) => row.project,
       
    },
    {
      name: "Environment",
      selector: (row) => row.environment,
       
    },
    {
      name: "Start Time",
      selector: (row) => row.startTime,
       
    },
    {
      name: "Progress",
      selector: (row) => row.progress,
       
    },
    {
      name: "Owner",
      selector: (row) => row.owner,
       
    },
    {
      name: "Encrypted",
      selector: (row) => row.encrypted,
       
    },
    {
      name: "Volume ID",
      selector: (row) => row.volumeId,
       
    },
  ];

  const tableData = props?.selectedRows;

  return (
    <Modal
      {...props}
      size="xl"
      scrollable
      fullscreen={"xl-down"}
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Images and associated Snapshots
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <div>
          <Alert severity="info">
            Note: Click on the Image listed below to expand & view associated
            snapshots and delete them along with the Image.
          </Alert>
          {props?.selectedRows?.map((row, id) => (
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {row?.imageId}({row?.imageName || "-"})
                </Accordion.Header>
                <Accordion.Body>
                  <div className="container serverDetails">
                    <div className="d-flex justify-content-between pt-3">
                      {/* <div className="d-flex flex-wrap ">
                       <label className="server_style">Image Name</label>
                       <span className="server_name">
                         i-0ea09f0f32dea92f2_5ca45744-5bca-4e6e-b5f4-053d4afaddf4
                       </span>
                     </div> */}
                    </div>
                    <div className="d-flex flex-wrap justify-content-around shadow pt-4">
                      <div className="">
                        <label className="titles">Image Name</label>
                        <p className="subdata">{row?.imageName || "-"} </p>
                      </div>
                      <div className="">
                        <label className="titles">Image Id</label>
                        <p className="subdata">{row?.imageId || "-"}</p>
                      </div>
                      <div className="">
                        <label className="titles">Name</label>
                        <p className="subdata">{row?.name || "-"}</p>
                      </div>
                      <div className="">
                        <label className="titles">Owner</label>
                        <p className="subdata">{row?.owner}</p>
                      </div>
                      <div className="">
                        <label className="titles">Source Amount</label>
                        <p className="subdata">{"-"}</p>
                      </div>
                      <div className="">
                        <label className="titles">Upload Status</label>
                        <p className="subdata">{row?.status} </p>
                      </div>
                      <div className="">
                        <label className="titles">Visibility</label>
                        <p className="subdata">{row?.visibility} </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex justify-content-end">
                        <TableButton
                          icon={
                            <img
                              className="deleteModal_tableIcons"
                              src={filterIcon}
                              alt="shareIcon"
                              // onClick={() => setShareImgShow(!shareImgShow)}
                            ></img>
                          }
                          title={"Filter"}
                        />
                      </div>
                      <div>
                        <CommonTable
                          columns={columns}
                          data={[tableData[id]]}
                          selectableRows={true}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onDelete();
            // onSearch(KeyValPairs);
          }}
        >
          Delete Images and Snapshots
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
