import React from "react";

import awsIcon from "../../../Images/awsIcon.svg";
import azureIcon from "../../../Images/azureIcon.svg";

import "../../../style/Infra/Inventory/inventorycard.scss";

function AwsCard(props) {
  return (
    <div className="smallInventory">
      <div className="card manage-card ">
        <div className="d-flex justify-content-between px-3 w-100 awsCard align-items-center">
          <div>
            {props?.title === "AWS" && (
              <img src={awsIcon} alt="AWS_ICON" className="awsIcon " />
            )}
            {props?.title === "AZURE" && (
              <img src={azureIcon} alt="AWS_ICON" className="awsIcon " />
            )}
          </div>
          <div className="d-flex flex-column">
            <label className="data_style text-right">
              {props.third_data ? props.third_data : "0"}
            </label>
            <label className="subtitle ">Total Resources</label>
          </div>
        </div>
        {}
      </div>
    </div>
  );
}
export default AwsCard;
