import React from "react";
import Header from "../../header/Header";

const Main=()=>
{
    return (
        <Header/>
    )
}

export default Main;