import React from "react";
import "./Auth.scss";
import SVG from "react-inlinesvg";
import logo from '../../../Images/Group.svg'
import { toAbsoluteUrl } from "../../../utils/toAbsoluteUrl";


const AuthWrapper = ({ children }) => {
  return (
    <div className="Auth">
      <div className="Auth__container">
        <div className="banner-wrapper w-100 d-none d-md-flex">
          <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center banner">
            <img className="img w-75" src={logo} />
          </div>
        </div>
        <div className="w-100 h-100 d-flex flex-column bg-white justify-content-center align-items-center  px-5">
          <div className="d-flex flex-column justify-content-center w-75">
            <SVG
              src={toAbsoluteUrl("/assets/svg/atlas-log.svg")}
              alt="logo"
              className="mb-4 align-self-center svg-logo"
            />
            <div className="">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
