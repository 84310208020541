import React from 'react';

import CommonTooltip from '../../../Tooltip';

import './ServicesTable.scss';

const ServicesTable = ({ tableHead, tableData }) => {
    const renderHeaderCell = (header) => {
        if (header.subHeaders && header.subHeaders.length > 0) {
            return (
                <th key={header.text} colSpan={header.colSpan || 1}>
                    {header.text}
                    <div className='d-flex flex-row subHeaderContainer'>
                        {header.subHeaders.map((subHeader, index) => (
                            <span className='w-50' key={index}>{subHeader.text}</span>
                        ))}
                    </div>
                </th>
            );
        } else {
            return <th key={header.text} colSpan={header.colSpan || 1}>{header.text}</th>;
        }
    };

    const renderRowCell = (rowData, header) => {
        if (header.subHeaders && header.subHeaders.length > 0) {
            return header.subHeaders.map((subHeader, index) => (
                <td key={index}>{CommonTooltip(rowData[header?.dataKey][subHeader?.dataKey]||'-')}</td>
            ));
        } else {
            return <td key={header.text}>{CommonTooltip(rowData[header?.dataKey]||'-')}</td>;
        }
    };

    return (
        <div className='cxoTable'>
            <table>
                <thead>
                    <tr>
                        {tableHead.map((header) => renderHeaderCell(header))}
                    </tr>
                </thead>
                <tbody>
                    {tableData?.map((rowData, rowIndex) => (
                        <tr key={rowIndex}>
                            {tableHead.map((header) => renderRowCell(rowData, header))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ServicesTable;
