import React from "react";
import { useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";

import { Alert } from "@mui/material";

import "../../../../../style/Common.scss";
import { commonConstants, dummyInstanceSize } from "../../../../../constants/constant";


const ResizeInstanceModal = (props) => {
  const [instanceSelectedValue, setInstanceSelectedValue] = useState("");
console.log(props?.selectedResizeData,'<<props?.selectedResizeData');
  const onConfirm = () => {
    props.getValues({ instanceSelectedValue: instanceSelectedValue });
    props.onHide();
    const postResizeServer = props?.selectedResizeData.map(data => {
      return {
        operation: commonConstants.resize,
        accountId: data?.instanceData?.accountId,
        instanceId: data?.instanceData?.instanceId,
        changeInstanceType:instanceSelectedValue,
      }
    })
    props.getResizeBodyServer(postResizeServer);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Resize Instance - server ({props?.selectedResizeData?.instanceData?.instanceId}){" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Alert severity="warning">
            Resizing this server will force it to restart.
          </Alert>
          <div className="m-2">
            <p>Current Instance Size</p>
            <p>{props?.selectedResizeData?.instanceData?.instanceType}</p>
            <p>
              Change Instance Size to
              <span className="requiredFieldStar">*</span>
            </p>

            <Form.Select
              className="w-50"
              onChange={(e) => setInstanceSelectedValue(e?.target?.value)}
            >
              <option>Select...</option>
              {dummyInstanceSize.map((data) => (
                <option value={data?.value}>{data?.key}</option>
              ))}
            </Form.Select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResizeInstanceModal;
