import { useEffect } from "react";
import React, { useReducer, useState } from "react";

import { Button, Form, Modal } from "react-bootstrap";

import { FormControlLabel, Switch } from "@mui/material";

import axiosInstance from "../../../../../api/axios";
import "../../../../../style/Common.scss";
import { API_URLS } from "../../../../../constants/API_URLS";
import { commonConstants } from "../../../../../constants/constant";
const initialState = {
  selectedRegion: "",
  name: "",
  description: "",
  isEncrypted: false,
  regionList: [],
};
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const CopyModal = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedRow = props?.selectedRows ? props?.selectedRows[0] : {};

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const onCopy = () => {
    const postBody = [
      {
        amiId: selectedRow?.imageId,
        newName: state.name,
        operation: commonConstants?.copy,
        accountId: selectedRow?.accountNumber,
        shareAccountId: null,
        destinationRegion: state.selectedRegion,
        sourceRegion: selectedRow?.regionCode,
      },
    ];

    props?.onHide();
    props?.onCopy(postBody);
  };

  const getRegionList = () => {
    const postBody = [
      {
        amiId: null,
        newName: null,
        operation: commonConstants?.regions,
        accountId: selectedRow?.accountNumber,
        shareAccountId: null,
      },
    ];
    axiosInstance
      .post(API_URLS?.infra?.compute?.images?.imageOperation, postBody)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setData({ regionList: response?.data?.data[0] });
        } else {
          console.error(
            response?.data?.statusMessage,
            "<<<error from regionList"
          );
        }
      })
      .catch((error) =>
        console.error(
          error?.response?.data?.statusMessage,
          "<<<error from regionList"
        )
      );
  };

  useEffect(() => {
    if (selectedRow && props?.show) {
      getRegionList();
    }
  }, [selectedRow,props?.show]);

  // const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Modal
      {...props}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Copy Image - Unassigned (ami-0031880ee1405a5a3)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <div className="w-75">
          <p className="mb-1">
            Select the regions you would like this image to be copied to
            <span className="requiredFieldStar">*</span>
          </p>
          <Form.Select
            onChange={(e) => {
              setData({ selectedRegion: e?.target?.value });
            }}
            aria-label="Default select example"
          >
            <option>Region</option>
            {state.regionList?.map((region) => (
              <option value={region}>{region}</option>
            ))}
          </Form.Select>
          <p className="mb-1 mt-2">
            Name <span className="requiredFieldStar">*</span>
          </p>
          <input
            value={state.name}
            placeholder="Enter Name"
            onChange={(e) => setData({ name: e?.target?.value })}
            className="w-100"
          ></input>
          <p className="mb-1 mt-2">Description</p>
          <textarea
            value={state.description}
            placeholder="Enter Description"
            onChange={(e) => setData({ description: e?.target?.value })}
            className="w-100"
          ></textarea>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!state.selectedRegion || !state.name}
          onClick={() => {
            onCopy();
          }}
        >
          Copy Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CopyModal;
