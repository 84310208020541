import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "../../style/Infra/tagSearchModal.scss";
const ManageTags = (props) => {
  const [KeyValPairs, setKeyValPairs] = useState([{ key: "", value: "" }]);

  const handleChangeKey = (index, val) => {
    setKeyValPairs((prevKeyValPairs) => {
      const updatedKeyValPairs = prevKeyValPairs.map((pair, i) =>
        i === index ? { ...pair, key: val } : pair
      );
      return updatedKeyValPairs;
    });
  };

  const handleChangeValue = (index, val) => {
    setKeyValPairs((prevKeyValPairs) => {
      const updatedValues = prevKeyValPairs.map((data, i) =>
        index === i ? { ...data, value: val } : data
      );
      return updatedValues;
    });
  };

  const removeKeyValuePair = (index) => {
    setKeyValPairs((prevData) => {
      const removedKey = prevData.filter((data, ind) => {
        return ind !== index;
      });
      return removedKey;
    });
  };
  const onSearch = (data) => {
    props.onHide();
    const updatedData = data.filter(
      (data) => data?.key != "" && data?.value != ""
    );
    props.getKeyValPairs(updatedData);
  };
  return (
    <>
      <Modal
        {...props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Manage Tags
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <Container>
            <Row>
              <Col xs>
                <p className="mb-1">
                  For each resource, you can have a maximum of 50 tags and each
                  tag key must be unique with only one value. Tag keys and
                  values are case-sensitive.
                </p>
              </Col>
            </Row>
            <Row className="tagSearchModal_borderBottom">
              <Col xs sm={5} md={5}>
                <p className="mb-1">Key</p>
              </Col>
              <Col xs sm={5} md={5}>
                <p className="mb-1">Value</p>
              </Col>
            </Row>
            {KeyValPairs.map((data, ind) => (
              <Row className="mt-2">
                <Col xs md={5}>
                  <input
                    value={data?.key}
                    className="w-100"
                    onChange={(e) => {
                      handleChangeKey(ind, e.target.value);
                    }}
                  ></input>
                </Col>
                <Col xs md={5}>
                  <input
                    value={data?.value}
                    className="w-100"
                    onChange={(e) => {
                      handleChangeValue(ind, e.target.value);
                    }}
                  ></input>
                </Col>
                <Col xs md={2}>
                  <p
                    className="tagSearchModal_xIcon"
                    onClick={() => {
                      removeKeyValuePair(ind);
                    }}
                  >
                    x
                  </p>
                </Col>
              </Row>
            ))}
          </Container>
          <Button
            onClick={() => {
              setKeyValPairs((data) => {
                if (!Array.isArray(data)) {
                  data = [];
                }
                return [
                  ...data,
                  {
                    key: "",
                    value: "",
                  },
                ];
              });
            }}
            size="sm"
>
            + Add Tag
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onSearch(KeyValPairs);
            }}
          >
            Update Tags
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManageTags;
