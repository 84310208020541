import React, { useState, useEffect, useRef } from 'react';
import semicolon from '../../Images/semicolon.svg';
import coin from '../../Images/coins.svg';
import square from '../../Images/square.svg';
import '../../../src/style/Pages/CXO/dashboard.scss';
const ThirdLayer = () => {
  const [listWise, setListWise] = useState([]);
  const [securityHub, setSecurityHub] = useState([]);
  const [flag, setFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState([]);
  const [selectedSeverity, setSelectedSeverity] = useState('All');
  const [selectedId, setSelectedId] = useState('');
  const [isDivVisible, setIsDivVisible] = useState(false);
  const divRef = useRef();
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleIdChange = (event) => {
    setSelectedId(event.target.value);
  };

  const handleOutsideClick = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsDivVisible(false);
    }
  };

  const handleSubmit = () => {
    if (selectedId) {
    } else {
      window.alert('Please select an ID first');
    }
  };

  const filteredData = securityHub.filter((data) => {
    if (selectedSeverity === 'All') {
      return true;
    } else {
      return data.Severity.Label.toLowerCase() === selectedSeverity.toLowerCase();
    }
  });

  const severityColors = {
    Low: '#00cc99',
    Medium: '#ff9933',

  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 thirdLayer p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 bg-light p-4">
                <div className="row">
                  <div className="col-md-6 divider">
                    <div className="thirdLayerr">
                      <div className="spacingLayer">
                        <p className="height-font ">Cloud improves IT efficiency</p>
                        <div className='d-flex flex-column gap-5'>
                          <div>
                            <h1 className="slide-effect secondLayerServiceData">
                              23
                            </h1>
                            <div className="coin-top">
                              <div className="layer-adjus fs-15">
                                increase in TB managed per admin
                              </div>
                              <img src={coin} className="coin" alt="Image3" />
                            </div>
                          </div>
                          <div>
                            <h1 className="slide-effect secondLayerServiceData">
                              50
                            </h1>
                            <div className="square-top">
                              <div className="layer-adjus fs-15">
                                increase in VMs managed per admin
                              </div>
                              <img src={square} className="squareIcon" alt="Image 3" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="thirdLayerr">
                      <p className="height-font ">With larger gains for re-architected applications</p>
                      <div className='d-flex flex-column gap-5'>
                        <div>
                          <h1 className="slide-effect secondLayerServiceData">
                            45
                          </h1>
                          <div className="coin-top">
                            <div className="layer-adjus fs-15">
                              increase in TB managed per admin
                            </div>
                            <img src={coin} className="coin" alt="Image3" />
                          </div>
                        </div>
                        <div>
                          <h1 className="slide-effect secondLayerServiceData">
                            33
                          </h1>
                          <div className="square-top">
                            <div className="layer-adjus fs-15">
                              increase in VMs managed per admin
                            </div>
                            <img src={square} className="squareIcon" alt="Image 3" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 text-light p-6 costSavingContainerLeft">
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className="dropdown" id='report'>
                      {isDivVisible ?
                        <div className='container   new-success bg-light'>
                          <label htmlFor="cars" >Choose Id for Scanning:</label>
                          <select id="cars" value={selectedId} onChange={handleIdChange} >
                            <option value="" disabled>Select an option</option>
                            {listWise.map((e) => {
                              return <option key={e.bill_payer_account_id} value={e.bill_payer_account_id} >{e.bill_payer_account_id}</option>
                            })}
                          </select>
                          {selectedId && (
                            <div>
                              <button className='new-b' data-toggle="modal" data-target="#exampleModalCenter" onClick={handleSubmit}>
                                Submit
                              </button>
                            </div>
                          )}
                        </div>
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="text-start px-3 pt-2">
                  <h1>Cloud Landscape</h1>
                  <p className="thirdpart">
                    By reducing or eliminating tasks that are no <br /> longer needed, teams are freed to work on<br />
                    higher value activities.
                  </p>
                </div>
                <br />
                <div className='auditCodeContent d-flex gap-3 w-100 flex-wrap align-items-start'>
                  <img src={semicolon} className=' semiColonImageCloudLandscape' alt="Image 3" />
                  <div className="secondlayertext w-75 text-start">
                    <h5>Mindful optimization of our cloud infrastructure has resulted in a substantial 53% decrease in overall operational expenses. Leveraging intelligent optimization mechanisms, we are achieving significant cost savings, enabling us to channel more resources into innovation and fueling our growth initiatives.</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-light">
              <div className='second-font1234'>
                Accounts :  {selectedId}
              </div>
              <div className="dropdown">
                <label htmlFor="cars"></label>
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(!isModalOpen)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body1 bg-light mt-5">
              <div className='container w-100'>
                <div className='row  mt-3  bg-light'>
                  <div className="table-container m-auto">
                    <div className="filter-container newContainer d-flex justify-content-end" >
                      <label htmlFor="severityFilter">Filter by Severity:</label>
                      <select
                        id="severityFilter"
                        value={selectedSeverity}
                        onChange={(e) => setSelectedSeverity(e.target.value)}
                      >
                        <option value="All">All</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                      </select>
                    </div>
                    <div>
                      {flag ?
                        <table className="table table-dark">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Description</th>
                              <th>Severity</th>
                              <th>Recommendation</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              filteredData.map((data) => (
                                <tr key={data.Id}>
                                  <td>{data.Title}</td>
                                  <td>{data.Description}</td>
                                  <td style={{ color: severityColors[data.Severity.Label] }}>
                                    {data.Severity.Label}
                                  </td>
                                  <td>{data?.Remediation?.Recommendation?.Text}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="4">No data available</td>
                              </tr>
                            )}
                          </tbody>
                        </table> :
                        <div className="loader  load-data"></div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ThirdLayer;
