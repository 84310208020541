import React from "react";

import { Navigate } from "react-router-dom";

import { ROUTE_PATH } from "./constants/Routes";
import { LocalStorageManager } from "./utils/LocalStorageManager";
function PrivateRoute({ children }) {
  
  const token = LocalStorageManager?.getToken();
  if (!token) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={ROUTE_PATH.LOGIN} />;
  }

  // authorized so return child components
  return children;
}

export default PrivateRoute;
