export const ROUTE_PATH = {
  LOGIN: '/login',
  user: {
    userView: '/users',
    createUser: '/users/create-User',
    editUser: '/users/edit-User',
  },
  CXO: {
    dashboard: '/cxo-dashboard',
  },

  BILLING: {
    DASHBOARD: '/billing/dashboard',
    KPI_DASHBOARD: '/billing/kpi/dashboard',
    POSTURE: '/billing/posture',
    BUDGET: '/billing/budget',
    BUDGET_MANAGMENT: '/billing/budget/budgetManagment',
    COST_ANOMOLY: '/billing/costAnomoly',
    MARKUP_AND_DISCOUNTS: '/billing/markupAndDiscount',
    OPTIMIZE_USAGE: '/billing/optimizeUsage',
    OPTIMIZE_RATE: '/billing/optimizeRate',
    CURRENT_RESERVATION: '/billing/current_reservation',
    ANOMOLY_DASHBOARD: '/billing/anamoly/dashboard',
    COSTCONTAINER_DASHBOARD: '/billing/cost-container/dashboard',
    ALERTS_AND_INSIGHTS: '/billing/budget/alertsAndInsights',
    COST_TRENDS: '/billing/posture/cost_trends',
    OPTIMIZE_USAGE_MANAGMENT: '/billing/optimizeUsage/optimizeUsageManagment',
    OPTIMIZE_USAGE_SUMMARY: '/billing/optimizeUsage/optimizeUsageSummary',
    COST_ANOMOLY_DASHBOARD: '/billing/costanomolydashboard',
    OPTIMIZE_ACTION_SUMMARY: '/billing/optimizeAction/OptimizeActionSummary',
    OPTIMIZE_ACTION: '/billing/OptimizeAction',
    CREATE_BUDGET: '/billing/budget/createbudget',
    UPDATE_BUDGET: '/billing/budget/updatebudget',
    COST_OVERVIEW: {
      ACCOUNT_BILLING: 'billing/cost-overview/accountBilling',
      RESOURCE_BILLING: 'billing/cost-overview/resourceBilling',
      PROJECT_BILLING: 'billing/cost-overview/projectBilling',
      APPLICATION_BILLING: 'billing/cost-overview/applicationBilling',
    },
  },

  ITSM: {
    workFlowModule: '/itsm/workflowModule',
    workFlowModuleListing: '/itsm/workflowListing',
    ticketDetails: '/ticket-detail/:id',
    ROUTE_TICKET_DETAIL: '/ticket-detail/:id',
    TicketDetails: '/itsm/ticketDetails',
  },
  Infra: {
    inventory: '/inventory',
    compute: {
      Servers: '/compute/servers',
    },
  },
};
