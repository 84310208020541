import React, { useState } from "react";

import { Button, Col, Container, Modal, Row } from "react-bootstrap";

import "../../../style/Infra/tagSearchModal.scss";

const TagSearchModal = (props) => {
  const [KeyValPairs, setKeyValPairs] = useState([{ key: "", value: "" }]);

  const handleChangeKey = (index, val) => {
    setKeyValPairs((prevKeyValPairs) => {
      const updatedKeyValPairs = prevKeyValPairs.map((pair, i) =>
        i === index ? { ...pair, key: val } : pair
      );
      return updatedKeyValPairs;
    });
  };

  const handleChangeValue = (index, val) => {
    setKeyValPairs((prevKeyValPairs) => {
      const updatedValues = prevKeyValPairs.map((data, i) =>
        index === i ? { ...data, value: val } : data
      );
      return updatedValues;
    });
  };

  const removeKeyValuePair = (index) => {
    setKeyValPairs((prevData) => {
      const removedKey = prevData.filter((data, ind) => {
        return ind !== index;
      });
      return removedKey;
    });
  };

  const onSearch = (data) => {
    props.onHide();
    let updatedData;
    if (props?.type) {
      updatedData = data[0]?.value;
    } else {
      updatedData = data;
    }
    props.getKeyValPairs(updatedData);
    setKeyValPairs((prevData) =>
    prevData.map((pair) => ({
      ...pair,
      key:"",
      value: "",
    }))
  );
  };

  

  return (
    <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {`Search By ${props?.type??'Tag Values'}`}
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row className="tagSearchModal_borderBottom">
            <Col xs sm={5} md={5}>
              <p className="mb-1">Key</p>
            </Col>
            <Col xs sm={5} md={5}>
              <p className="mb-1">Value</p>
            </Col>
          </Row>
          {KeyValPairs.map((data, ind) => ( 
            <Row className="mt-2">
              <Col xs md={5}>
                {props?.type ? (
                  <input
                    className="w-100"
                    value={props?.type}
                    disabled
                      onChange={(e) => {
                      handleChangeKey(ind, props?.type);
                    }}
                  ></input>
                ) : (
                  <input
                    className="w-100"
                    value={data?.key}
                    onChange={(e) => {
                      handleChangeKey(ind, e.target.value);
                    }}
                  ></input>
                )}
              </Col>
              <Col xs md={5}>
                <input
                  className="w-100"
                  value={data?.value}
                  onChange={(e) => {
                    handleChangeValue(ind, e.target.value);
                  }}
                ></input>
              </Col>
              <Col xs md={2}>
                {!props?.type && (
                  <p
                    className="tagSearchModal_xIcon"
                    onClick={() => {
                      removeKeyValuePair(ind);
                    }}
                  >
                    x
                  </p>
                )}
              </Col>
            </Row>
          ))}
        </Container>
        {!props?.type && (
          <Button
            onClick={() => {
              setKeyValPairs((data) => {
                if (!Array.isArray(data)) {
                  data = [];
                }
                return [
                  ...data,
                  {
                    key: "",
                    value: "",
                  },
                ];
              });
            }}
            size="sm"
            className="mx-2"
          >
            + Add Tag
          </Button>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            onSearch(KeyValPairs);
          }}
        >
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TagSearchModal;
