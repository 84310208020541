import { filter } from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { FontFamilies } from "../../constants/constant";
const index = () => {
  return (
    <Helmet>
      {filter(FontFamilies, "path").map((fontFamily) => (
        <link href={fontFamily.path} rel="stylesheet" key={fontFamily.value} />
      ))}
    </Helmet>
  );
};

export default index;
