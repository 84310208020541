import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  sidebarOpen:false  
};
const openSidebar = createSlice({
  name: "openSidebar",
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebarOpen = action.payload;
    },
  },
});

export const { toggleSidebar } = openSidebar.actions;
export default openSidebar.reducer;

