import React from "react";

import { Spinner } from "react-bootstrap";

import WarningIcon from "../../../Images/Warning.svg";
import GovernanceIcon from "../../../Images/governance.svg";
import NotificationIcon from "../../../Images/notification.svg";

import "../../../style/Infra/Inventory/inventorycard.scss";

function SmallInventoryCard(props) {
  return (
    <div className="smallInventory mt-3">
      <div className="card manage-card  mb-2">
        <label className="title">
          <div className="d-flex flex-wrap align-items-center gap-2 ">
            <div>
              <img
                className="governanceIcon"
                src={GovernanceIcon}
                alt="governance"
              ></img>
            </div>
            <div>
              <p className="mb-0">{props.activeTitle}</p>
            </div>
          </div>
        </label>
        <div className="d-flex flex-wrap">
          <div className="directionGovernance justify-content-around w-100 d-flex gap-3 p-3 pb-0">
            <div className="d-flex gap-2">
              <div className="d-flex flex-column">
                {props.secon_data ? (
                  <div className="data_style d-flex gap-1 align-items-center">
                    <p className="mb-0">{props.secon_data}</p>
                    <img
                      src={NotificationIcon}
                      alt="NotificationIcon"
                      className="inventorycard_subIcons_notificationIcon pb-1"
                    ></img>
                  </div>
                ) : (
                  <Spinner animation="border" variant="dark" />
                )}
                <label>Tagged</label>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="d-flex flex-column">
                {props.third_data ? (
                  <div className="data_style  d-flex gap-1 align-items-center">
                    <p className="mb-0">{props.third_data}</p>
                    <img
                      src={WarningIcon}
                      alt="WarningIcon"
                      className="inventorycard_subIcons_notificationIcon pb-1"
                    ></img>
                  </div>
                ) : (
                  <Spinner animation="border" variant="dark" />
                )}
                <label>Untagged</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SmallInventoryCard;
