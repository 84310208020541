import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CommonTooltip = (data) => {

  const renderTooltip = (props, data) => (
    <Tooltip id="button-tooltip" {...props}>
      {data}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="bottom-start"
      delay={{ show: 250, hide: 400 }}
      overlay={(e) => renderTooltip(e, data)}
      
    >
      <p className='mb-0'>{data}</p>
    </OverlayTrigger>

  )
}

export default CommonTooltip