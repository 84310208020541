import React, { useState } from 'react';
import { BiRefresh } from 'react-icons/bi';

import search from '../../../Images/search.svg';
import filterIcon from '../../../Images/filterFilled.svg';

import '../../../style/Infra/inventorySearch.scss';
import { Actions, inventoryDropdownValues } from '../../../constants/constant';
import InventoryDropdown from '../../../components/InfraDropdown/Inventory/dropdown';
import CheckBoxDropdown from '../../../components/InfraDropdown/checkboxDropdown';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

function InventorySearch({
  type,
  getSelectedAccountIds,
  accountDetails,
  resourceDropdownOptions,
  getSelectedResources,
  viewBy,
  getSelectAllValue,
  regionDropdownOptions,
  getSelectedRegion,
  selectedValue,
  selectedServiceTypeValue,
  handleDropdownChangeViewAs,
  handleServiceTypeDropdownChangeViewAs,
  btnAppendFun,
}) {
  const [selectedValue1, setSelectedValue1] = useState('');
  const [selectedValue2, setSelectedValue2] = useState('');
  const [clear, setClear] = useState('');

  const [serchval, setSearchVal] = useState('');
  const actions = Actions;

  const handleRefreshClick = () => {
    setSelectedValue1('');
    setSelectedValue2('');
    setSearchVal('');
    getSelectedAccountIds([]);
    setClear(true);
  };
  const handleSelectChange1 = (event) => {
    setSelectedValue1(event.target.value);
  };

  const handleSelectChange2 = (event) => {
    setSelectedValue2(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const onSelectResourceType = (data) => {
    getSelectedResources(data);
  };
  const onSelectRegion = (data) => {
    getSelectedRegion(data);
  };

  const onSelectAccount = (data) => {
    getSelectedAccountIds(
      accountDetails
        .filter((innerData) => data.includes(innerData.account_Id))
        .map((data) => {
          return { accountID: data?.account_Id, arn: data?.role_Arn };
        })
    );
  };

  return (
    <div className="inventorySearch pt-3 pb-4">
      <div className="input-group w-100 d-flex justify-content-end">
        {type === 'Governance' && (
          <>
            <div className=" d-flex px-3">
              {selectedValue === inventoryDropdownValues.InActiveResources && (
                <div className="input-group-append me-2">
                  <Dropdown
                    as={ButtonGroup}
                    className="inventoryTab_viewByDropdown"
                  >
                    <Button
                      variant=""
                      className="inventoryTab_viewByDropdown_dropdownBtn"
                    >
                      {selectedServiceTypeValue}
                    </Button>
                    <Dropdown.Toggle
                      className="dropdown dropdownbordr inventoryTab_viewAllDropdown"
                      variant=""
                      split
                      id="dropdown-basic"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        CloudFront
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        IAM
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        EC2
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        Lambda
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        VPC
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        RDS
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        S3
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        CloudTrail
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        DynamoDb
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        SNS
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        SES
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        EKS
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        SQS
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        SSM
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={handleServiceTypeDropdownChangeViewAs}
                      >
                        WAF
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              <p className="inventoryTab_viewByTxt">View By</p>
              <div className="ps-3">
                <Dropdown
                  as={ButtonGroup}
                  className="inventoryTab_viewByDropdown"
                >
                  <Button
                    variant=""
                    className="inventoryTab_viewByDropdown_dropdownBtn"
                  >
                    {selectedValue}
                  </Button>
                  <Dropdown.Toggle
                    className="dropdown dropdownbordr inventoryTab_viewAllDropdown"
                    variant=""
                    split
                    id="dropdown-basic"
                  ></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleDropdownChangeViewAs}>
                      All Resources
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDropdownChangeViewAs}>
                      VPC
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDropdownChangeViewAs}>
                      Cloud Formation
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDropdownChangeViewAs}>
                      Tags
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleDropdownChangeViewAs}>
                      In-Active Resources
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="d-flex gap-1">
              <div className="input-group-append">
                <CheckBoxDropdown
                  Options={accountDetails.map((data, index) => {
                    return {
                      id: index + 1,
                      value: data?.account_Id,
                      label: data?.name,
                    };
                  })}
                  clear={clear}
                  getSelectedValue={(data) => {
                    setClear(false);
                    onSelectAccount && onSelectAccount(data);
                  }}
                  placeholder="Accounts"
                  identifier="dropdown1"
                />
              </div>
              <div className="input-group-append">
                <CheckBoxDropdown
                  Options={regionDropdownOptions}
                  placeholder="Region"
                  identifier="dropdown2"
                  clear={clear}
                  getSelectedValue={(data) => {
                    setClear(false);
                    onSelectRegion && onSelectRegion(data);
                  }}
                />
              </div>
              {viewBy === inventoryDropdownValues.allResources && (
                <div className="input-group-append">
                  <CheckBoxDropdown
                    Options={resourceDropdownOptions}
                    placeholder="Resource Type"
                    identifier="dropdown3"
                    clear={clear}
                    getSelectedValue={(data) => {
                      setClear(false);
                      onSelectResourceType && onSelectResourceType(data);
                    }}
                    className="special_checkbox"
                  />
                </div>
              )}
              {viewBy === inventoryDropdownValues.tags && (
                <div className="input-group-append">
                  <CheckBoxDropdown
                    Options={resourceDropdownOptions}
                    placeholder="Tags"
                    identifier="dropdown3"
                    getIsSelectAllValue={(e) => getSelectAllValue(e)}
                    clear={clear}
                    getSelectedValue={(data) =>
                      onSelectResourceType && onSelectResourceType(data)
                    }
                    className="special_checkbox"
                  />
                </div>
              )}
              <button
                className="inventorySearch_customBtn"
                onClick={btnAppendFun}
              >
                Custom
              </button>
            </div>
          </>
        )}
        {type === 'Resources' && (
          <>
            <div className="input-group-append">
              <InventoryDropdown
                key="Dropdown1"
                options={actions}
                onSelectChange={handleSelectChange1}
                clear={clear}
                selectedValue={selectedValue1}
                placeholder="Subscriptions"
              />
            </div>
            <div className="input-group-append">
              <InventoryDropdown
                options={actions}
                key="Dropdown2"
                onSelectChange={handleSelectChange2}
                selectedValue={selectedValue2}
                placeholder="Region"
              />
            </div>
            <div className="input-group-append">
              <InventoryDropdown
                key="Dropdown1"
                options={actions}
                onSelectChange={handleSelectChange1}
                selectedValue={selectedValue1}
                placeholder="Resource Type"
              />
            </div>
          </>
        )}
        <div className="mx-1 input-group-append">
          <span
            className="input-group-text text-two"
            id="Rrfresh"
            onClick={handleRefreshClick}
          >
            <BiRefresh className="refreshIcon" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default InventorySearch;
