import { toast } from "react-toastify";
import {
  Filetype,
  downloadApiMessage,
  itsmDynamicColor,
  toastIds,
} from "../constants/constant";
import moment from "moment/moment";
import DATE_FORMAT from "../constants/DATE_FORMAT";
import { useDispatch } from "react-redux";

export const customYAxisTickUtilFunction = (currency, value, x, y) => {
  const formatValue = (value) => {
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"]; // Extend as needed
      const magnitude = Math.floor(Math.log10(value) / 3);
      return `${value / 10 ** (magnitude * 3)}${suffixes[magnitude]}`;
    }
    return value;
  };

  return `${currency}${formatValue(value)}`;
};

export const useReduxDispatch = () => {
  return useDispatch();
};

export const graphDataManipulationForLengthData = (input) => {
  const processedData = input.map((obj) => {
    if (Object.keys(obj).length <= 10) {
      return obj; // Return the array as it is
    }
    const first10Keys = Object.keys(obj).slice(0, 12);
    const othersValue = Object.values(obj)
      .slice(12)
      .reduce((sum, value) => (isNaN(value) ? sum : sum + value), 0);
    const newObj = {
      name: obj.name,
      ...(obj[first10Keys[0]] ? { [first10Keys[0]]: obj[first10Keys[0]] } : {}),
      ...(obj[first10Keys[1]] ? { [first10Keys[1]]: obj[first10Keys[1]] } : {}),
      ...(obj[first10Keys[2]] ? { [first10Keys[2]]: obj[first10Keys[2]] } : {}),
      ...(obj[first10Keys[3]] ? { [first10Keys[3]]: obj[first10Keys[3]] } : {}),
      ...(obj[first10Keys[4]] ? { [first10Keys[4]]: obj[first10Keys[4]] } : {}),
      ...(obj[first10Keys[5]] ? { [first10Keys[5]]: obj[first10Keys[5]] } : {}),
      ...(obj[first10Keys[6]] ? { [first10Keys[6]]: obj[first10Keys[6]] } : {}),
      ...(obj[first10Keys[7]] ? { [first10Keys[7]]: obj[first10Keys[7]] } : {}),
      ...(obj[first10Keys[8]] ? { [first10Keys[8]]: obj[first10Keys[8]] } : {}),
      ...(obj[first10Keys[9]] ? { [first10Keys[9]]: obj[first10Keys[9]] } : {}),
      ...(obj[first10Keys[10]]
        ? { [first10Keys[10]]: obj[first10Keys[10]] }
        : {}),
      others: othersValue,
    };

    return newObj;
  });

  return processedData;
};

export const getMonthsBetweenDates = (startDate, endDate) => {
  const startMoment = moment(startDate, "DD-MM-YYYY");
  const endMoment = moment(endDate, "DD-MM-YYYY");

  const months = [];
  let currentMonth = startMoment.clone();

  while (
    currentMonth.isBefore(endMoment) ||
    currentMonth.isSame(endMoment, "month")
  ) {
    months.push(currentMonth.format("MMM YYYY"));
    currentMonth.add(1, "month");
  }

  return months;
};

export function getCurrentUrl(location) {
  return location.pathname;
}

export const checkIfPatchIsActive = (location, url) => {
  const current = getCurrentUrl(location);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};

export const copyToClipboard = (data) => {
  navigator.clipboard.writeText(data);
};

export const ToCamelCase = (inputString) => {
  return (
    inputString?.charAt(0).toUpperCase() + inputString?.slice(1).toLowerCase()
  );
};

export const convertTimestampInMonths = (val) => {
  const timestamp = val * 1000;
  const date = new Date(timestamp);
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();
  const formattedDate = `${month} ${year}`;
  return formattedDate;
};

export const ToastError = (message, id) => {
  toast.error(message, { toastId: id });
};

export const ToastSuccess = (message, id) => {
  toast.success(message, { toastId: id });
};

export const retrieveLocalStorageValues = (name) => {
  return JSON.parse(localStorage.getItem(name));
};

export const storeLocalStorageValues = (name, object) => {
  localStorage.setItem(name, JSON.stringify(object));
};

export const DownloadFile = (fileData, fileName) => {
  const binaryData = fileData;
  const blob = new Blob([binaryData], { type: Filetype?.excel });
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  var url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  ToastSuccess(downloadApiMessage.success, toastIds.downloadSuccess);
};
export const getOnlyTwoDigitsAfterDecimal = (val) => {
  return Math.floor(val * 100) / 100;
};

export const getMetricsSummaryData = {
  getS3Data : (data) =>{
    return [
      {
        id: 1,
        service: "S3 Storage Usage (GB/Mo)",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_storgae_usage)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_storgae_usage)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_storgae_usage)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_storgae_usage) +
            parseFloat(data[1]?.s3_storgae_usage) +
            parseFloat(data[2]?.s3_storgae_usage)
        )}k`,
      },
      {
        id: 2,
        service: "S3 Storage Usage Change",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_storage_usage_change)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_storage_usage_change)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_storage_usage_change)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_storage_usage_change) +
            parseFloat(data[1]?.s3_storage_usage_change) +
            parseFloat(data[2]?.s3_storage_usage_change)
        )}k`,
      },
      {
        id: 3,
        service: "S3 Unit Cost (All)",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_unit_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_unit_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_unit_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_unit_cost) +
            parseFloat(data[1]?.s3_unit_cost) +
            parseFloat(data[2]?.s3_unit_cost)
        )}k`,
      },
      {
        id: 4,
        service: "S3 Unit Cost (GB Stored)",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.S3_GB_Unit_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.S3_GB_Unit_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.S3_GB_Unit_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.S3_GB_Unit_cost) +
            parseFloat(data[1]?.S3_GB_Unit_cost) +
            parseFloat(data[2]?.S3_GB_Unit_cost)
        )}k`,
      },
      {
        id: 5,
        service: "S3 Standard Only Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_standard_only_covergae)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_standard_only_covergae)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_standard_only_covergae)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_standard_only_covergae) +
            parseFloat(data[1]?.s3_standard_only_covergae) +
            parseFloat(data[2]?.s3_standard_only_covergae)
        )}k`,
      },
      {
        id: 6,
        service: "S3 Standard Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.s3_standard_covergae
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.s3_standard_covergae
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.s3_standard_covergae
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_standard_covergae) +
            parseFloat(data[1]?.s3_standard_covergae) +
            parseFloat(data[2]?.s3_standard_covergae)
        )}k`,
      },
      {
        id: 7,
        service: "S3 SIA Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.['s3_standard-ia_storage_cost'])}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.['s3_standard-ia_storage_cost'])}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.['s3_standard-ia_storage_cost'])}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.['s3_standard-ia_storage_cost']) +
            parseFloat(data[1]?.['s3_standard-ia_storage_cost']) +
            parseFloat(data[2]?.['s3_standard-ia_storage_cost'])
        )}k`,
      },
      {
        id: 8,
        service: "S3 Intelligent Tiering Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.['s3_intelligent-tiering_storage_cost'])}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.['s3_intelligent-tiering_storage_cost'])}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.['s3_intelligent-tiering_storage_cost'])}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.['s3_intelligent-tiering_storage_cost']) +
            parseFloat(data[1]?.['s3_intelligent-tiering_storage_cost']) +
            parseFloat(data[2]?.['s3_intelligent-tiering_storage_cost'])
        )}k`,
      },
      {
        id: 9,
        service: "S3 Glacier Instant Retrieval Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_glacier_instant_retrieval_retrieval_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_glacier_instant_retrieval_retrieval_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_glacier_instant_retrieval_retrieval_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_glacier_instant_retrieval_retrieval_cost) +
            parseFloat(data[1]?.s3_glacier_instant_retrieval_retrieval_cost) +
            parseFloat(data[2]?.s3_glacier_instant_retrieval_retrieval_cost)
        )}k`,
      },
      {
        id: 10,
        service: "S3 Glacier Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_glacier_coverage)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_glacier_coverage)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_glacier_coverage)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_glacier_coverage) +
            parseFloat(data[1]?.s3_glacier_coverage) +
            parseFloat(data[2]?.s3_glacier_coverage)
        )}k`,
      },
      {
        id: 11,
        service: "S3 Glacier Deep Archive Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.s3_glacier_deep_archive_storage_storage_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.s3_glacier_deep_archive_storage_storage_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.s3_glacier_deep_archive_storage_storage_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.s3_glacier_deep_archive_storage_storage_cost) +
            parseFloat(data[1]?.s3_glacier_deep_archive_storage_storage_cost) +
            parseFloat(data[2]?.s3_glacier_deep_archive_storage_storage_cost)
        )}k`,
      },
    ]
  },
  getSnapshotsData:(result)=>{
    return [
       {
         id: 1,
         service: "Snapshot Cost Change",
         c1: `${getOnlyTwoDigitsAfterDecimal(result[0]?.ebs_snapshot_cost)}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(result[1]?.ebs_snapshot_cost)}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(result[2]?.ebs_snapshot_cost)}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.ebs_snapshot_cost) +
             parseFloat(result[1]?.ebs_snapshot_cost) +
             parseFloat(result[2]?.ebs_snapshot_cost)
         )}k`,
       },
       {
         id: 2,
         service: "% Snapshots over 1yr Coverage",
         c1: `${getOnlyTwoDigitsAfterDecimal(
           result[0]?.ebs_snapshots_over_1yr_cost
         )}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(
           result[1]?.ebs_snapshots_over_1yr_cost
         )}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(
           result[2]?.ebs_snapshots_over_1yr_cost
         )}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.ebs_snapshots_over_1yr_cost) +
             parseFloat(result[1]?.ebs_snapshots_over_1yr_cost) +
             parseFloat(result[2]?.ebs_snapshots_over_1yr_cost)
         )}k`,
       },
       {
         id: 3,
         service: "% Snapshot Archive Coverage",
         c1: `${getOnlyTwoDigitsAfterDecimal(
           result[0]?.ebs_snapshots_under_1yr_cost
         )}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(
           result[1]?.ebs_snapshots_under_1yr_cost
         )}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(
           result[2]?.ebs_snapshots_under_1yr_cost
         )}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.ebs_snapshots_under_1yr_cost) +
             parseFloat(result[1]?.ebs_snapshots_under_1yr_cost) +
             parseFloat(result[2]?.ebs_snapshots_under_1yr_cost)
         )}k`,
       },
     ]
   },
  getEbsData: (data) => {
    return [
      {
        id: 1,
        service: "EBS Storage Usage Change (GB/Mo)",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.usage_storage_gb_mo
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.usage_storage_gb_mo
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.usage_storage_gb_mo
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.usage_storage_gb_mo) +
            parseFloat(data[1]?.usage_storage_gb_mo) +
            parseFloat(data[2]?.usage_storage_gb_mo)
        )}k`,
      },
      //for further use
      // {
      //   id: 2,
      //   service: "Unit Cost (All)",
      //   c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_usage_cost)}k`,
      //   c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_usage_cost)}k`,
      //   c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_usage_cost)}k`,
      //   add: `${getOnlyTwoDigitsAfterDecimal(
      //     parseFloat(data[0]?.ec2_usage_cost) +
      //       parseFloat(data[1]?.ec2_usage_cost) +
      //       parseFloat(data[2]?.ec2_usage_cost)
      //   )}k`,
      // },
      // {
      //   id: 3,
      //   service: "gp3 of gp Coverage",
      //   c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_unit_cost)}k`,
      //   c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_unit_cost)}k`,
      //   c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_unit_cost)}k`,
      //   add: `${getOnlyTwoDigitsAfterDecimal(
      //     parseFloat(data[0]?.ec2_unit_cost) +
      //       parseFloat(data[1]?.ec2_unit_cost) +
      //       parseFloat(data[2]?.ec2_unit_cost)
      //   )}k`,
      // },
      {
        id: 4,
        service: "gp2 Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ebs_gp2_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ebs_gp2_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ebs_gp2_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ebs_gp2_cost) +
            parseFloat(data[1]?.ebs_gp2_cost) +
            parseFloat(data[2]?.ebs_gp2_cost)
        )}k`,
      },
      {
        id: 5,
        service: "gp3 Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ebs_gp3_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ebs_gp3_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ebs_gp3_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ebs_gp3_cost) +
            parseFloat(data[1]?.ebs_gp3_cost) +
            parseFloat(data[2]?.ebs_gp3_cost)
        )}k`,
      },
      {
        id: 6,
        service: "io1 Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ebs_io1_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ebs_io1_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ebs_io1_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ebs_io1_cost) +
            parseFloat(data[1]?.ebs_io1_cost) +
            parseFloat(data[2]?.ebs_io1_cost)
        )}k`,
      },
      {
        id: 7,
        service: "io2 Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ebs_io2_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ebs_io2_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ebs_io2_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ebs_io2_cost) +
            parseFloat(data[1]?.ebs_io2_cost) +
            parseFloat(data[2]?.ebs_io2_cost)
        )}k`,
      },
    ]
  },
  getEc2Data:(data)=>{
    return [
      {
        id: 1,
        service: "EC2 Spend Trend",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.total_ec2_spend)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.total_ec2_spend)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.total_ec2_spend)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.total_ec2_spend) +
            parseFloat(data[1]?.total_ec2_spend) +
            parseFloat(data[2]?.total_ec2_spend)
        )}k`,
      },
      {
        id: 2,
        service: "EC2 Usage Trend",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_usage_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_usage_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_usage_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_usage_cost) +
            parseFloat(data[1]?.ec2_usage_cost) +
            parseFloat(data[2]?.ec2_usage_cost)
        )}k`,
      },
      {
        id: 3,
        service: "Unit Cost (EC2)",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_unit_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_unit_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_unit_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_unit_cost) +
            parseFloat(data[1]?.ec2_unit_cost) +
            parseFloat(data[2]?.ec2_unit_cost)
        )}k`,
      },
      {
        id: 4,
        service: "EC2 Prev Generation Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.ec2_previous_generation_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.ec2_previous_generation_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.ec2_previous_generation_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_previous_generation_cost) +
            parseFloat(data[1]?.ec2_previous_generation_cost) +
            parseFloat(data[2]?.ec2_previous_generation_cost)
        )}k`,
      },
      {
        id: 5,
        service: "EC2 Spot Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_spot_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_spot_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_spot_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_spot_cost) +
            parseFloat(data[1]?.ec2_spot_cost) +
            parseFloat(data[2]?.ec2_spot_cost)
        )}k`,
      },
      {
        id: 6,
        service: "EC2 Graviton Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.ec2_graviton_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.ec2_graviton_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.ec2_graviton_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_graviton_cost) +
            parseFloat(data[1]?.ec2_graviton_cost) +
            parseFloat(data[2]?.ec2_graviton_cost)
        )}k`,
      },
      {
        id: 7,
        service: "EC2 AMD Coverage ",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.ec2_amd_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.ec2_amd_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.ec2_amd_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.ec2_amd_cost) +
            parseFloat(data[1]?.ec2_amd_cost) +
            parseFloat(data[2]?.ec2_amd_cost)
        )}k`,
      },
    ]
  },
  getRDSData:(result)=>{
   return [
      {
        id: 1,
        service: "RDS Oracle Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(result[0]?.rds_oracle_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(result[1]?.rds_oracle_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(result[2]?.rds_oracle_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(result[0]?.rds_oracle_cost) +
            parseFloat(result[1]?.rds_oracle_cost) +
            parseFloat(result[2]?.rds_oracle_cost)
        )}k`,
      },
      {
        id: 2,
        service: "RDS SQL Server Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          result[0]?.rds_sql_server_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          result[1]?.rds_sql_server_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          result[2]?.rds_sql_server_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(result[0]?.rds_sql_server_cost) +
            parseFloat(result[1]?.rds_sql_server_cost) +
            parseFloat(result[2]?.rds_sql_server_cost)
        )}k`,
      },
      {
        id: 3,
        service: "RDS Open Source Engines Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          result[0]?.rds_open_source_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          result[1]?.rds_open_source_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          result[2]?.rds_open_source_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(result[0]?.rds_open_source_cost) +
            parseFloat(result[1]?.rds_open_source_cost) +
            parseFloat(result[2]?.rds_open_source_cost)
        )}k`,
      },
      {
        id: 4,
        service: "RDS Graviton Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          result[0]?.rds_graviton_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          result[1]?.rds_graviton_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          result[2]?.rds_graviton_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(result[0]?.rds_graviton_cost) +
            parseFloat(result[1]?.rds_graviton_cost) +
            parseFloat(result[2]?.rds_graviton_cost)
        )}k`,
      },
    ]
  },
  getOtherGravitionData:(result)=>{
    return [
       {
         id: 1,
         service: "ElastiCache Graviton Coverage",
         c1: `${getOnlyTwoDigitsAfterDecimal(result[0]?.elasticache_graviton_cost)}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(result[1]?.elasticache_graviton_cost)}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(result[2]?.elasticache_graviton_cost)}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.elasticache_graviton_cost) +
             parseFloat(result[1]?.elasticache_graviton_cost) +
             parseFloat(result[2]?.elasticache_graviton_cost)
         )}k`,
       },
       {
         id: 2,
         service: "OpenSearch Graviton Coverage",
         c1: `${getOnlyTwoDigitsAfterDecimal(
           result[0]?.opensearch_graviton_cost
         )}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(
           result[1]?.opensearch_graviton_cost
         )}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(
           result[2]?.opensearch_graviton_cost
         )}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.opensearch_graviton_cost) +
             parseFloat(result[1]?.opensearch_graviton_cost) +
             parseFloat(result[2]?.opensearch_graviton_cost)
         )}k`,
       },
       {
         id: 3,
         service: "Lambda Graviton Coverage",
         c1: `${getOnlyTwoDigitsAfterDecimal(
           result[0]?.lambda_graviton_cost
         )}k`,
         c2: `${getOnlyTwoDigitsAfterDecimal(
           result[1]?.lambda_graviton_cost
         )}k`,
         c3: `${getOnlyTwoDigitsAfterDecimal(
           result[2]?.lambda_graviton_cost
         )}k`,
         add: `${getOnlyTwoDigitsAfterDecimal(
           parseFloat(result[0]?.lambda_graviton_cost) +
             parseFloat(result[1]?.lambda_graviton_cost) +
             parseFloat(result[2]?.lambda_graviton_cost)
         )}k`,
       },
     ]
   },
   getOndemandCoverageData: (data) => {
    return [
      {
        id: 1,
        service: "Compute OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(
          data[0]?.compute_ondemand_cost
        )}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(
          data[1]?.compute_ondemand_cost
        )}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(
          data[2]?.compute_ondemand_cost
        )}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.compute_ondemand_cost) +
            parseFloat(data[1]?.compute_ondemand_cost) +
            parseFloat(data[2]?.compute_ondemand_cost)
        )}k`,
      },
      {
        id: 2,
        service: "Sagemaker OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.sagemaker_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.sagemaker_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.sagemaker_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.sagemaker_ondemand_cost) +
            parseFloat(data[1]?.sagemaker_ondemand_cost) +
            parseFloat(data[2]?.sagemaker_ondemand_cost)
        )}k`,
      },
      {
        id: 3,
        service: "RDS OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.rds_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.rds_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.rds_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.rds_ondemand_cost) +
            parseFloat(data[1]?.rds_ondemand_cost) +
            parseFloat(data[2]?.rds_ondemand_cost)
        )}k`,
      },
      {
        id: 4,
        service: "ElastiCache OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.elasticache_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.elasticache_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.elasticache_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.elasticache_ondemand_cost) +
            parseFloat(data[1]?.elasticache_ondemand_cost) +
            parseFloat(data[2]?.elasticache_ondemand_cost)
        )}k`,
      },
      {
        id: 5,
        service: "OpenSearch OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.opensearch_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.opensearch_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.opensearch_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.opensearch_ondemand_cost) +
            parseFloat(data[1]?.opensearch_ondemand_cost) +
            parseFloat(data[2]?.opensearch_ondemand_cost)
        )}k`,
      },
      {
        id: 6,
        service: "Redshift OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.redshift_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.redshift_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.redshift_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.redshift_ondemand_cost) +
            parseFloat(data[1]?.redshift_ondemand_cost) +
            parseFloat(data[2]?.redshift_ondemand_cost)
        )}k`,
      },
      {
        id: 7,
        service: "DynamoDB OD Coverage",
        c1: `${getOnlyTwoDigitsAfterDecimal(data[0]?.dynamodb_ondemand_cost)}k`,
        c2: `${getOnlyTwoDigitsAfterDecimal(data[1]?.dynamodb_ondemand_cost)}k`,
        c3: `${getOnlyTwoDigitsAfterDecimal(data[2]?.dynamodb_ondemand_cost)}k`,
        add: `${getOnlyTwoDigitsAfterDecimal(
          parseFloat(data[0]?.dynamodb_ondemand_cost) +
            parseFloat(data[1]?.dynamodb_ondemand_cost) +
            parseFloat(data[2]?.dynamodb_ondemand_cost)
        )}k`,
      },
    ]
  },
};

function darkenColor(hexColor, percent) {
  // Ensure the percent is within the range [0, 100]
  percent = Math.min(100, Math.max(0, percent));

  // Parse the hex color to RGB
  let r = parseInt(hexColor.substring(1, 3), 16);
  let g = parseInt(hexColor.substring(3, 5), 16);
  let b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the darkness factor
  let darkenFactor = 1 - percent / 100;

  // Darken each component of the RGB color
  r = Math.floor(r * darkenFactor);
  g = Math.floor(g * darkenFactor);
  b = Math.floor(b * darkenFactor);

  // Ensure the values are within the valid range [0, 255]
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  // Convert the darkened RGB values back to hex and return the result
  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}

export const generateRandomColor = () => {
  const colors = itsmDynamicColor;

  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];

  // Darken the color by 10%
  const darkenedColor = darkenColor(randomColor, 50);

  return { baseColor: randomColor, darkenedColor };
};

export const fixedDecimalPlaces = (value, decimalPlace = 2) =>
  isNaN(value) ? 0 : parseFloat(Number(value).toFixed(decimalPlace));

export const last4Months = () => {
  const dateFilterData = [];
  for (let i = 0; i < 4; i++) {
    dateFilterData.push({
      name: moment().subtract(i, "months").format("MMMM YYYY"),
      value: moment().subtract(i, "months").format("MMM YYYY"),
    });
  }
  return dateFilterData;
};

export const reloadPage = () => {
  window.location.reload();
};

export function areArraysOfObjectsEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  arr1.sort((a, b) => a.uniqueProperty.localeCompare(b.uniqueProperty));
  arr2.sort((a, b) => a.uniqueProperty.localeCompare(b.uniqueProperty));
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // Check if the properties and values of the objects are equal
    if (!isObjectEqual(obj1, obj2)) {
      return false;
    }
  }
  return true;
}

export const FirstLetterCapital = (str) => {
  return (
    str.substr(0, 1).toUpperCase() + str.substr(1, str.length).toLowerCase()
  );
};

export function isObjectEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all properties and their values are the same, including null values
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];
    if (value1 !== value2 && !(value1 === null && value2 === null)) {
      return false;
    }
  }

  return true;
}
export const generateRandomAmount = () => {
  const randomValue = Math.random() * 1000; // Generate a random value
  return parseFloat(randomValue.toFixed(2)); // Format to two decimal places
};

//infra

export const generateRandomColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    colors.push(randomColor);
  }
  return colors;
};

export const CaseInsensitiveSort = (rowA, rowB) => {
  const a = rowA.title.toLowerCase();
  const b = rowB.title.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};
