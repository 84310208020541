import React from 'react';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../api/axios';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import moment from "moment";
import { API_URLS } from '../../../constants/API_URLS';
import { ToastSuccess } from '../../../utils/utils';
import { STATUSCODE } from '../../../constants/constant';

const initialState = {
  loading: false,
  loginResponse: [],
  userToken: '',
  userData: [],
  decodedData: '',
  error: '',
  role: '',
  validUser: false
};

export const reduxLogin = createAsyncThunk("login/reduxLogin", (payload) => {
  return axios
    .post(API_URLS.LOGIN.url, payload)
    .then((response) => {
      if (response?.data?.statusCode == STATUSCODE.OK) {
        localStorage.setItem(
          'Token',
          response?.data?.token
        );
        return response?.data;
      }
    })
    .catch((error) => {
      console.log(error)
    });
});


const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    USER_LOGOUT: (state, action) => {
      localStorage.setItem('loginRoleData', "")
      state.loginResponse = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reduxLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reduxLogin.fulfilled, (state, action) => {
      // return (
      // let roleArray = []
      // if (action?.payload?.response?.roleData) {
      //   Object.entries(action?.payload?.response?.roleData).map((value) => {
      //     if (value[1]) {
      //       roleArray.push(value[0])
      //     }
      //   })
      // }
      const currentDate = moment().format('YYYY_MM_DD')
      const [year, month, day] = currentDate.split('_')
      state.year = year;
      state.month = month;
      state.day = day;
      state.loading = false;
      state.loginResponse = action?.payload;
      state.userToken = action?.payload?.response?.token;
      // state.decodedData = jwt_decode(action?.payload?.response?.token);
      // state.userData = roleArray?.length ? roleArray : [];
      state.error = '';
      // );
    });
    builder.addCase(reduxLogin.rejected, (state, action) => {
      state.loading = false;
      state.loginResponse = [];
      state.userToken = '';
      state.userData = [];
      state.decodedData = '';
      state.error = action.error.message;
    });
  },
});

export default loginSlice.reducer;
export const { USER_LOGOUT } = loginSlice.actions;
