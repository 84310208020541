import React, { useEffect, useState } from "react";

import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useLocation } from "react-router-dom";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import userEdit from "../../Images/userEdit.svg";
import BillingIcon from "../../Images/Billing.svg";
import ClmIcon from "../../Images/ClmIconOutlined.svg";
import { ROUTE_PATH } from "../../constants/Routes";
import { checkIfPatchIsActive } from "../../utils/utils";
import ApiManagerIcon from "../../Images/API Manager.svg";
import CxoDasboardIcon from "../../Images/CXO Dashboard.svg";
import InfraStructureIcon from "../../Images/Infrastructure.svg";
import ReleaseManagment from "../../Images/Release Management.svg";
import IncidentManagmentIcon from "../../Images/incidentManagementWhite.svg";

import "./SidebarMenu.scss";

const SidebarMenu = ({ forSmallDevices, isSmallSidebarOpen }) => {
  const [isBillingMenuOpen, setIsBillingMenuOpen] = useState(false);
  const [isClmMenuOpen, setIsClmMenuOpen] = useState(false);
  const [isCostOptimizerOpen, setIsCostOptimizerOpen] = useState(false);
  const [isBillingDashboardOpen, setIsBillingDashboardOpen] = useState(false);
  const location = useLocation();
  const matchRouteForNavItem = (path, hasSubmenu = false) => {
    return checkIfPatchIsActive(location, path) ? `menu-link-active` : "";
  };

  const handleCostOptimizerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCostOptimizerOpen(!isCostOptimizerOpen);
  };

  const handleBillingDashboardClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsBillingDashboardOpen(!isBillingDashboardOpen);
  };

  const handleBillingClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isBillingMenuOpen) {
      setIsCostOptimizerOpen(false);
    }
    setIsBillingMenuOpen(!isBillingMenuOpen);
  };

  const handleClmClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isClmMenuOpen) {
      setIsCostOptimizerOpen(false);
    }
    setIsClmMenuOpen(!isClmMenuOpen);
  };

  useEffect(() => {
    if (!isSmallSidebarOpen) {
      setIsBillingMenuOpen(false);
      setIsClmMenuOpen(false);
      setIsCostOptimizerOpen(false);
    }
  }, [isSmallSidebarOpen]);

  return (
    <div
      className={`SidebarMenu ${forSmallDevices && "SidebarMenuSmall"} my-2`}
    >
      <PerfectScrollbar>
        <ul
          role="list"
          id="SidebarMenu-scroll-container"
          className="SidebarMenu__list d-flex flex-column"
        >
          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                ROUTE_PATH.CXO.dashboard
              )}
              `}
              to={ROUTE_PATH.CXO.dashboard}
            >
              <span className="menu-link-icon">
                <SVG src={CxoDasboardIcon} />
              </span>
              <span className="menu-link-text">CXO Dashboard</span>
            </NavLink>
          </li>
          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center 
              ${matchRouteForNavItem(ROUTE_PATH.Infra.inventory)}
                `}
              to={ROUTE_PATH.Infra.inventory}
            >
              <span className="menu-link-icon">
                <SVG src={InfraStructureIcon} />
              </span>
              <span className="menu-link-text">Infrastructure</span>
            </NavLink>
          </li>
          {/* {get(menuOptionsAvailable, "users") && ( */}
          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                ROUTE_PATH.BILLING.ALERTS_AND_INSIGHTS ||
                  ROUTE_PATH.BILLING.ANOMOLY_DASHBOARD ||
                  ROUTE_PATH.BILLING.BUDGET ||
                  ROUTE_PATH.BILLING.BUDGET_MANAGMENT ||
                  ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD ||
                  ROUTE_PATH.BILLING.COST_ANOMOLY ||
                  ROUTE_PATH.BILLING.COST_ANOMOLY_DASHBOARD ||
                  ROUTE_PATH.BILLING.COST_TRENDS ||
                  ROUTE_PATH.BILLING.CREATE_BUDGET ||
                  ROUTE_PATH.BILLING.CURRENT_RESERVATION ||
                  ROUTE_PATH.BILLING.DASHBOARD ||
                  ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS ||
                  ROUTE_PATH.BILLING.OPTIMIZE_ACTION ||
                  ROUTE_PATH.BILLING.OPTIMIZE_ACTION_SUMMARY ||
                  ROUTE_PATH.BILLING.OPTIMIZE_RATE ||
                  ROUTE_PATH.BILLING.OPTIMIZE_USAGE ||
                  ROUTE_PATH.BILLING.OPTIMIZE_USAGE_MANAGMENT ||
                  ROUTE_PATH.BILLING.OPTIMIZE_USAGE_SUMMARY ||
                  ROUTE_PATH.BILLING.POSTURE ||
                  ROUTE_PATH.BILLING.UPDATE_BUDGET
              )}`}
              to={ROUTE_PATH.BILLING.DASHBOARD}
              onClick={handleBillingClick}
            >
              <span className="menu-link-icon">
                <SVG src={BillingIcon} />
              </span>
              <span className="menu-link-text">Billing</span>
              <span
                className={`menu-link-caret ${
                  isBillingMenuOpen ? "open" : "closed"
                } ml-auto`}
              >
                <ChevronRightIcon fontSize="5" />
              </span>
            </NavLink>
          </li>
          {isBillingMenuOpen && (
            <div className="pt-1 pb-1">
              {/* {get(menuOptionsAvailable, "users") && ( */}
              {/* <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.DASHBOARD
                  )}`}
                  to={ROUTE_PATH.BILLING.DASHBOARD}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-link-text">Dashboard</span>
                </NavLink>
              </li> */}
              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center  ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.DASHBOARD
                  )}`}
                  to={ROUTE_PATH.BILLING.DASHBOARD}
                  onClick={handleBillingDashboardClick}
                >
                  <span className="menu-link-text">Dashboard</span>
                  <span
                    className={`menu-link-caret ${
                      isBillingDashboardOpen ? "open" : "closed"
                    } ml-auto`}
                  >
                    <ChevronRightIcon fontSize="5" />
                  </span>
                </NavLink>
              </li>

              {isBillingDashboardOpen && (
                <>
                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.DASHBOARD
                      )}`}
                      to={ROUTE_PATH.BILLING.DASHBOARD}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Billing Dashboard</span>
                    </NavLink>
                  </li>

                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.KPI_DASHBOARD
                      )}`}
                      to={ROUTE_PATH.BILLING.KPI_DASHBOARD}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">KPI Dashboard</span>
                    </NavLink>
                  </li>

                </>
              )}
              <li className="SidebarMenu__list-item child mx-4">
                {/* <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.POSTURE
                  )}`}
                  to={ROUTE_PATH.BILLING.POSTURE}
                > */}
                <div
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.POSTURE
                  )}`}
                  onClick={() =>
                    window.location.replace(ROUTE_PATH.BILLING.POSTURE)
                  }
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-link-text">Posture</span>
                </div>
                {/* </NavLink> */}
              </li>
              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.BUDGET
                  )}`}
                  to={ROUTE_PATH.BILLING.BUDGET}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-link-text">Budget</span>
                </NavLink>
              </li>
              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.ANOMOLY_DASHBOARD
                  )}`}
                  to={ROUTE_PATH.BILLING.ANOMOLY_DASHBOARD}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-link-text">Anomaly Dashboard</span>
                </NavLink>
              </li>

              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS
                  )}`}
                  to={ROUTE_PATH.BILLING.MARKUP_AND_DISCOUNTS}
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-link-text">Markup & Discounts</span>
                </NavLink>
              </li>

              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center  ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD
                  )}`}
                  to={ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD}
                  onClick={handleCostOptimizerClick}
                >
                  <span className="menu-link-text">Cost Optimizer</span>
                  <span
                    className={`menu-link-caret ${
                      isCostOptimizerOpen ? "open" : "closed"
                    } ml-auto`}
                  >
                    <ChevronRightIcon fontSize="5" />
                  </span>
                </NavLink>
              </li>

              {isCostOptimizerOpen && (
                <>
                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD
                      )}`}
                      to={ROUTE_PATH.BILLING.COSTCONTAINER_DASHBOARD}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text"> Dashboard</span>
                    </NavLink>
                  </li>

                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.OPTIMIZE_USAGE
                      )}`}
                      to={ROUTE_PATH.BILLING.OPTIMIZE_USAGE}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Optimize Usage</span>
                    </NavLink>
                  </li>

                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.OPTIMIZE_RATE
                      )}`}
                      to={ROUTE_PATH.BILLING.OPTIMIZE_RATE}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Optimize Rate</span>
                    </NavLink>
                  </li>

                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.OPTIMIZE_ACTION
                      )}`}
                      to={ROUTE_PATH.BILLING.OPTIMIZE_ACTION}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Optimize Actions</span>
                    </NavLink>
                  </li>
                </>
              )}

              <li className="SidebarMenu__list-item child mx-4">
                <NavLink
                  className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                    ROUTE_PATH.BILLING.COST_OVERVIEW.ACCOUNT_BILLING ||
                      ROUTE_PATH.BILLING.COST_OVERVIEW.RESOURCE_BILLING ||
                      ROUTE_PATH.BILLING.COST_OVERVIEW.PROJECT_BILLING ||
                      ROUTE_PATH.BILLING.COST_OVERVIEW.APPLICATION_BILLING
                  )}`}
                  to={ROUTE_PATH.BILLING.COST_OVERVIEW.ACCOUNT_BILLING}
                  onClick={handleClmClick}
                >
                  <span className="menu-link-text">Cost Overview</span>
                  <span
                    className={`menu-link-caret ${
                      isClmMenuOpen ? "open" : "closed"
                    } ml-auto`}
                  >
                    <ChevronRightIcon fontSize="5" />
                  </span>
                </NavLink>
              </li>

              {isClmMenuOpen && (
                <>
                  {/* {get(menuOptionsAvailable, "users") && ( */}
                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.COST_OVERVIEW.ACCOUNT_BILLING
                      )}`}
                      to={ROUTE_PATH.BILLING.COST_OVERVIEW.ACCOUNT_BILLING}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Account Billing</span>
                    </NavLink>
                  </li>

                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.COST_OVERVIEW.RESOURCE_BILLING
                      )}`}
                      to={ROUTE_PATH.BILLING.COST_OVERVIEW.RESOURCE_BILLING}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Resource billing</span>
                    </NavLink>
                  </li>
                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.COST_OVERVIEW.PROJECT_BILLING
                      )}`}
                      to={ROUTE_PATH.BILLING.COST_OVERVIEW.PROJECT_BILLING}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">Project billing</span>
                    </NavLink>
                  </li>
                  <li className="SidebarMenu__list-item child mx-5">
                    <NavLink
                      className={`menu-link d-flex flex-row align-items-center ${matchRouteForNavItem(
                        ROUTE_PATH.BILLING.COST_OVERVIEW.APPLICATION_BILLING
                      )}`}
                      to={ROUTE_PATH.BILLING.COST_OVERVIEW.APPLICATION_BILLING}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-link-text">
                        Application billing
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
            </div>
          )}

          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center 
              ${matchRouteForNavItem(ROUTE_PATH.ITSM.workFlowModuleListing)}
                `}
              to={ROUTE_PATH.ITSM.workFlowModuleListing}
            >
              <span className="menu-link-icon">
                <SVG src={IncidentManagmentIcon} />
              </span>
              <span className="menu-link-text">ITSM</span>
            </NavLink>
          </li>

          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center 
                `}
            >
              <span className="menu-link-icon">
                <SVG src={ReleaseManagment} />
              </span>
              <span className="menu-link-text">Release Managment</span>
            </NavLink>
          </li>

          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center 
                `}
            >
              <span className="menu-link-icon">
                <SVG src={ApiManagerIcon} />
              </span>
              <span className="menu-link-text">Api manager</span>
            </NavLink>
          </li>

          <li className="SidebarMenu__list-item">
            <NavLink
              className={`menu-link d-flex flex-row align-items-center 
              ${matchRouteForNavItem(ROUTE_PATH.user.userView)}
                `}
              to={ROUTE_PATH.user.userView}
            >
              <span className="menu-link-icon">
                <SVG src={userEdit} />
              </span>
              <span className="menu-link-text">Users</span>
            </NavLink>
          </li>
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SidebarMenu;
