export const API_URLS = {
  LOGIN: {
    url: "/auth-service/v1/auth/login",
  },
  SyncWithCloud: "/infra-service/v1/inventory/sync-up",
  Logout: "/auth-service/v1/auth/logout",
  USERS: {
    getUserList: "/auth-service/v1/auth/getUsers/",
    createUser: "/auth-service/v1/auth/save",
    deletUsers: "/auth-service/v1/auth/deleteUser",
  },
  POSTURE: {
    POST_POSTURE_TABLE: "/cost-service/v1/posture/historical?",
    POSTURE_DOWNLOAD: "/cost-service/v1/posture/historical/download/csv?",

    GET_FORCAST: "cost-service/v1/posture/posture",

    POST_SUMMARY: "cost-service/v1/posture/summary?",
    POST_SUMMARY_MONTH:
      "cost-service/v1/posture/getMonthlyCostByAccountAndMonths?",

    GET_SINGLE_PRODUCT: "cost-service/v1/posture/productServiceDayCost?",
    POST_PRODUCT_MONTH: "cost-service/v1/posture/getProductMonthCost?",
    GET_PRODUCT: "cost-service/v1/posture/product?",
    GET_UNTAGS: "cost-service/v1/posture/tagUntagDay?",

    GET_TAGS: "cost-service/v1/posture/tagTopDay?",
    POST_TAGS_MONTH: "cost-service/v1/posture/tagTopMonth?",
    POST_UNTAGS_MONTH: "/cost-service/v1/posture/tagUntag?",
    GET_SUMMAY_MONTH_TABLE: "/cost-service/v1/posture/summarMonthTable?",
    GET_REGION: "/cost-service/v1/posture/region?",
    POST_REGION_MONTH: "cost-service/v1/posture/regionMonth?",
    PRODUCT_TABLE: "cost-service/v1/posture/getProductMonthTable?",
    REGION_TABLE: "cost-service/v1/posture/regionMonthTable?",
    TAGS_TABLE: "cost-service/v1/posture/tagTopMonthTable?",
    UNTAG_TABLE: "cost-service/v1/posture/tagUntagTable?",

    ACCOUNTIDS: "cost-service/v1/posture/getAccounts",
    CSV_DOWNLOAD_SUMMARY: "/cost-service/v1/posture/summaryTable/generate-csv?",
    CSV_DOWNLOAD_TAG: "/cost-service/v1/posture/topTagTable/download-csv?",
    CSV_DOWNLOAD_UNTAG: "/cost-service/v1/posture/tagUntagTable/download-csv?",

    CSV_DOWNLOAD_PRODUCT: "/cost-service/v1/posture/productTable/download-csv?",
    CSV_DOWNLOAD_REGION: "/cost-service/v1/posture/regionTable/download-csv?",
  },
  V1: {
    POSTURE: {
      GET_CARDS_DATA: "/cost-service/v1/posture/getTotalcost?",
      POST_POSTURE_TABLE: "cost-service/v1/posture/accountView",

      GET_FORCAST: "cost-service/v1/posture/posture",

      POST_SUMMARY: "cost-service/v1/posture/summary?",
      POST_SUMMARY_MONTH:
        "cost-service/v1/posture/getMonthlyCostByAccountAndMonths?",

      GET_SINGLE_PRODUCT: "cost-service/v1/posture/productServiceDayCost?",
      POST_PRODUCT_MONTH: "cost-service/v1/posture/getProductMonthCost?",
      GET_PRODUCT: "cost-service/v1/posture/product?",

      GET_TAGS: "cost-service/v1/posture/tagUntagDay?",
      POST_UNTAGS_MONTH: "/cost-service/v1/posture/tagUntag?",
      POST_TAGS_MONTH: "/cost-service/v1/posture/tagTopMonth?",

      GET_REGION: "/cost-service/v1/posture/region?",
      POST_REGION_MONTH: "cost-service/v1/posture/regionMonth?",

      PRODUCT_TABLE: "cost-service/v1/posture/getProductMonthTable?",
      REGION_TABLE: "cost-service/v1/posture/regionMonthTable?",
      TAGS_TABLE: "cost-service/v1/posture/tagUntagTable?",
      ACCOUNTIDS: "cost-service/v1/posture/getAccounts",
    },
    MARKUP: {
      DELETE: "/cost-service/pricingRule/v1/delete?",
      GET: "/cost-service/pricingRule/v1/list/rule",
      CREATE: "/cost-service/pricingRule/v1/create/rules",
      UPDATE: "/cost-service/pricingRule/v1/update",
      ACCLIST: "/cost-service/pricingRule/v1/get-AccountList",
    },
    DASHBOARD: {
      // CHART APIs
      GET_TOTAL_SPEND: "/cost-service/v1/dashboard/totalSpend",
      GET_CLOUD_SPEND_TREND: "/cost-service/v1/dashboard/spendTrend",
      GET_SPEND_BY_REGION: "/cost-service/v1/dashboard/spendByRegion",
      GET_SPEND_BY_PRODUCT_FAMILY:
        "/cost-service/v1/dashboard/spendByProductFamily",
      GET_SPEND_BY_TAGGED: "/cost-service/v1/dashboard/spendByTaggedStatus",
      GET_BUDGET_DRIFT: "/cost-service/v1/dashboard/getBudgetDrift",
      GET_BUDGET_FORECAST: "/cost-service/v1/dashboard/getBudgetForecast",
      GET_CLOUD_SPEND_FORECAST:
        "/cost-service/v1/dashboard/getCloudSpendForecast",
      GET_TOP_BUDGET: "cost-service/v1/dashboard/getTopBudget",

      // DROPDOWN API
      GET_CLOUD_ACCOUNT: "/cost-service/v1/dropdowns/cloudAccounts",

      // CARD API
      GET_CARDS_DATA: "/cost-service/v1/dashboard/dashboardCards",

      // cost anomaly module
      GET_ANOMALIES: "/cost-service/anamolies/v1/get-anomalies",
      GET_IMPACT_BY_CLOUD_ACCOUNT:
        "/cost-service/anamolies/v1/get-impactByCloudAccount?",
      GET_IMPACT_BY_PRODUCT: "/cost-service/anamolies/v1/get-impactByProduct?",
      GET_RESOURCE_CATEGORY:
        "/cost-service/anamolies/v1/get-costImpactByResourceCategory",
      GET_CLOUD_PROVIDER:
        "/cost-service/anamolies/v1/get-costImpactByCloudProvider",
    },
    COST_OPTIMIZER: {
      COST_OPTIMIZER_DASHBOARD:
        "/cost-service/costOptimisation/dashboard/v1/get/dashboard/data",
    },
    BUDGET: {
      GET_ALL_DATA: "/cost-service/v1/budget/getAllBudgets?",
      DELETE_BUDGET: "/cost-service/v1/budget/deletebudget?",
      GET_BUDGET_HISTORY: "/cost-service/v1/budget/getBudgetHistory?",
      GET_ALERTS_FROM_ALERTS_INSIGHTS: "/cost-service/v1/budget/getAlerts?",
      GET_BUDGET_ALERTS_BY_NAME: "/cost-service/v1/budget/getAlertsByName?",
      DOWNLOAD_BUDGET_DATA: "/cost-service/v1/budget/downloadfile?",
      GET_NOTIFICATION_LIST: "/cost-service/v1/budget/getNotifications?",
      GET_BUDGET_AND_ALERT_COUNT: "/cost-service/v1/budget/getBACount?",
      GET_CLOUD_ACCOUNT_CREATE_BUDGET:
        "/cost-service/v1/budget/getMemberAccounts",
      GET_PERIOD_DATA: "/cost-service/v1/budget/getPeriods",
      GET_FILTER_DROPDOWN: "/cost-service/v1/budget/getFilter",
      GET_TAG_VALUE: "/cost-service/v1/budget/getTagValues",
      GET_FILTER_TYPE: "/cost-service/v1/budget/getFiltervalue",
      GET_SUGGESTED_VALUES_CREATE_BUDGET_BY_MONTHS:
        "/cost-service/v1/budget/getMonthlyPlanned?",
      GET_SUGGESTED_VALUES_CREATE_BUDGET_BY_QUARTERLY:
        "/cost-service/v1/budget/getQuarterlyPlanned?",
      CREATE_BUDGET_API: "/cost-service/v1/budget/saveBudget",
      SEARCH_BUDGET: "/cost-service/v1/budget/getAllBudgets?",
      UPDATE_BUDGET: "/cost-service/v1/budget/updatebudget",
    },
    OPTIMIZE_USAGE: {
      getRightSizingInnerData:
        "/cost-service/optimse-usage/v1/get/right-sizing?",
      downloadRightSizingInnerData:
        "/cost-service/optimse-usage/v1/get/right-sizing/download-csv?",
      getResourceDetailsRightSizing:
        "/cost-service/optimse-usage/v1/get/right-sizing/get-resourceDetails?",
      getRightSizingOverviewDetails:
        "/cost-service/optimse-usage/v1/get/right-sizing/get-overviewDetails?",
      getSavingDetails:
        "/cost-service/optimse-usage/v1/get/right-sizing/get-saving-details?",
      getCompareDetails:
        "cost-service/optimse-usage/v1/compare/instance?service=AmazonEC2&currentInstanceType=m5a.xlarge&recommendedInstanceType=t3.micro%2Ct2.micro%2Ct2.nano",
      getOrphanedData:
        "/cost-service/optimse-usage/v1/get/manage-orphan/get-orphaned-data?",
      getIdleData:
        "/cost-service/optimse-usage/v1/get/manage-idle/get-idle-data?",
      orphanDownload:
        "/cost-service/optimse-usage/v1/get/manage-orphan/download-csv",
      idleDownload:
        "/cost-service/optimse-usage/v1/get/manage-idle/get-idle-resources/download-csv?",
      idleAllDownload:
        "/cost-service/optimse-usage/v1/download/all/idle/right-sizing",
      getManageOrphanOverviewDetails:
        "/cost-service/optimse-usage/v1/get/manage-orphan/OverviewDetails?",
      getManageIdleOverviewDetails:
        "/cost-service/optimse-usage/v1/get/manage-idle/OverviewDetails?",
      getManageOrphanSavingsDetails:
        "/cost-service/optimse-usage/v1/get/manage-orphan/get-cost-details?",
      getManageIdleSavingsDetails:
        "/cost-service/optimse-usage/v1/get/manage-idle/get-cost-details?",
      getResourceDetailsManageIdle:
        "/cost-service/optimse-usage/v1/get/manage-idle/get-resourceDetails?",
      getCompareDetails: "cost-service/optimse-usage/v1/compare/instance?",
      getSkuCompareDetails: "/cost-service/optimse-usage/v1/compare/instance?",
      getOrphanAllDetails: "/cost-service/optimse-usage/v1/get/orphan/all?",
      getIdleAllDetails: "/cost-service/optimse-usage/v1/get/idle/all?",
      getRightSizingAllDetails:
        "/cost-service/optimse-usage/v1/get/right-sizing/all?",
      rightSizingDownload:
        "/cost-service/optimse-usage/v1/download/all/right-sizing",
      createAction: "/cost-service/schedule-recommendation/v1/create-action",
    },
    OPTIMIZE_RATE: {
      getCurrentReservations:
        "/cost-service/v1/optimizeRate/currentReservations?",
      getRecommendedReservations:
        "/cost-service/v1/optimizeRate/RecommendedReservation?",
      getDownloadFile: "/cost-service/v1/optimizeRate/downloadfile",
      getRecommendedReservationsCsvDownload:
        "/cost-service/v1/optimizeRate/get/recommendation-reservation/download-csv?",
      getCurrentReservationsCsvDownload:
        "/cost-service/v1/optimizeRate/get/current-reservation/download-csv?",
    },
    OPTIMIZE_ACTIONS: {
      getOptimizeActions: "/cost-service/v1/costAction/getCostActions",
      getDownloadReports: "/cost-service/v1/costAction/downloadReport",
    },
  },
  ITSM: {
    getPrioritiesDropdownData: "/infra-service/v1/itsm/getPriorities",
    getCategoriesDropdownData: "/infra-service/v1/itsm/getCategories",
    getProjectDropdownData: "/infra-service/v1/itsm/getProject",
    getAssignedToDropdownData: "/infra-service/v1/itsm/getAssignees",
    createWorkFlow: "/infra-service/v1/itsm/createWorkFlow",
    getTicketListing: "/infra-service/v1/itsm/getTickets",
    deleteTicket: "/infra-service/v1/itsm/deleteTicket?",
  },
  infra: {
    getAccountDetails: "/infra-service/v1/inventory/getAccountDetails",
    inventory: {
      getTopTenResource: "/infra-service/v1/inventory/getTopTenResource",
      getTotalResourceCountByRegion:
        "/infra-service/v1/inventory/getTotalResourceCountByRegion",
      getTotalResourceCount:
        "/infra-service/v1/inventory/getTotalResourceCount",
      getAllResources: "/infra-service/v1/inventory/getAllResources",
      getTagResources: "/infra-service/v1/inventory/getTags",
      getVpcResources: "/infra-service/v1/inventory/getVpcAndCf",
      getCFResources: "/infra-service/v1/inventory/getVpcAndCf",

      getTableDataAccordian: "/infra-service/v1/inventory/getResourceDetails",
      getTagsTableDataAccordian:
        "/infra-service/v1/inventory/getTagedResources",
      getUntagedTableDataAccordian:
        "/infra-service/v1/inventory/getUnTagedResources",
      addTags: "/infra-service/v1/inventory/addTags",
      getTagUntagCount: "/infra-service/v1/inventory/taged-untaged-count",
      getCustomButtonDataInCSV: "/clm-service/inventory/customInventory",
      getInActiveResources: "/clm-service/inventory/listInventory",
    },
    compute: {
      servers: {
        getComputeServers: "/infra-service/v1/compute/server",
        saveServerGroup:
          "/infra-service/v1/compute/server-group/saveServerGroup",
        getServerGroups: "/infra-service/v1/compute/server-group/getGroups/",
        deleteServerGroup:
          "/infra-service/v1/compute/server-group/deleteGroup?",
        serverMonitoring: "/infra-service/v1/compute/monitoring?",
        serverOperation: "/infra-service/v1/compute/serverOperation",
        remoteSession: "/infra-service/v1/compute/getSessions",
        getInstancesOfParticularServerGroup:
          "/infra-service/v1/compute/server-group/getInstance",
        PatchOperation: "infra-service/v1/compute/patchSacnInstance",
        convertToManaged: "infra-service/v1/compute/managedUnmanaged",
      },
      images: {
        amiList: "/infra-service/v1/compute/amiList ",
        imageOperation: "/infra-service/v1/compute/imageOperation",
        regionList: "/infra-service/v1/compute/regionList",
      },
      workspace: {
        workspaceList: "/infra-service/v1/compute/workspaceList",
        workspaceOperation: "/infra-service/v1/compute/workspaceOperation",
      },
      nootbook: {
        notebookList: "/infra-service/v1/compute/notebookList",
        notebookOperation: "/infra-service/v1/compute/notebookOperation",
      },
    },
    storage: {
      volumes: {
        getVolumeTable: "/infra-service/v1/storage/getVolumes",
        volumeOperation: "/infra-service/v1/storage/volumeOperation",
      },
      s3: {
        getS3Table: "/infra-service/v1/storage/getS3Bucket",
      },
      snapShot: {
        getSnapShotTable: "/infra-service/v1/storage/getSnapshots",
        snapshotOperation: "/infra-service/v1/storage/snapshotOperation",
      },
    },
  },
  CLM: {
    billing: {
      pieChartDataApi: "/cost-service/clm-service/billing/fetchApplicationCost",
      serviceTableDataApi:
        "/cost-service/clm-service/billing/fetchServiceWiseCost",
      applicationTableDataApi:
        "/cost-service/clm-service/billing/fetchApplTagCost",
    },
    resourceBilling: {
      serviceDropDownData: "/cost-service/clm-service/cur/serviceCode",
      serviceTableData: "/cost-service/clm-service/cur/curReport",
    },
    applicationBilling: {
      applicationTagsDropDownData:
        "/cost-service/clm-service/billing/fetchAllApplicationTags",
      applicationServiceTableData:
        "/cost-service/clm-service/billing/applicationWithTagKey",
    },
    projectBilling: {
      accountDropdown: "cost-service/v1/dropdowns/cloudAccounts",
      chartData:
        "/cost-service/clm-service/billing/getAccountIdApplicationCostService",
      pieToBarTable:
        "/cost-service/clm-service/billing/getApplicationServiceCostService",
      barToTable:
        "/cost-service/clm-service/billing/getAppServiceResourceWiseCost",
    },
  },
  CXO: {
    getServiceCostTable: "/cost-service/v1/cxo/serviceCostTable",
    getServiceCost: "/cost-service/v1/cxo/serviceCost",
    getYearCost: "/cost-service/v1/cxo/yearCost",
    getYearInsights: "/cost-service/v1/insights/generate-insights",
    accountCost: "/cost-service/v1/cxo/accountCost",
  },
  KPI_DASHBOARD: "/cost-service/v1/insights/kpi-metrics",
};

export const STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  DATA_NOT_FOUND: 404,
  DUPLICATE_RECORD: 409,
  INTERNAL_SERVER_ERROR: 500,
  NOT_APPLICABLE: 406,
};
