import React, { useState } from "react";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { commonConstants } from "../../../../../constants/constant";
import '../../../../../style/Common.scss';

const RenameModal = (props) => {
  const [name, setName] = useState("");
  const selectedRow =
    props?.selectedRows ? props?.selectedRows[0] : {};
  const onRename = () => {
    const postBody = [
      {
        amiId: selectedRow?.imageId,
        newName: name,
        operation: commonConstants.rename,
        accountId: selectedRow?.accountNumber,
        shareAccountId: null,
      },
    ];
    props.onRename(postBody);
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="lg"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Rename Image - {selectedRow?.imageId}({selectedRow?.imageName || "-"})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row className="tagSearchModal_borderBottom">
            <Col xs sm={5} md={5}>
              <p className="mb-1">Key</p>
            </Col>
            <Col xs sm={5} md={5}>
              <p className="mb-1">Value</p>
            </Col>
          </Row>
          <Row>
            <Col xs sm={5} md={5}>
              <input
                disabled
                placeholder={"Name"}
                className="w-75 mt-2"
              ></input>
            </Col>
            <Col xs sm={5} md={5}>
              <input
                onChange={(e) => setName(e?.target?.value)}
                value={name}
                className="w-75 mt-2"
              ></input>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline"
          onClick={() => {
            props.onHide();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!name}
          onClick={() => {
            onRename();
            // onSearch(KeyValPairs);
          }}
        >
          Rename Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameModal;
