import React, { useState } from "react";

import axios from "../../../api/axios";
import { Formik } from "formik";
import SVG from "react-inlinesvg";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Divider,
  TextField,
} from "@mui/material";

import AuthWrapper from "./wrapper";
import Outlook from "../../../Images/outlook.svg";
import { loginUserApi } from "../../../api/users";
import { ToastError } from "../../../utils/utils";
import { ROUTE_PATH } from "../../../constants/Routes";
import { API_URLS } from "../../../constants/API_URLS";
import { SET_LOGIN_RESPONSE } from "../../../redux/Slices/AuthSlice";
import { LoginInitialValues } from "../../../globals/validations/index";
import { LoginValidationSchema } from "../../../globals/validations/index";
import {
  ErrorMessage,
  loginApiHeaders,
  toastIds,
} from "../../../constants/constant";

import "./Auth.scss";
import "../../../style/index.scss";

const Login = () => {
  const [viewPassword, setViewPassword] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (values, { setSubmitting, setFieldError, setStatus }) => {
    setSubmitting(true);
    axios
      .post(
        API_URLS.LOGIN.url,
        { empEmail: values.email, password: values.password },
        {
          headers: loginApiHeaders,
        }
      )
      .then((resp) => {
        if (resp.data?.statusCode === 200) {
          const respData = { ...resp?.data, email: values?.email };
          dispatch(SET_LOGIN_RESPONSE(respData));
          navigate(ROUTE_PATH.BILLING.DASHBOARD);
        } else {
          ToastError(ErrorMessage.invalidCredentialsLogin, toastIds.apiError);
        }
      })
      .catch((error) => {
        ToastError(ErrorMessage.invalidCredentialsLogin, toastIds.apiError);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const toggleShowPassword = () => {
    setViewPassword(!viewPassword);
  };

  return (
    <AuthWrapper>
      <div className="Auth__form p-4 bg-white h-100 w-100">
        {/* FORM STARTS */}
        <div className="Auth__form-wrapper">
          <Formik
            initialValues={LoginInitialValues}
            validationSchema={LoginValidationSchema()}
            onSubmit={handleLogin}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex flex-column">
                    <div className=" mb-4 ">
                      <span className="text-label">Email ID</span>
                      <div className="w-100 mt-2">
                        <TextField
                          name="email"
                          placeholder="Enter Email ID"
                          variant="outlined"
                          value={values.email}
                          defaultValue={values.email}
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          onBlur={handleBlur}
                          className="w-100"
                          error={errors.email && touched.email}
                          sx={{
                            input: {
                              borderRadius: 5,
                              padding: "14px 8px",
                            },
                          }}
                        />
                        {errors.email && touched.email && (
                          <small className="text-danger ml-2 d-block mb-2">
                            {errors.email}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="w-100 mb-4">
                      <span className="text-label py-2 ">Password</span>
                      <div className="w-100 mt-2 passwordWrapper">
                        <TextField
                          name="password"
                          placeholder="Enter Password"
                          variant="outlined"
                          value={values.password}
                          defaultValue={values.password}
                          onBlur={handleBlur}
                          className="w-100"
                          type={viewPassword ? "password" : "text"}
                          error={errors.password && touched.password}
                          onChange={(e) =>
                            setFieldValue("password", e.target.value)
                          }
                          sx={{
                            input: {
                              // padding: "14px 8px",
                              // "&::placeholder": {
                              //   alignSelf: "center",
                              //   opacity: 1,
                              //   color: "$input-border",
                              // },
                              borderRadius: 5,
                              padding: "14px 8px",
                            },
                          }}
                        />
                        {viewPassword ? (
                          <div
                            className="passwordWrapper_passwordIcon"
                            onClick={toggleShowPassword}
                          >
                            <Visibility />
                          </div>
                        ) : (
                          <div
                            className="passwordWrapper_passwordIcon"
                            onClick={toggleShowPassword}
                          >
                            <VisibilityOff />
                          </div>
                        )}
                        {errors.password && touched.password && (
                          <small className="text-danger ml-2 d-block">
                            {errors.password}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <LoadingButton
                      type="submit"
                      className="login-btn w-100 py-2"
                      variant="contained"
                      disableElevation
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Login
                    </LoadingButton>
                  </div>

                  <Divider className="text-muted mt-3">OR</Divider>

                  <div className="mt-3">
                    <Button
                      className="outlook-btn w-100 py-2"
                      variant="contained"
                      disableElevation
                    >
                      <span className="mx-2">
                        <SVG className="outlook-img" src={Outlook} />
                      </span>
                      <span className="text-blue">Outlook</span>
                    </Button>
                  </div>

                  <div className="text-right mt-3">
                    {/* <Link
                      className="form-sublink"
                      to={APP_ROUTES.ROUTE_FORGOT_PASSWORD}
                    >
                      Forgot Password ?
                    </Link> */}
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
        {/* FORM ENDS */}
      </div>
    </AuthWrapper>
  );
};

export default Login;
