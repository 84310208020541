import React, { useEffect, useReducer } from 'react';

import { Button, ButtonGroup, Col, Nav, Row, Tab } from 'react-bootstrap';

import axios from '../../../api/axios';
import awsIcon from '../../../Images/awsIcon.svg';
import InventorySearch from './inventorySearch';
import azureIcon from '../../../Images/azureIcon.svg';

import '../../../style/Infra/inventory.scss';
import {
  STATUSCODE,
  graphColors,
  inventoryDropdownValues,
  InActiveTableCols,
  rootAccount,
  providerText,
  regionText,
  serviceType,
  status,
} from '../../../constants/constant';
import SmallInventoryCard from '../../../components/InfraCards/InventoryCards/smallInventoryCard';
import HorizontalBarChart from '../../../components/InfraCharts/Bar/horizontalBarChart';
import InventoryCard from '../../../components/InfraCards/InventoryCards/inventorycard';
import AwsCard from '../../../components/InfraCards/InventoryCards/awsCard';
import Accordion from '../../../components/InfraAccordion/accordion';
import { API_URLS } from '../../../constants/API_URLS';
import Header from '../../header/Header';
import DoughnutChart from '../../../components/InfraCharts/Doughnut/doughnut';
import DonutChart from '../../../components/InfraCharts/ApexDoughnutChart';
import CommonTable from '../../../components/Infra/Table/table';
import CommonTooltip from '../../../components/Tooltip';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import _ from 'lodash';

const initialState = {
  selectedValue: 'All Resources',
  selectedServiceTypeValue: 'EC2',
  ByAccountResource: '',
  loading: false,
  ByRegion: '',
  accordionItems: '',
  activeTab: 'AWS',
  accountIdData: [],
  activeTitle: 'Governance',
  selectedResource: [],
  tagData: [],
  selectedTab: 'aws',
  tagUntagCount: {
    tagged: 0,
    untagged: 0,
  },
  regionDropdownOptions: [],
  selectedRegion: [],
  topTenResouces: '',
  totalCountAccountDoughnut: 0,
  vpcData: '',
  awsCount: 0,
  awsFilterCount: 0,
  masterAccountDetails: [],
  selectedAccountIds: [],
  selectedTags: [],
  resourceDropdownOptions: [],
  csvData: [],
  selectAllInventorySearch: false,
  inActiveResource: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

function Inventory() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: 'SET_DATA', payload: data });
  };

  const {
    selectedValue,
    selectedServiceTypeValue,
    ByAccountResource,
    loading,
    ByRegion,
    accordionItems,
    activeTab,
    accountIdData,
    activeTitle,
    selectedResource,
    tagData,
    selectedTab,
    tagUntagCount,
    regionDropdownOptions,
    selectedRegion,
    topTenResouces,
    totalCountAccountDoughnut,
    vpcData,
    awsCount,
    awsFilterCount,
    masterAccountDetails,
    selectedAccountIds,
    selectedTags,
    resourceDropdownOptions,
    csvData,
    selectAllInventorySearch,
    inActiveResource,
  } = state;

  const handleDropdownChange = (event) => {
    setData({ selectedValue: event.target.innerText });
  };
  const handleServiceTypeDropdownChange = (event) => {
    setData({ selectedServiceTypeValue: event.target.innerText });
  };

  const getByAccount = () => {
    setData({ ByAccountResource: null });
    let body = {
      resourceType: selectedResource?.length > 0 ? selectedResource : null,
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      tagKey: null,
      tagValue: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: 10,
      isTag: selectedValue === inventoryDropdownValues.tags ? 'Tag' : null,
    };
    axios
      .post(API_URLS.infra.inventory.getTotalResourceCount, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          const labels = response?.data?.data.map((item) =>
            item.accountID.toString()
          );
          setData({ awsFilterCount: response.data.totalCount });
          setData({ totalCountAccountDoughnut: response?.data?.totalCount });
          const data = response?.data?.data.map((item) => item.count);
          const backgroundColor = graphColors;
          setData({
            ByAccountResource: {
              labels: labels,
              datasets: data,
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getByAccountForGettingCount = () => {
    setData({ ByAccountResource: null });
    let body = {
      resourceType: null,
      regions: null,
      tagKey: null,
      tagValue: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: 10,
      isTag: selectedValue === inventoryDropdownValues.tags ? 'Tag' : null,
    };
    axios
      .post(API_URLS.infra.inventory.getTotalResourceCount, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({ awsCount: response.data.totalCount });
          setData({ totalCountAccountDoughnut: response?.data?.totalCount });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data);
      });
  };

  const getByRegion = () => {
    setData({ ByRegion: null });
    let body = {
      resourceType: selectedResource?.length > 0 ? selectedResource : null,
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
      isTag: selectedValue === inventoryDropdownValues.tags ? 'Tag' : null,
    };
    axios
      .post(API_URLS.infra.inventory.getTotalResourceCountByRegion, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          const labels = response?.data?.data?.map((item) => item?.region);
          const data = response?.data?.data?.map((item) => item?.count);
          const backgroundColor = graphColors;
          setData({
            ByRegion: {
              labels: labels,
              datasets: data,
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data);
      });
  };

  const getByRegionWithoutFilter = () => {
    setData({ ByRegion: null });

    let body = {
      resourceType: null,
      regions: null,
      accountID: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
      isTag: selectedValue === inventoryDropdownValues.tags ? 'Tag' : null,
    };
    axios
      .post(API_URLS.infra.inventory.getTotalResourceCountByRegion, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({
            regionDropdownOptions: response?.data?.data.map((data, index) => {
              return {
                id: index + 1,
                value: data?.region,
                label: data?.region,
              };
            }),
          });
          const labels = response?.data?.data?.map((item) => item?.region);
          const data = response?.data?.data?.map((item) => item?.count);
          const backgroundColor = graphColors;
          setData({
            ByRegion: {
              labels: labels,
              datasets: data,
            },
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data);
      });
  };

  const getAllResources = () => {
    setData({ accordionItems: [] });
    let body = {
      resourceType: selectedResource?.length > 0 ? selectedResource : null,
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
    };
    axios
      .post(API_URLS.infra.inventory.getAllResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({ accordionItems: response?.data?.data });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getResourceTypeDropdownValues = () => {
    setData({ resourceDropdownOptions: [] });
    let body = {
      resourceType: null,
      regions: null,
      accountID: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
    };
    axios
      .post(API_URLS.infra.inventory.getAllResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({
            resourceDropdownOptions: response?.data?.data.map((data, index) => {
              return {
                id: index + 1,
                value: data?.resourceType,
                label: data?.resourceType?.replaceAll(':', ' '),
              };
            }),
          });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getTagResources = () => {
    let body = {
      resourceType: selectedResource?.length > 0 ? selectedResource : null,
      regions:
        selectedRegion?.length > 0 && !selectAllInventorySearch
          ? selectedRegion
          : null,
      accountDetails:
        selectedAccountIds?.length > 0 && !selectAllInventorySearch
          ? selectedAccountIds
          : null,
      limit: null,
      tagKey:
        selectedTags?.length > 0
          ? [
              ...new Set(
                selectedTags.map((data) => {
                  return data?.key;
                })
              ),
            ]
          : null,
      tagValue:
        selectedTags?.length > 0
          ? [
              ...new Set(
                selectedTags.map((data) => {
                  return data?.value;
                })
              ),
            ]
          : null,
    };
    setData({ accordionItems: [] });

    axios
      .post(API_URLS.infra.inventory.getTagResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          const accordianItem = response?.data?.data[0]?.tagsDTOS.map(
            (data) => {
              return {
                resourceType: `${data?.key}-${data?.value}`,
                resourceKey: data?.key,
                resourceValue: data?.value,
              };
            }
          );
          accordianItem.unshift({
            resourceType: 'Untagged Resources',
          });
          if (selectedValue === inventoryDropdownValues.tags) {
            setData({ accordionItems: accordianItem });
          }
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getTagResourcesWithoutFilter = () => {
    setData({ resourceDropdownOptions: [] });
    let body = {
      resourceType: null,
      regions: null,
      accountID: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
      tagKey: null,
      tagValue: null,
    };
    setData({ accordionItems: [] });
    axios
      .post(API_URLS.infra.inventory.getTagResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({ tagData: response?.data?.data[0].tagsDTOS });
          const resourceDropdownOptions = response?.data?.data[0].tagsDTOS.map(
            (data, index) => {
              const label = `${data?.key}-${data?.value}`;
              return {
                id: index + 1,
                value: data?.value,
                label: label?.length > 25 ? `${label.slice(0, 25)}...` : label,
              };
            }
          );
          const uniqueValues = {};
          const uniqueArray = [];

          resourceDropdownOptions.forEach((item) => {
            const value = item.value;

            if (!uniqueValues[value]) {
              uniqueValues[value] = true;
              uniqueArray.push(item);
            }
          });
          setData({ resourceDropdownOptions: uniqueArray });
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getTopTenResourceList = () => {
    setData({ topTenResouces: [] });
    let body = {
      resourceType: selectedResource?.length > 0 ? selectedResource : null,
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      tagKey: null,
      tagValue: null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: 10,
    };
    axios
      .post(API_URLS.infra.inventory.getTopTenResource, body)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setData({ topTenResouces: [...res?.data?.data] });
        } else {
          console.error(
            res?.data?.statusMessage,
            'error from getTopTenResourceList'
          );
        }
      })
      .catch((err) => {
        console.error(
          err?.res?.data?.statusMessage,
          'error from getTopTenResourceList'
        );
      });
  };

  const getVpcResources = () => {
    let body = {
      resourceType: ['AWS::EC2::VPC'],
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
    };
    setData({ accordionItems: [] });

    axios
      .post(API_URLS.infra.inventory.getVpcResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({ vpcData: response?.data });
          const accordianItem = response?.data?.data.map((data) => {
            return {
              resourceType: `${data?.arn.split('/')[1]}`,
            };
          });
          setData({ accordionItems: accordianItem });
        }
      })
      .catch((error) => {
        console.error(error.message, '<<<error from vpc api');
      });
  };

  const getCloudFormationResources = () => {
    let body = {
      resourceType: ['AWS::CloudFormation::Stack'],
      regions: selectedRegion?.length > 0 ? selectedRegion : null,
      accountDetails:
        selectedAccountIds?.length > 0 ? selectedAccountIds : null,
      limit: null,
    };
    setData({ accordionItems: [] });

    axios
      .post(API_URLS.infra.inventory.getCFResources, body)
      .then((response) => {
        if (response.data.statusCode === STATUSCODE.OK) {
          setData({ vpcData: response?.data });
          const accordianItem = response?.data?.data.map((data) => {
            return {
              resourceType: `${data?.arn.split('/')[1]}`,
            };
          });
          setData({ accordionItems: accordianItem });
        }
      })
      .catch((error) => {
        console.error(error.message, '<<<error from vpc api');
      });
  };

  const getAccountDetails = () => {
    axios
      .get(API_URLS.infra.getAccountDetails)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setData({
            masterAccountDetails: response?.data?.data
              .filter((data) => data.account_type === 'master')
              .map((data) => {
                return {
                  accountID: data?.account_Id,
                  arn: data?.role_Arn,
                };
              }),
          });
          setData({ accountIdData: response?.data?.data });
        } else {
          console.error(
            response?.data?.statusMessage,
            '<<<<<error from  accountDetailsApi'
          );
        }
      })
      .catch((error) =>
        console.error(
          error?.response?.data?.statusMessage,
          '<<<<<error from  accountDetailsApi'
        )
      );
  };

  const getTagUntagCount = () => {
    axios
      .get(API_URLS?.infra?.inventory?.getTagUntagCount)
      .then((response) => {
        const result = response?.data;
        if (result?.statusCode === 200) {
          setData({
            tagUntagCount: {
              tagged: result?.data[0]?.tagged,
              untagged: result?.data[0]?.unTagged,
            },
          });
        } else {
          console.error(
            response?.data?.statusMessage,
            '<<error from get tag untag count api'
          );
          setData({
            tagUntagCount: {
              tagged: 0,
              untagged: 0,
            },
          });
        }
      })
      .catch((error) => {
        console.error(
          error?.response?.data?.statusMessage,
          '<<error from get tag untag count api'
        );
        setData({
          tagUntagCount: {
            tagged: 0,
            untagged: 0,
          },
        });
      });
  };

  const onSelectResource = (data) => {
    if (selectedValue === inventoryDropdownValues.allResources) {
      setData({ selectedResource: data });
    } else {
      setData({
        selectedTags: tagData.filter((innerData) =>
          data.includes(innerData.value)
        ),
      });
    }
  };

  const onSelectRegion = (data) => {
    setData({ selectedRegion: data });
  };

  const inActiveResourceTable = async () => {
    setData({ loading: true });
    const InActiveResourcesPayload = {
      provider: providerText,
      account: rootAccount,
      region: regionText,
      service_type: selectedServiceTypeValue,
      status: status,
    };
    try {
      const response = await axios.post(
        API_URLS.infra.inventory.getInActiveResources,
        InActiveResourcesPayload
      );
      const ResponseDatadataEvents = response.data.data.Events;
      const ourTableData = ResponseDatadataEvents.map((data) => {
        return {
          ResourceId: data.Resources[0].ResourceName,
          ResourceType: data.Resources[0].ResourceType,
          Username: data.Username,
        };
      });
      setData({ inActiveResource: ourTableData });
    } catch (error) {
      console.error(error);
    } finally {
      setData({ loading: false });
    }
  };

  const customCSVDataDownloadButton = async () => {
    const customButtonDataInCSVPayload = {
      account: rootAccount,
      region: regionText,
    };
    try {
      const response = await axios.post(
        API_URLS.infra.inventory.getCustomButtonDataInCSV,
        customButtonDataInCSVPayload
      );
      const ResponseDatadata = response.data.data[0];

      const fetchTagValue = (data, value) => {
        let val = _.find(data, { Key: value });
        if (val !== undefined) {
          return val.Value;
        } else {
          return "";
        }
      };
      const fetchVolumeId = (data) => {
        let val = "";
        data.map((e) => {
          val = val + "[" + e?.VolumeId + "]";
        });
        return val;
      };
      const fetchVolumeSize = (data) => {
        let val = "";
        data.map((e) => {
          val = val + "[" + e?.Size + "]";
        });
        return val;
      };
      const fetchVolumeState = (data) => {
        let val = "";
        data.map((e) => {
          val = val + "[" + e?.State + "]";
        });
        return val;
      };

      let ec2Data = [];
      let rdsData = [];
      let s3Data = [];
      let csv = "";
      const jsonToCsv = (jsonData) => {
        let headers = Object.keys(jsonData[0]);
        csv += headers.join(",") + "\n";
        jsonData.forEach(function (row) {
          let data = headers
            .map((header) => JSON.stringify(row[header]))
            .join(",");
          csv += data + "\n";
        });
        return csv;
      };

      ResponseDatadata.ec2.map((data) => {
        ec2Data.push({
          "Instance Id": data.InstanceId,
          "Instance Type": data.InstanceType,
          "State": data.State.Name,
          PrivateIpAddress: data.PrivateIpAddress,
          PublicIpAddress: data.PublicIpAddress,
          OS: data.PlatformDetails,
          "Availability Zone": data.Placement.AvailabilityZone,
          "Launch Time": data.LaunchTime,
          "Key pair Name": data.KeyName,
          "Tag:Environment": fetchTagValue(data.Tags, "environment"),
          "Tag:Application Owner": fetchTagValue(data.Tags, "application-owner"),
          "Tag:Application Name": fetchTagValue(data.Tags,"Name"),
          "Tag:Email": fetchTagValue(data.Tags, "email"),
          "Tag:Spoc": fetchTagValue(data.Tags, "spoc"),
          "Tag:Map migrated": fetchTagValue(data.Tags, "map-migrated"),
          "Tag:State": fetchTagValue(data.Tags, "state"),
          "Volume Id": fetchVolumeId(data.Volumes,"VolumeId"),
          "Volume size": fetchVolumeSize(data.Volumes,"Size"),
          "Volume state": fetchVolumeState(data.Volumes,"State"),
          "VPC ID": data.VpcId,
          "Security Groups Id": data.SecurityGroups[0].GroupId,
          "Security Groups Name": data.SecurityGroups[0].GroupName,
          SubnetId: data.SubnetId,
        });
      });
      ec2Data.push({}, {}, {});
      jsonToCsv(ec2Data);
      setData({ csvData: csv });

      ResponseDatadata.rds.map((data) => {
        rdsData.push({
          "DB identifier": data.DBInstanceIdentifier,
          Engine: data.Engine,
          "Region & AZ": data.AvailabilityZone,
          Size: data.AllocatedStorage,
          Status: data.DBInstanceStatus,
          Endpoint: data.Endpoint?.Address,
          VPC: data.DBSubnetGroup?.VpcId,
          Port: data.Endpoint?.Port,
          "Security groups": data.VpcSecurityGroups[0]?.VpcSecurityGroupId,
          "Tag:Name": fetchTagValue(data.Tags, "Name"),
          "Tag:Environment": fetchTagValue(data.Tags, "environment"),
          "Tag:Application Owner": fetchTagValue(data.Tags, "application-owner"),
          "Tag:Costcenter": fetchTagValue(data.Tags, "Costcenter"),
          "Tag:Email": fetchTagValue(data.Tags, "email"),
          "Tag:Spoc": fetchTagValue(data.Tags, "spoc"),
          "Tag:Map migrated": fetchTagValue(data.Tags, "map-migrated"),
          "Tag:State": fetchTagValue(data.Tags, "state"),
        });
      });
      rdsData.push({}, {}, {});
      jsonToCsv(rdsData);
      setData({ csvData: csv });

      ResponseDatadata.s3.map((data) => {
        s3Data.push({
          "Bucket Name": data.Name,
          Region: regionText,
          "Date Created": data.CreationDate,
        });
      });
      s3Data.push({}, {}, {});
      jsonToCsv(s3Data);
      setData({ csvData: csv });
    } catch (error) {
      console.error(error);
    }
  }

  let downloadCsv = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "custom_data.csv";
    document.body.appendChild(a);
    a.click();
  };

  useEffect(() => {
    getAccountDetails();
    customCSVDataDownloadButton(); // Called on Custom button click
  }, []);

  useEffect(() => {
    if (selectedResource?.length > 0 && selectedResource?.length < 10) {
      const matchedAccountIDs = [];

      for (const item of accordionItems) {
        if (selectedResource.includes(item.resourceType)) {
          matchedAccountIDs.push({
            resourceType: item.resourceType,
            count: item.count,
          });
        }
      }
      setData({ topTenResouces: matchedAccountIDs });
    } else {
      getTopTenResourceList();
    }
  }, [
    selectedAccountIds,
    accordionItems,
    masterAccountDetails,
    selectedResource,
    selectedRegion,
  ]);

  useEffect(() => {
    if (masterAccountDetails?.length > 0) {
      getByAccountForGettingCount();
    }
    if (selectedValue !== inventoryDropdownValues.tags) {
      getResourceTypeDropdownValues();
    } else {
      getTagResourcesWithoutFilter();
    }
    getByRegionWithoutFilter();
  }, [masterAccountDetails, selectedValue]);

  useEffect(() => {
    if (masterAccountDetails?.length > 0) {
      if (selectedValue === inventoryDropdownValues.allResources) {
        getAllResources();
        // getTagResources();
      } else if (selectedValue === inventoryDropdownValues.tags) {
        getTagResources();
      } else if (selectedValue === inventoryDropdownValues.VPC) {
        //getTagResources();
        getVpcResources();
      } else if (selectedValue === inventoryDropdownValues.InActiveResources) {
        if (selectedServiceTypeValue) {
          inActiveResourceTable();
        }
      } else {
        //getTagResources();
        getCloudFormationResources();
      }
      getByAccount();
      getByRegion();
      getTagUntagCount();
    }
  }, [
    selectedValue,
    selectedAccountIds,
    masterAccountDetails,
    selectedResource,
    selectedTags,
    selectedRegion,
    selectedServiceTypeValue,
  ]);

  return (
    <div className="container-fluid inventoryPage">
      <div className="row">
        <Header />
        <div className="mx-3">
          <div className="col-md-6  pt-4">
            <p className="inventoryTab_cloudTxt">Clouds:</p>
            <div className="inventoryTab">
              <Tab.Container
                activeKey={activeTab}
                onSelect={(tab) => {
                  setData({ activeTab: tab });
                  tab === 'AWS'
                    ? setData({ activeTitle: 'Governance' })
                    : setData({ activeTitle: 'Resources' });
                }}
                id="left-tabs-example"
                defaultActiveKey="first"
              >
                <Nav variant="underline">
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setData({ selectedTab: 'aws' })}
                      eventKey="AWS"
                    >
                      <div className="w-max">
                        <img
                          src={awsIcon}
                          alt="AWS Icon"
                          className="inventoryTab_cardIcons "
                        />
                        AWS ({awsCount})
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setData({ selectedTab: 'azure' })}
                      eventKey="AZURE"
                    >
                      <div className="w-max ">
                        <img
                          src={azureIcon}
                          alt="Azure Icon"
                          className="inventoryTab_cardIcons"
                        />
                        Azure ({0})
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Tab.Container>
            </div>
          </div>
          <div>
            <div>
              <InventorySearch
                type={activeTitle}
                viewBy={selectedValue}
                getSelectAllValue={(data) =>
                  setData({ selectAllInventorySearch: data })
                }
                selectedValue={selectedValue}
                handleDropdownChangeViewAs={handleDropdownChange}
                selectedServiceTypeValue={selectedServiceTypeValue}
                handleServiceTypeDropdownChangeViewAs={
                  handleServiceTypeDropdownChange
                }
                getSelectedRegion={(data) => onSelectRegion(data)}
                resourceDropdownOptions={resourceDropdownOptions}
                regionDropdownOptions={regionDropdownOptions}
                accountDetails={accountIdData}
                getSelectedResources={(data) => onSelectResource(data)}
                getSelectedAccountIds={(data) => {
                  setData({ selectedAccountIds: data });
                }}
                btnAppendFun={downloadCsv}
              />
            </div>
            {selectedValue === inventoryDropdownValues.InActiveResources ? (
              <>
                {state.loading ? (
                  <div className="loaderDiv">
                    <Loader />
                  </div>
                ) : (
                  <CommonTable
                    columns={InActiveTableCols}
                    data={inActiveResource || []}
                    loading={false}
                  />
                )}
              </>
            ) : (
              <>
                <div className="d-flex gap-3 flex-wrap">
                  {selectedValue === inventoryDropdownValues.allResources && (
                    <div className="col-md-3 awsCardContainer">
                      <AwsCard third_data={awsFilterCount} title={activeTab} />
                      <SmallInventoryCard
                        third_data={tagUntagCount?.untagged}
                        secon_data={tagUntagCount?.tagged}
                        activeTitle={activeTitle}
                      />
                    </div>
                  )}
                  <div className={'col-md'}>
                    {ByAccountResource ? (
                      <InventoryCard
                        isDougnut
                        title="By Account "
                        displaycomponents={
                          <DonutChart
                            data={ByAccountResource.datasets}
                            labels={ByAccountResource.labels}
                            display={false}
                          />
                        }
                      />
                    ) : (
                      <p>
                        <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
                      </p>
                    )}
                  </div>
                  <div className={'col-md'}>
                    {ByRegion && (
                      <InventoryCard
                        title="By Region "
                        isDougnut
                        displaycomponents={
                          <DonutChart
                            data={ByRegion.datasets}
                            labels={ByRegion.labels}
                            display={false}
                          />
                        }
                      />
                    )}
                  </div>
                  {selectedValue === inventoryDropdownValues.allResources && (
                    <div className="col-md ">
                      {typeof topTenResouces == 'object' ? (
                        <InventoryCard
                          title={
                            selectedResource?.length === 1
                              ? selectedResource[0]?.replaceAll(':', ' ')
                              : selectedResource?.length < 10 &&
                                selectedResource?.length > 1
                              ? `${selectedResource?.length} Resource selected`
                              : 'Top 10 Resource Types'
                          }
                          displaycomponents={
                            topTenResouces?.length > 0 ? (
                              <div className="inventoryTab_top10ChartContainer pt-3">
                                <HorizontalBarChart
                                  labels={topTenResouces.map((data) => {
                                    return data?.resourceType;
                                  })}
                                  data={topTenResouces.map((data) => {
                                    return data?.count;
                                  })}
                                />
                              </div>
                            ) : null
                          }
                        />
                      ) : (
                        <p>
                          <i className="fa fa-spinner fa-spin fa-2x color-black"></i>
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="row m-0 mt-2">
                  <div>
                    <div
                      className="accordion accordion-flush shadow pt-4"
                      id="accordionParent"
                    >
                      <Accordion
                        vpcData={vpcData}
                        items={accordionItems}
                        masterAccountDetails={masterAccountDetails}
                        selectedAccountIds={selectedAccountIds}
                        selectedResource={selectedResource}
                        selectedRegion={selectedRegion}
                        selectedValue={selectedValue}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Inventory;
