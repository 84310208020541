import React, { useEffect, useReducer } from "react";
import "../../../src/style/Pages/CXO/dashboard.scss"; // Import the CSS file for styling
import CXOLineChart from "../../components/common/Charts/CXOLineChart";
import CXOHorizontalSingleBarChart from "../../components/common/Charts/CXOHorizontalSingleBarChart";
import {
  toastIds,
} from "../../constants/constant";
import axios from "../../api/axios";
import { API_URLS } from "../../constants/API_URLS";
import { ToastError } from "../../utils/utils";
import moment from "moment";
import LoaderGreyWhite from "../../components/LoaderGreyWhite";
import _ from 'lodash';

const initialState = {
  lineData: {},
  barData: {},
  lineInsightsData: [],
  LineChartInsightsLoading: false,
  lineChartLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const LineChartBarChart = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: 'SET_DATA', payload: data });
  };

  const {
    lineData,
    barData,
    lineInsightsData,
    LineChartInsightsLoading,
    lineChartLoading,
  } = state;

  const fetchLineChartInsights = async (value) => {
    setData({ LineChartInsightsLoading: true });
    try {
      await axios
        .post(API_URLS.CXO.getYearInsights, value)
        .then((response) => {
          let result = response?.data;
          if (result?.statusCode === 200) {
            const insightsString = [];
            result?.data[0]?.message?.map((item) => {
              if (item.length > 0)
                insightsString.push(item);
            })
            setData({ lineInsightsData: insightsString })
          } else {
            ToastError(result?.statusMessage, toastIds.errorToastId);
          }
        })
    } catch (err) {
      ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
    }
    finally {
      setData({ LineChartInsightsLoading: false });
    }
  };

  const fetchLineChart = async () => {
    setData({ lineChartLoading: true });
    try {
      await axios
        .get(API_URLS.CXO.getYearCost)
        .then((response) => {
          let result = response?.data;
          if (result?.statusCode === 200) {
            fetchLineChartInsights(response);
            let axisvalue = [];
            const MylineData = result?.data[0]?.resultsByTime.map((item) => {
              let lineSpend = JSON.parse(item?.total?.UnblendedCost?.amount).toFixed(2)
              let month = moment(item?.timePeriod?.start).format('MMM');
              axisvalue.push(`${month}-${moment(item?.timePeriod?.start).year()}`)
              return {
                name: month,
                value: lineSpend > 0 ? parseFloat(lineSpend) : 0,
                currency : item?.total?.UnblendedCost?.unit
              }
            })

            setData({ lineData: { data: MylineData, xAxisValue: axisvalue } })
            setData({ barData: { data: (_.chunk(MylineData, 9)[1]), yAxisValue: (_.chunk(axisvalue, 9)[1]) } })
          } else {
            ToastError(result?.statusMessage, toastIds.errorToastId);
          }
        })
    } catch (err) {
      ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
    }
    finally {
      setData({ lineChartLoading: false });
    }
  };

  useEffect(() => {
    fetchLineChart();
  }, [])

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 sixthlayer text-light ">
          <div className="container-fluid">
            <div className="d-flex flex-row flex-wrap my-4">
              <div className="col-lg-6 sixthlayer text-light">
                <div className="col-lg-12">
                  <div className="first-layer-blue px-3">Insights</div>
                  <div className="InsightTextData me-1">
                    {LineChartInsightsLoading ? (
                      <div className="loaderDiv">
                        <LoaderGreyWhite />
                      </div>
                    ) : (
                      lineInsightsData.map((item) => {
                        return (
                          <div className="fs-1 mt-2 mb-2 text-start first-layer-text">
                            <div className="px-3">
                              <ul className="serviceInsight">
                                <li>{item}</li>
                              </ul>
                            </div>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column gap-4 sixthlayer col-12 col-lg-6 text-dark">
                {lineChartLoading ? (
                  <div className="loaderDiv">
                    <LoaderGreyWhite />
                  </div>
                ) : (
                  <div className="bg-light myLineChart">
                    <CXOLineChart
                      data={lineData?.data}
                      xAxisData={lineData?.xAxisValue}
                    />
                  </div>
                )}

                {lineChartLoading ? (
                  <div className="loaderDiv">
                    <LoaderGreyWhite />
                  </div>
                ) : (
                  <div className="bg-light myBarChart">
                    <CXOHorizontalSingleBarChart
                      data={barData?.data}
                      yAxisData={barData?.yAxisValue}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChartBarChart;
