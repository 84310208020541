import React, { useEffect, useState } from 'react'

import SVG from "react-inlinesvg";
import { useDispatch } from 'react-redux';

import { useMediaQuery } from '@mui/material'

import FifthLayer from './audit'
import SecondLayer from './costSaving'
import FourthLayer from './operational'
import LineChartBarChart from './LineChartBarChart'
import FirstLayer from './hdbfsDashboard'
import ThirdLayer from './cloudLandscape'
import LastInsightsLayer from './lastInsights';
import ServicesInsightLayer from './servicesInsight'
import sidebarToggle from '../../Images/sidebarToggle.svg';
import { toggleSidebar } from '../../redux/Slices/OpenSidebar'

import '../../../src/style/Pages/CXO/dashboard.scss'

const CxoDashboard = () => {

  const matches = useMediaQuery('(min-width:768px)');
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const dispatch = useDispatch();
  const dummyData = {
    reduction: "$200",
    reduction_percent: "20%",
    reduction_per: "30%"
  }

  useEffect(() => {
    dispatch(toggleSidebar(isOpenSidebar));
  }, [isOpenSidebar])

  return (
    <div className='cxo-dashboard'>
      {
        !matches &&
        <button onClick={() => { setIsOpenSidebar(!isOpenSidebar) }} className="btn toggleBtnCxo shadow-none">
          <SVG src={sidebarToggle} className="toggleBtnCxo_toggleIcon" />
        </button>

      }
      <div className='container-fluid'>
        <div>
          <FirstLayer />
          <SecondLayer /> 
          <ThirdLayer />
          <LineChartBarChart />
          <ServicesInsightLayer />
          <LastInsightsLayer />
          <FourthLayer />
          <FifthLayer />
        </div>
      </div>
    </div>
  )
}

export default CxoDashboard
