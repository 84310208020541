import React, { useEffect, useReducer, useState } from "react";

import { Form } from "react-bootstrap";

import axios from "../../../../api/axios";
import CopyModal from "./copyModal/copyModal";
import ShareModal from "./shareModal/shareModal";
import axiosInstance from "../../../../api/axios";
import RenameModal from "./renameModal/renameModal";
import DeleteModal from "./deleteModal/deleteModal";
import editIcon from "../../../../Images/editIcon.svg";
import copyIcon from "../../../../Images/copyIcon.svg";
import shareIcon from "../../../../Images/shareIcon.svg";
import { toastIds } from "../../../../constants/constant";
import { API_URLS } from "../../../../constants/API_URLS";
import deleteIcon from "../../../../Images/deleteIcon.svg";
import CommonTooltip from "../../../../components/Tooltip";
import refreshIcon from "../../../../Images/restartIcon.svg";
import filterIcon from "../../../../Images/filterFilled.svg";
import CommonTable from "../../../../components/Infra/Table/table";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import editIconDisabled from "../../../../Images/editIconDisabled.svg";
import copyIconDisabled from "../../../../Images/copyIconDisabled.svg";
import shareIconDisabled from "../../../../Images/shareIconDisabled.svg";
import deleteIconDisabled from "../../../../Images/deleteIconDisabled.svg";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import TagSearchModal from "../../../../components/Infra/TagSearchModal/tagSearchModal";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";

import "../../../../style/Infra/images.scss";
const initialState = {
  open: false,
  isAdded: false,
  tableData: [],
  loading: false,
  shareImgShow: false,
  showOpenCopyImage: false,
  openRenameModal: false,
  openDeleteModal: false,
  selectedRowsTable: null,
  regionList: [],
  selectedRegion: "",
  currentPage: 1,
  filterCheckboxDisabled: false,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function Images() {
  const [state, dispatch] = useReducer(reducer, initialState);
  //share image module
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableData;
      let mergedData = [SearchObject, ...result];

      setData({ tableData: mergedData });
    }
  };
  const columns = [
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Image Name",
      selector: (row) => CommonTooltip(row.imageName),
    },
    {
      name: "ImageID",
      selector: (row) => CommonTooltip(row.imageId),
    },
    {
      name: "Name",
      selector: (row) => CommonTooltip(row.name),
    },
    {
      name: "Owner",
      selector: (row) => row.owner,
    },
    {
      name: "Owner Account",
      selector: (row) => row.ownerAccount,
    },
    {
      name: "Visibility",
      selector: (row) => row.visibility,
    },
    {
      name: "Project",
      selector: (row) => row.project,
    },
  ];
  const SearchObject = {
    status: (
      <TableColumnSearch
        column={"status"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    imageName: (
      <TableColumnSearch
        column={"imageName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    imageId: (
      <TableColumnSearch
        column={"imageId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    name: (
      <TableColumnSearch
        column={"name"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    owner: (
      <TableColumnSearch
        column={"owner"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    ownerAccount: (
      <TableColumnSearch
        column={"ownerAccount"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    visibility: (
      <TableColumnSearch
        column={"visibility"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    project: (
      <TableColumnSearch
        column={"project"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };
  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  const getImageData = (regionSelected) => {
    setData({ loading: true, tableData: [] });
    let body = {
      value: null,
      region: regionSelected,
    };
    axios
      .post(API_URLS.infra.compute.images.amiList, body)
      .then((response) => {
        let result = response?.data;
        if (result?.statusCode === 200) {
          let tableData = result?.data?.map((data) => {
            let temp = {
              ...data,
            };
            return temp;
          });
          setData({ tableData: tableData });
        } else {
          ToastError(result?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((err) => {
        ToastError(err?.response?.data?.statusMessage, toastIds.errorToastId);
      })
      .finally(() => {
        setData({ loading: false });
      });
  };

  const OnImageOperation = (data) => {
    axios
      .post(API_URLS.infra?.compute?.images?.imageOperation, data)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getImageData();
          setData({ selectedRowsTable: [] });
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  const getRegionList = () => {
    axiosInstance
      .get(API_URLS.infra.compute.images.regionList)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setData({
            regionList: response?.data?.data,
            selectedRegion: response?.data?.data[15],
          });
          getImageData(response?.data?.data[15]);
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  useEffect(() => {
    getRegionList();
  }, []);

  useEffect(() => {
    if (state.selectedRegion) {
      getImageData(state.selectedRegion);
    }
  }, [state.selectedRegion]);

  return (
    <>
      <TagSearchModal
        show={state.open}
        onHide={() => setData({ open: false })}
        getKeyValPairs={(data) => {}}
      />
      <ShareModal
        show={state.shareImgShow}
        onHide={() => setData({ shareImgShow: false })}
        selectedRows={state.selectedRowsTable?.selectedRows}
        onShare={(data) => OnImageOperation(data)}
      />
      <CopyModal
        show={state.showOpenCopyImage}
        onHide={() => setData({ showOpenCopyImage: false })}
        selectedRows={state.selectedRowsTable?.selectedRows}
        onCopy={(data) => OnImageOperation(data)}
      />
      <RenameModal
        show={state.openRenameModal}
        onHide={() => setData({ openRenameModal: false })}
        selectedRows={state.selectedRowsTable?.selectedRows}
        onRename={(data) => OnImageOperation(data)}
      />
      <DeleteModal
        show={state.openDeleteModal}
        onHide={() => setData({ openDeleteModal: false })}
        selectedRows={state.selectedRowsTable?.selectedRows}
        onDelete={(data) => OnImageOperation(data)}
      />
      <div className="images-container mx-4  mt-5">
        <div className="d-flex gap-3 flex-wrap align-items-center">
          <h5 className=" text-start ms-1 mb-1">Images</h5>
          <div className="images-container_regionDropdown">
            <Form.Select
              value={state.selectedRegion}
              onChange={(e) =>
                setData({
                  selectedRegion: e?.target?.value,
                  filterCheckboxDisabled: false,
                })
              }
              className="images-container_regionDropdown_regionBody"
              size="sm"
              aria-label="Default select example"
            >
              <option disabled>Select Region</option>
              {state.regionList.map((data) => (
                <option value={data}>{data}</option>
              ))}
            </Form.Select>
          </div>
        </div>
        <div className=" border rounded border border-0 mt-3  mx-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-wrap">
              {state.selectedRowsTable?.selectedCount === 1 ? (
                <>
                  <TableButton
                    icon={
                      <img
                        className="images-container_tableIcons"
                        src={shareIcon}
                        alt="shareIcon"
                        onClick={() =>
                          setData({ shareImgShow: !state.shareImgShow })
                        }
                      ></img>
                    }
                    title={"Share"}
                  />
                  <TableButton
                    icon={
                      <img
                        onClick={() =>
                          setData({
                            showOpenCopyImage: !state.showOpenCopyImage,
                          })
                        }
                        className="images-container_tableIcons"
                        src={copyIcon}
                        alt="copyIcon"
                      ></img>
                    }
                    title={"Copy"}
                  />
                  <TableButton
                    icon={
                      <img
                        className="images-container_tableIcons"
                        src={editIcon}
                        alt="editIcon"
                        onClick={() =>
                          setData({ openRenameModal: !state.openRenameModal })
                        }
                      ></img>
                    }
                    title={"Rename"}
                  />
                </>
              ) : (
                <>
                  <TableButton
                    icon={
                      <img
                        className="images-container_tableIcons"
                        src={shareIconDisabled}
                        alt="shareIcon"
                      ></img>
                    }
                    title={"Share"}
                  />
                  <TableButton
                    icon={
                      <img
                        className="images-container_tableIcons"
                        src={copyIconDisabled}
                        alt="copyIcon"
                      ></img>
                    }
                    title={"Copy"}
                  />
                  <TableButton
                    icon={
                      <img
                        className="images-container_tableIcons"
                        src={editIconDisabled}
                        alt="editIcon"
                      ></img>
                    }
                    title={"Rename"}
                  />
                </>
              )}
              {state.selectedRowsTable?.selectedCount > 0 ? (
                <TableButton
                  icon={
                    <img
                      className="images-container_tableIcons"
                      src={deleteIcon}
                      onClick={() =>
                        setData({ openDeleteModal: !state.openDeleteModal })
                      }
                      alt="deleteIcon"
                    ></img>
                  }
                  title={"Delete"}
                />
              ) : (
                <TableButton
                  icon={
                    <img
                      className="images-container_tableIcons"
                      src={deleteIconDisabled}
                      alt="deleteIcon"
                    ></img>
                  }
                  title={"Delete"}
                />
              )}
            </div>
            <div className="d-flex flex-wrap">
              <TableButton
                icon={
                  <img
                    onClick={() => OnFilterClick()}
                    className="images-container_tableIcons"
                    src={filterIcon}
                    alt="filterIcon"
                  ></img>
                }
                title={"Filter"}
              />
              <TableButton
                icon={
                  <img
                    className="images-container_tableIcons"
                    src={refreshIcon}
                    alt="refreshIcon"
                    onClick={() => getImageData(state.selectedRegion)}
                  ></img>
                }
                title={"Refresh"}
              />
            </div>
          </div>
          <CommonTable
            removeSearchCheckbox={state.filterCheckboxDisabled}
            onChangePage={(data) => {
              setData({ resetPagination: false, currentPage: data });
            }}
            resetPagination={state.resetPagination}
            columns={columns}
            onSelectedRowsChange={(data) =>
              setData({ selectedRowsTable: data })
            }
            data={state.tableData}
            selectableRows={true}
            loading={state.loading}
          />
        </div>
      </div>
    </>
  );
}
