const Colors = {
  PINK: "#EA5F82",
  WHITE: "#fff",
  LIGHTGRAY: "#e6e6e6",
  LAVENDER: "#e8e9f8",
  DISABLEDLABEL: "#abb2b9",
  ALLBLACK: "#000000",
  BLACK: "#2E2E2E",
  BLUE: "#5e34f5",
  TABCOLOR: "#aed6f1",
  DROPDOWN: "#ced4da",
  HEADERBORDER: "#ff7f50",
  TABLETOPCONTENT: "#fafafa",
  DARKBLUE: "#1f0f7b",
  LIGHTBLUE: "#5175a6",
  SKYBLUE: "#335ccb",
  NAVYBLUE: "#253a71",
  LIGHTGREY: "#f1f3f5",
  RED: "red",
  GREY: "grey",
  LIGHTERBLUE: "#C9E1FF",
  LIGHTGREY2: "#dee2e6",
  DARKGREEN: "#00b219",
  DARKGREY: "#707070",
  DOUGHNUTBORDER: "#a2cfd3",
  DOUGHNUTBORDERMANGO: "#fad99f",
  DOUGHNUTBORDERDARKCYAN: "#9eb7cc",
  TABBACKGROUND: "#f4f6f6",
  SERVERBARCOLORONE: "#335ccb",
  SERVERBARCOLORTWO: "#798892",
  TABLEHEADERBUTTONBG: "#c7c4c4",
  BARBACKGROUND: "#66bcfd",
  ACTIVATEONPREMDOWNLOADBUTTON: "#51c7a1",
  YELLOW: "#fce300",
  YELLOWORANGE: "#ff8f00",
  PRINCETONORANGE: "#ff832e",
  OLIVEGREEN: "#1b5e20",
  DARKGREYTEXT: "#555",
  BLUETEXT: "#1c84c6",
  GREENTEXT: "#0f9d58",
  BOXSHADOWCOLOR: "rgba(0, 0, 0, 0.1)",
  DUSTGRAY: "#8D8989",
  CORALRED: "rgba(255, 99, 132, 1)",
  TRANSPARENTSKYBLUE: "rgba(54, 162, 235, 1)",
  DARKCHARCOAL: "rgba(0, 0, 0, 0.8)",
  TRANSPARENT: "rgba(255, 255, 255, 0)",
  TRASCULENTCHARCOAL: "rgba(0, 0, 0, 0.5)",
  LIGHTBLUE: "#F6F8FC",
  SLATEGRAY: "#8C8D8E",
  STEELBLUEGRAY: "#6E7079",
  GUNMETALGRAY: "#4C5058",
  LIGHTSKYBLUE: "#87CEFA",
  LIMEGREEN: "#32CD32",
  CORNFLOWERBLUE: "#6495ED",
  HOTPINK: "#FF69B4",
  MEDIUMORCHID: "#BA55D3",
  DARKSKYBLUE: "#2F93C8",
  PASTELGREEN: "#AEC48F",
  MUSTARDYELLOW: "#FFDB5C",
  CORAL: "#F98862",
  LAVENDERBLUE: "#8884D8",
  GOLDENROD: "#FFAC1C",
  SAFETYORANGE: "#FF6F00",
  TRANSLUCENTWHITE: "rgba(255, 255, 255, 0.9)",
  ROYALBLUE: "#0076E1",
  CORALPINK: "#EA5F82",
  PEACH: "#FFA384",
  MIDNIGHTBLUE: "#253A71",
  ORANGE: "#FFA500",
  LIMEGREEN: "#00FF00",
  BRIGHTORANGE: "#FF9E05",
  GREYCOLOR: "#555",
  MAINBLUE: "#253A71",
  VERTICALCOLOR: "#0A6CE9",
  CIRCLECOLOR: "#F3963E",
};

export default Colors;
