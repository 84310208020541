import React, { useReducer } from "react";

import { Button, Modal } from "react-bootstrap";

import { Switch } from "@mui/material";

import { ErrorMessage, commonConstants, regex } from "../../../../../constants/constant";

import "../../../../../style/Common.scss";
import AddTag from "../../../../../components/Infra/AddTag/addTag";

const initialState = {
  openTagModal: false,
  name: "",
  nameError: "",
  description: "",
  tagKeyPair: "",
  rebootInstance: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const CreateImageModal = (props) => {
  const initialState = {
    openTagModal: false,
    name: "",
    nameError: "",
    description: "",
    tagKeyPair: [],
    rebootInstance: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_DATA":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const handleNameChange = (val) => {
    if (regex.imageNameRegex.test(val)) {
      setData({ name: val, nameError: "" });
    } else {
      setData({ name: val, nameError: ErrorMessage.invalidVolumeCreateImageName });
    }
  };

  const onCreate = () => {
    if (!state.nameError) {
      const mergedObject = state.tagKeyPair.reduce((result, currentObject) => {
        return { ...result, ...currentObject };
      }, {});

      const postBodyServer = props.selectedRow.map((data) => {
        return {
          operation: commonConstants.createImage,
          description: state.description,
          accountId: data?.instanceData?.accountId,
          instanceId: data?.instanceData?.instanceId,
          tagsName:
            Object.entries(mergedObject).length > 0 ? mergedObject : null,
          imageName: state?.name,
          rebootInstance: state.rebootInstance,
          changeInstanceType: null,
        };
      });

      props.getImageBodyServer(postBodyServer);
      props.onHide();
    }
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  return (
    <>
      <AddTag
        show={state.openTagModal}
        onHide={() => setData({ openTagModal: false })}
        getKeyValPairs={(data) => {
          let temp = [];
          data?.map((item) => {
            temp.push({ [item.key]: item.value });
          });
          setData({ tagKeyPair: temp });
        }}
      />
      <Modal {...props} centered aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Create Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <p className="mb-1">
            Name <span className="requiredFieldStar">*</span>
          </p>
          <input
            className="w-100 mb-2"
            value={state.name}
            onChange={(e) => handleNameChange(e.target.value)}
            placeholder="Enter Name"
          ></input>
          {state.nameError && <p className="errorMessage">{state.nameError}</p>}
          <p className="mb-1">Description</p>
          <textarea
            className="w-100 mb-2"
            value={state.description}
            onChange={(e) => setData({ description: e.target.value })}
            placeholder="Enter Description"
          ></textarea>
          <p>Tag Target Images</p>
          <Button
            className="mb-2"
            onClick={() => setData({ openTagModal: !state.openTagModal })}
          >
            Add Tags
          </Button>
          <p className="mb-0">Reboot instance after image is created?</p>
          <div className="d-flex flex-wrap align-items-center">
            <p className="mb-0">No</p>
            <Switch
              {...label}
              onChange={(e) => setData({ rebootInstance: e.target.checked })}
            />
            <p className="mb-0">Yes</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button
            disabled={!state.name || state.nameError}
            onClick={() => onCreate()}
          >
            Create Image
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateImageModal;
