import React from "react";

export const CustomYAxisTick = ({ currency, payload, x, y }) => {
  const formatValue = (value) => {
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"]; // Extend as needed
      const magnitude =  Math.floor(Math.log10(value) / 3);
      return `${value / 10 ** (magnitude * 3)}${suffixes[magnitude]}`;
    }
    return value;
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} textAnchor="end" fill="#666">
        {currency}
        {formatValue(payload.value)}
      </text>
    </g>
  );
};
