import React from "react";

import { Tab, Tabs } from "react-bootstrap";

import { FaPlay } from "react-icons/fa";
import { FaStop } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";

import Details from "./details";
import Monitoring from "./monitoring";
import RemoteSessions from "./remoteSession";
import chipIcon from "../../../../../Images/chipIcon.svg";
import { localStorageHeaders } from "../../../../../constants/constant";
import { useNavigate } from "react-router-dom/dist";
import "../../../../../style/Infra/serverDetails.scss";
import TableButton from "../../../../../components/common/TableHeader/tablebutton";
import { ROUTE_PATH } from "../../../../../constants/Routes";
export default function ServerDeatils() {
  const navigate = useNavigate();
  const serverDetails = JSON.parse(
    localStorage.getItem(localStorageHeaders.serverDetails)
  );
  return (
    <div className="container serverDetails">
      <div className="d-flex justify-content-between pt-3">
        <div className="d-flex flex-wrap ">
          <label
            className="server_style"
            onClick={() => navigate(ROUTE_PATH?.Infra?.compute?.Servers)}
          >
            server
          </label>
          <span className="server_name">
            / <img src={chipIcon} alt="chip_icon"></img>{" "}
            {serverDetails?.instanceName}
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-around border pt-4">
        <div className="">
          <label className="titles">Name</label>
          <p className="subdata">{serverDetails?.instanceName || "-"}</p>
        </div>
        <div className="">
          <label className="titles">Instance Id</label>
          <p className="subdata">{serverDetails?.instanceId || "-"}</p>
        </div>
        <div className="">
          <label className="titles">Operating System</label>
          <p className="subdata">{serverDetails?.operatingSystem || "-"}</p>
        </div>
        <div className="">
          <label className="titles">Account Name</label>
          <p className="subdata">{serverDetails?.accountName || "-"}</p>
        </div>
        <div className="">
          <label className="titles">Region Name</label>
          <p className="subdata">{serverDetails?.regionName || "-"}</p>
        </div>
      </div>
      <div className="mt-4">
        <Tabs variant="underline" defaultActiveKey="Instances" className="mb-3">
          <Tab eventKey="Instances" title="Details" className="tab">
            <Details data={serverDetails} />
          </Tab>
          <Tab eventKey="Monitoring" title="Monitoring" className="tab">
            <Monitoring
              instanceId={serverDetails?.instanceId}
              accountName={serverDetails?.accountName}
              accountId={serverDetails?.accountId}
            />
          </Tab>
          <Tab
            eventKey="RemoteSessions"
            title="Remote Sessions"
            className="tab"
          >
            <RemoteSessions
              instanceId={serverDetails?.instanceId}
              accountId={serverDetails?.accountId}
              roleArn={serverDetails?.roleArn}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
