import { useEffect, useReducer, useState } from "react";

import { Badge, Button } from "react-bootstrap";

import { HiTag } from "react-icons/hi";

import VolumeModal from "./volumeModal";
import axios from "../../../../api/axios";
import DeleteVolumeModal from "./deleteVolumeModal";
import modifyIcon from "../../../../Images/note.svg";
import searchIcon from "../../../../Images/search.svg";
import cameraIcon from "../../../../Images/camera.svg";
import CreateSnapshotModal from "./createSnapshotModal";
import refreshIcon from "../../../../Images/refresh.svg";
import { API_URLS } from "../../../../constants/API_URLS";
import { toastIds } from "../../../../constants/constant";
import CommonTooltip from "../../../../components/Tooltip";
import filterIcon from "../../../../Images/filterFilled.svg";
import trashCanIcon from "../../../../Images/deleteIcon.svg";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import CommonTable from "../../../../components/Infra/Table/table";
import modifyIconDisabled from "../../../../Images/noteDisabled.svg";
import cameraIconDisabled from "../../../../Images/cameraDisabled.svg";
import Modal from "../../../../components/common/Modal/InfraModal/Modal";
import trashCanIconDisabled from "../../../../Images/deleteIconDisabled.svg";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import TagSearchModal from "../../../../components/Infra/TagSearchModal/tagSearchModal";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";

import "../../../../style/Infra/storage.scss";
import "../../../../style/Infra/volumemodal.scss";
export const initialState = {
  isModalOpenModify: false,
  openTagSearchModal: false,
  openCreateSnapshotModal: false,
  openDeleteModal: false,
  isAdded: false,
  tableData: [],
  tableDataRaw: [],
  selectedRow: [],
  loading: false,
  isSingleSelect: true,
  selectedVolumeId: "",
  modifyVolumeSelectedRow: null,
  filterCheckboxDisabled: false,
  currentPage: 1,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function Volumes() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };

  const closeModal = () => {
    setData({ isModalOpenModify: false });
  };

  const MODIFY = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFF",
    zIndex: 1000,
    width: "45%",
    height: "auto",
  };

  const onClickModify = (volumeId, row) => {
    setData({
      isModalOpenModify: !state.isModalOpenModify,
      selectedVolumeId: volumeId,
      isSingleSelect: true,
      modifyVolumeSelectedRow: row,
    });
  };

  const ModifyBtn = (props) => {
    return (
      <div className="volume">
        <Button
          onClick={() => {
            onClickModify(props.volumeId, props.row);
          }}
          className="btn_style"
        >
          <HiTag /> modify
        </Button>
      </div>
    );
  };

  const columns = [
    {
      name: "Volume ID",
      selector: (row) => CommonTooltip(row.volumeId),
       
    },
    {
      name: "Volume Name",
      selector: (row) =>CommonTooltip(row.volumeName) ,
       
    },
    {
      name: "Instance ID",
      selector: (row) => CommonTooltip(row.instanceId),
       
    },
    {
      name: "Volume Size",
      selector: (row) => row.volumeSize,
       
    },
    {
      name: "Volume Type",
      selector: (row) => row.volumeType,
       
    },
    {
      name: "State",
      selector: (row) => row.state,
       
    },
    {
      name: "Account Name ",
      selector: (row) => row.accountName,
       
    },
    {
      name: "Availablity Zone",
      selector: (row) => row.availabilityZone,
       
    },
  ];

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data?.[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableDataRaw.map((data) => {
        let newItem = {
          volumeId: data?.volumeId || "",
          volumeName: data?.volumeName || "",
          instanceId: data?.instanceId || "",
          volumeSize: data?.volumeSize || "",
          volumeType: (
            <div className="d-flex flex-wrap gap-1">
              <p className="mb-0">{data.volumeType}</p>
              <ModifyBtn row={data} volumeId={data?.volumeId} />
            </div>
          ),
          state: data?.state || "",
          accountName: data?.accountName || "",
          regionCode: data?.regionCode || "",
          regionName: data?.regionName || "",
          project: data?.project || "",
          environment: data?.environment || "",
          availabilityZone: data?.availabilityZone || "",
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    volumeId: (
      <TableColumnSearch
        column={"volumeId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeName: (
      <TableColumnSearch
        column={"volumeName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    instanceId: (
      <TableColumnSearch
        column={"instanceId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeSize: (
      <TableColumnSearch
        column={"volumeSize"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeType: (
      <TableColumnSearch
        column={"volumeType"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    state: (
      <TableColumnSearch
        column={"state"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountName: (
      <TableColumnSearch
        column={"accountName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionCode: (
      <TableColumnSearch
        column={"regionCode"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionName: (
      <TableColumnSearch
        column={"regionName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    project: (
      <TableColumnSearch
        column={"project"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    environment: (
      <TableColumnSearch
        column={"environment"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    availabilityZone: (
      <TableColumnSearch
        column={"availabilityZone"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  const getVolumeTableData = (searchValue) => {
    setData({ loading: true, tableData: [] });
    let url = API_URLS.infra.storage.volumes.getVolumeTable;
    if (searchValue) {
      url += `?volumeId=${searchValue}`;
    }
    axios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setData({ tableDataRaw: response?.data?.data[0] });
          const result = response?.data?.data[0].map((data) => {
            let newItem = {
              volumeId: data?.volumeId,
              volumeName: data?.volumeName || "",
              instanceId: data?.instanceId || "",
              volumeSize: data?.volumeSize || "",
              volumeType: (
                <div className="d-flex flex-wrap gap-1">
                  <p className="mb-0">{data.volumeType}</p>
                  <ModifyBtn row={data} volumeId={data?.volumeId} />
                </div>
              ),
              state: data?.state || "",
              accountName: data?.accountName || "",
              regionCode: data?.regionCode || "",
              regionName: data?.regionName || "",
              project: data?.project || "",
              environment: data?.environment || "",
              availabilityZone: data?.availabilityZone || "",
            };
            return newItem;
          });
          setData({ tableData: result });
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      )
      .finally(() => setData({ loading: false }));
  };

  const onDelete = (value) => {
    let postBody = state.selectedRow?.map((item) => {
      let temp = {
        volumeId: item?.volumeId,
        volumeType: null,
        description: null,
        createSnapshot: true,
        operation: "delete",
        key: null,
        value: null,
        accountId: item?.accountId,
      };
      return temp;
    });
    axios
      .post(API_URLS.infra.storage.volumes.volumeOperation, postBody)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getVolumeTableData();
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) => {
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId);
      });
  };

  const OnVolumeOperation = (data) => {
    axios
      .post(API_URLS?.storage.volumes.volumeOperation, data)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getVolumeTableData();
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  useEffect(() => {
    getVolumeTableData();
  }, []);

  useEffect(() => {
    const selectedVolumeIds = state.selectedRowsTable?.selectedRows.map(
      (data) => {
        return data.volumeId;
      }
    );
    setData({
      selectedRow: state.tableDataRaw.filter((data) =>
        selectedVolumeIds.includes(data?.volumeId)
      ),
    });
  }, [state.selectedRowsTable]);

  return (
    <>
      <Modal
        open={state.isModalOpenModify}
        closeOnBackgroundClick={true}
        portalId="portal"
        styles={MODIFY}
      >
        <VolumeModal
          closeModal={closeModal}
          volume={
            state.isSingleSelect
              ? [state.selectedVolumeId]
              : state.selectedRowsTable?.selectedRows?.length > 0 &&
                state.selectedRowsTable?.selectedRows.map((data) => {
                  return data?.volumeId;
                })
          }
          selectedRows={state.selectedRow}
          singleVolume={state.modifyVolumeSelectedRow}
          onModifyClick={(data) => OnVolumeOperation(data)}
        />
      </Modal>
      <CreateSnapshotModal
        show={state.openCreateSnapshotModal}
        onHide={() => setData({ openCreateSnapshotModal: false })}
        selectedVolumeDetails={state.selectedRow}
        getCreateBody={(data) => OnVolumeOperation(data)}
      />
      <DeleteVolumeModal
        show={state.openDeleteModal}
        onHide={() => setData({ openDeleteModal: false })}
        selectedRows={state.selectedRow}
        onDelete={(data) => OnVolumeOperation(data)}
      />
      <TagSearchModal
        type="Volume Id"
        show={state.openTagSearchModal}
        onHide={() => {
          setData({ openTagSearchModal: false });
        }}
        getKeyValPairs={(data) => {
          getVolumeTableData(data);
        }}
      ></TagSearchModal>

      <div className="commmon_stye infra_container mt-3">
        <h5 className=" text-start ms-1">Volumes</h5>

        <div className="container_style">
          <div className="  rounded  mt-3  mx-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap">
                {state.selectedRowsTable?.selectedCount > 0 ? (
                  <TableButton
                    icon={
                      <img
                        onClick={() =>
                          setData({
                            openCreateSnapshotModal:
                              !state.openCreateSnapshotModal,
                          })
                        }
                        className="storageHeader_tableIcons"
                        src={cameraIcon}
                        alt="restartIcon"
                      ></img>
                    }
                    title={"Create Snapshot"}
                  />
                ) : (
                  <TableButton
                    icon={
                      <img
                        className="storageHeader_tableIcons"
                        src={cameraIconDisabled}
                        alt="restartIcon"
                      ></img>
                    }
                    title={"Create Snapshot"}
                  />
                )}
                {state.selectedRowsTable?.selectedCount > 0 ? (
                  <TableButton
                    icon={
                      <img
                        className="storageHeader_tableIcons"
                        src={modifyIcon}
                        alt="restartIcon"
                        onClick={() => {
                          setData({
                            isSingleSelect: false,
                            isModalOpenModify: !state.isModalOpenModify,
                          });
                        }}
                      ></img>
                    }
                    title={"Modify Volume Type"}
                  />
                ) : (
                  <TableButton
                    icon={
                      <img
                        className="storageHeader_tableIcons"
                        src={modifyIconDisabled}
                        alt="restartIcon"
                      ></img>
                    }
                    title={"Modify Volume Type"}
                  />
                )}
                {state.selectedRowsTable?.selectedCount > 0 ? (
                  <TableButton
                    icon={
                      <img
                        className="storageHeader_tableIcons"
                        src={trashCanIcon}
                        alt="restartIcon"
                        onClick={() =>
                          setData({ openDeleteModal: !state.openDeleteModal })
                        }
                      ></img>
                    }
                    title={"Delete Volume"}
                  />
                ) : (
                  <TableButton
                    icon={
                      <img
                        className="storageHeader_tableIcons"
                        src={trashCanIconDisabled}
                        alt="restartIcon"
                      ></img>
                    }
                    title={"Delete Volume"}
                  />
                )}
                {state.selectedRowsTable?.selectedCount > 0 && (
                  <div className="d-flex align-items-center">
                    <Badge bg="secondary" pill>
                      {state.selectedRowsTable?.selectedCount} Selected
                    </Badge>
                  </div>
                )}
              </div>
              <div className="d-flex flex-wrap">
                <TableButton
                  icon={
                    <img
                      className="storageHeader_tableIcons"
                      src={searchIcon}
                      alt="restartIcon"
                      onClick={() =>
                        setData({
                          openTagSearchModal: !state.openTagSearchModal,
                        })
                      }
                    ></img>
                  }
                  title={" Tag Search"}
                />
                <TableButton
                  icon={
                    <img
                      className="storageHeader_tableIcons"
                      src={filterIcon}
                      alt="restartIcon"
                      onClick={() => OnFilterClick()}
                    ></img>
                  }
                  title={"Filter"}
                />
                <TableButton
                  icon={
                    <img
                      className="storageHeader_tableIcons"
                      src={refreshIcon}
                      alt="restartIcon"
                      onClick={() => getVolumeTableData()}
                    ></img>
                  }
                  title={"Refresh"}
                />
              </div>
            </div>

            <CommonTable
              removeSearchCheckbox={state.filterCheckboxDisabled}
              onChangePage={(data) => {
                setData({ resetPagination: false, currentPage: data });
              }}
              resetPagination={state.resetPagination}
              columns={columns}
              onSelectedRowsChange={(data) =>
                setData({ selectedRowsTable: data })
              }
              data={state.tableData}
              selectableRows={true}
              loading={state.loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
