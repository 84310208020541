import React, { useEffect, useRef } from 'react';

import * as echarts from 'echarts';

import Colors from '../../../../constants/Colors';
import '../../../../style/Pages/CXO/CXOLineChart.scss';

const CXOLineChart = ({ data, xAxisData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData, // Names for the x-axis
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: '{value}', // Displays Months on the x-axis
          color: Colors.ALLBLACK,
        },
        axisLine: {
          lineStyle: {
            color: Colors.LAVENDER,
            opacity: 0.05,
          },
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: function (index, value) {
            // Hide the first label, assuming index starts from 0
            return index === 0 ? false : true;
          },
          formatter: function (value, index) {
            // Hide the first label, assuming index starts from 0
            return value === 0 ? '' : value.toLocaleString(); // Add commas to the numbers;
          }, // Display cost on the y-axis
          interval: 2,
          color: Colors.ALLBLACK,
        },
      },
      series: [
        {
          type: 'line',
          data: data,
          itemStyle: {
            color: Colors.PRINCETONORANGE,
          },
          label: {
            show: true,
            position: 'top',
            textStyle: {
              fontWeight: 'bold',
              fontSize: 10,
            },
            formatter: function (params) {
              return params.value.toLocaleString(); // This Adds commas to the numbers
            }, // Displays only cost in the labels
          },
          showSymbol: true,
          showAllSymbol: true,
          symbol: '', // This is the symbol shown at the nodes of the y-axis values of the Line Chart
          symbolSize: 0,
        },
      ],
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        valueFormatter : (value, index) =>{
          return `${value} ${data[index]?.currency}`
        },
      },
      grid: {
        top: '14%',
        bottom: '6%',
        left: '3%',
        right: '3.5%',
        containLabel: true,
      },
    };

    myChart.setOption(option);

    const resizeListener = () => {
      myChart.resize();
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
      myChart.dispose();
    };
  }, [data, xAxisData]);

  return <div ref={chartRef}
    className='w-100 h-100 overflow-auto'
  ></div>;
};

export default CXOLineChart;
