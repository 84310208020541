import React from "react";
import '../../../style/Infra/storage.scss'
import { Outlet } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import SubHeader from "../../header/SubHeader";
import Header from "../../header/Header";

export default function Storage() {

  const SUB_NAV_ITEMS = [
    {
      Title: "Volume",
      path: "/storage/volumes",
    },
    {
      Title: "Snapshots",
      path: "/storage/snapshots",
    },
    {
      Title: "S3",
      path: "/storage/s3",
    },
  ]
  return (
    <>
      <Header />
      <SubHeader SUB_NAV_ITEMS={SUB_NAV_ITEMS} />
      <Outlet />
    </>
    // <div>
    //   {/* <Header /> */}
    //   <div className="mb-3 storageHeader">
    //     <nav className="nav">
    //       <Button className={`nav-link ${activeItem === 'Volume' ? 'active' : ''}`}
    //         aria-current="page"
    //         onClick={() => handleItemClick('Volume')}
    //       >
    //         Volume
    //       </Button>
    //       <Button className={`nav-link ${activeItem === 'Snapshots' ? 'active' : ''}`}
    //         onClick={() => handleItemClick('Snapshots')}
    //       >Snapshots</Button>
    //       <Button className={`nav-link ${activeItem === 'S3' ? 'active' : ''}`}
    //         onClick={() => handleItemClick('S3')}
    //       >S3</Button>
    //     </nav>
    //   </div>
    //   <Outlet />
    // </div>
  )
}



