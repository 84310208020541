import React, { useEffect, useState } from 'react';

import SVG from "react-inlinesvg";
import { toast } from 'react-toastify';

import axios from '../../api/axios';
import Loader from '../../components/Loader';
import { API_URLS } from '../../constants/API_URLS';
import { getOnlyTwoDigitsAfterDecimal } from '../../utils/utils';
import insightsIndicator from '../../Images/insightsIndicator.svg';
import ServicesTable from '../../components/common/Table/CXOServicesTable/table';
import { ErrorMessage, lastInsightsTableHead, subHeadersLastInsights } from '../../constants/constant';

import '../../../src/style/Pages/CXO/dashboard.scss';

const LastInsightsLayer = () => {
  const [insightsData, setInsightsData] = useState({ tableData: [], tableCols: [], insightsKey: [] });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAccountCost();
  }, [])


  const getAccountCost = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URLS?.CXO?.accountCost);
      getInsights(response);
      const data = response?.data?.data[0]?.yearlyCostResult[0];
      if (!data) {
        return;
      }
      const tableColumns = [
        ...lastInsightsTableHead,
        ...Object.keys(data.months).map((data) => ({
          text: data,
          dataKey: data,
          colSpan: 2,
          subHeaders: subHeadersLastInsights
        }))
      ];
      const tableData = response?.data?.data[0]?.yearlyCostResult?.map(data => {
        const monthsData = Object.entries(data?.months).reduce((acc, [key, value]) => {
          acc[key] = {
            cost: `$${getOnlyTwoDigitsAfterDecimal(value?.cost)}`,
            percentDiff: (<>
              <span>
                <SVG src={insightsIndicator} fill={getOnlyTwoDigitsAfterDecimal(value?.percentDiff) > 0 ? 'red' : 'green'} width={'13px'} height={'13px'}></SVG>
              </span>
              {getOnlyTwoDigitsAfterDecimal(value?.percentDiff)}%
            </>)
          };
          return acc;
        }, {});

        return {
          accountId: data?.accountId,
          accountName: data?.accountName,
          linkedAccountTotal: getOnlyTwoDigitsAfterDecimal(data?.linkedAccountTotal),
          ...monthsData
        };
      });
      const totalCostCol = {
        accountId: ' ',
        accountName: 'Total Cost',
        linkedAccountTotal: getOnlyTwoDigitsAfterDecimal(response?.data?.data[0]?.totalCost),
      }
      let lastColMonth = {}
      Object.entries(response?.data?.data[0]?.monthTotalCost).forEach(([key, value], index) => {
        const monthCostPercentage = Object.values(response?.data?.data[0]?.monthCostPercentage)[index];
        lastColMonth[key] = {
          cost: `$${getOnlyTwoDigitsAfterDecimal(value)}`,
          percentDiff: (<>
            <span>
              <SVG src={insightsIndicator} fill={getOnlyTwoDigitsAfterDecimal(monthCostPercentage) > 0 ? 'red' : 'green'} width={'13px'} height={'13px'}></SVG>
            </span>
            {getOnlyTwoDigitsAfterDecimal(monthCostPercentage)}%
          </>)
        }
      })
      lastColMonth = { ...lastColMonth, ...totalCostCol }
      tableData.push(lastColMonth);
      setInsightsData(prevState => ({
        ...prevState,
        tableCols: tableColumns,
        tableData: tableData
      }));
    } catch (error) {
      toast.error(ErrorMessage?.accountCost)
    }
    finally{
      setLoading(false);
    }
  };

  const getInsights = async (postParams) => {
    setLoading(true);

    try {
      const result = await axios.post(API_URLS?.CXO?.getYearInsights, postParams);
      setInsightsData(prevState => ({
        ...prevState,
        insightsKey: result?.data?.data[0]?.message.filter(data => data)
      }));
    }
    catch (er) {
      toast.error(ErrorMessage?.getYearInsights)
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row h-max">
        <div className="col-lg-12 fifthlayer p-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 text-light">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="first-layer-blue pt-2 px-3">Insights</div>
                    {
                      loading ?
                        <div className='loaderDiv'>
                          <Loader />
                        </div>
                        :
                        <div className='px-3'>
                          <ul className=' lastInsightsPoints'>
                            {insightsData?.insightsKey?.map(data => <li>{data}</li>)}
                          </ul>
                        </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-light h-max pb-3 pt-3 tableWrapperInsights lastInsightsTable">
                {
                  loading ?
                    <div className='loaderDiv'>
                      <Loader />
                    </div>
                    :
                    <ServicesTable tableHead={insightsData?.tableCols} tableData={insightsData?.tableData} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastInsightsLayer;
