import React, { useState } from "react";

import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";

import { FaPlay, FaStop } from "react-icons/fa";

import chipIcon from "../../../../../Images/chipIcon.svg";
import Details from "../../Servers/ServerDeatils/details";
import Monitoring from "../../Servers/ServerDeatils/monitoring";
import RemoteSessions from "../../Servers/ServerDeatils/remoteSession";

import "../../../../../style/Infra/serverDetails.scss";
const ViewInstanceModal = (props) => {
  // const handleChangeKey = (index, val) => {
  //   setKeyValPairs((prevKeyValPairs) => {
  //     const updatedKeyValPairs = prevKeyValPairs.map((pair, i) =>
  //       i === index ? { ...pair, key: val } : pair
  //     );
  //     return updatedKeyValPairs;
  //   });
  // };

  // const handleChangeValue = (index, val) => {
  //   setKeyValPairs((prevKeyValPairs) => {
  //     const updatedValues = prevKeyValPairs.map((data, i) =>
  //       index === i ? { ...data, value: val } : data
  //     );
  //     return updatedValues;
  //   });
  // };

  // const removeKeyValuePair = (index) => {
  //   setKeyValPairs((prevData) => {
  //     const removedKey = prevData.filter((data, ind) => {
  //       return ind !== index;
  //     });
  //     return removedKey;
  //   });
  // };

  // const onSearch = (data) => {
  //   props.onHide();
  //   const updatedData = data.filter(
  //     (data) => data?.key != "" && data?.value != ""
  //   );
  //   props.getKeyValPairs(updatedData);
  // };
console.log(props,'<>>props');
  return (
    <div className="serverDetails">
      <Modal
        scrollable
        fullscreen={"xl-down"}
        size="xl"
        {...props}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Instance Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="grid-example">
          <div className="container serverDetails">
            <div className="d-flex justify-content-between pt-3">
              <div className="d-flex flex-wrap ">
                <label className="server_style">server</label>
                <span className="server_name">
                  / <img src={chipIcon} alt="chip_icon"></img>{" "}
                  {props?.serverDetails?.instanceName || "-"}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-around shadow pt-4">
              <div className="">
                <label className="titles">Name</label>
                <p className="subdata">
                  {props?.serverDetails?.instanceName || "-"}
                </p>
              </div>
              <div className="">
                <label className="titles">Instance Id</label>
                <p className="subdata">
                  {props?.serverDetails?.instanceId || "-"}
                </p>
              </div>
              <div className="">
                <label className="titles">Operating System</label>
                <p className="subdata">
                  {props?.serverDetails?.operatingSystem || "-"}
                </p>
              </div>
              <div className="">
                <label className="titles">Account Name</label>
                <p className="subdata">
                  {props?.serverDetails?.accountName || "-"}
                </p>
              </div>
              <div className="">
                <label className="titles">Region Name</label>
                <p className="subdata">
                  {props?.serverDetails?.regionName || "-"}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <Tabs defaultActiveKey="Instances" className="mb-3">
                <Tab eventKey="Instances" title="Details" className="tab">
                  <Details data={props?.serverDetails} />
                </Tab>
                <Tab eventKey="Monitoring" title="Monitoring" className="tab">
                  <Monitoring
                    instanceId={props?.serverDetails?.instanceId}
                    accountName={props?.serverDetails?.accountName}
                    accountId={props?.serverDetails?.accountId}
                    />
                </Tab>
                <Tab
                  eventKey="RemoteSessions"
                  title="Remote Sessions"
                  className="tab"
                >
                  <RemoteSessions
                    instanceId={props?.serverDetails?.instanceId}
                    accountId={props?.serverDetails?.accountId}
                    roleArn={props?.serverDetails?.roleArn}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewInstanceModal;
