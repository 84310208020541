import React, { useEffect, useReducer, useState } from "react";

import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import DeleteModal from "./deleteModal";
import axios from "../../../../api/axios";
import cameraIcon from "../../../../Images/camera.svg";
import searchIcon from "../../../../Images/search.svg";
import refreshIcon from "../../../../Images/refresh.svg";
import { API_URLS } from "../../../../constants/API_URLS";
import { toastIds } from "../../../../constants/constant";
import deleteIcon from "../../../../Images/deleteIcon.svg";
import CommonTooltip from "../../../../components/Tooltip";
import filterIcon from "../../../../Images/filterFilled.svg";
import CreateSnapshotModal from "../volumes/createSnapshotModal";
import { ToastError, ToastSuccess } from "../../../../utils/utils";
import CommonTable from "../../../../components/Infra/Table/table";
import cameraIconDisabled from "../../../../Images/cameraDisabled.svg";
import deleteIconDisabled from "../../../../Images/deleteIconDisabled.svg";
import TableButton from "../../../../components/common/TableHeader/tablebutton";
import TagSearchModal from "../../../../components/Infra/TagSearchModal/tagSearchModal";
import TableColumnSearch from "../../../../components/common/TableSearchInput/tableSearch";

import "../../../../style/Infra/snapshots.scss";
const initialState = {
  openModalDelete: false,
  openModalTagSearch: false,
  isAdded: false,
  tableData: [],
  loading: false,
  selectedRowsTable: null,
  tableDataRaw: [],
  selectedRow: [],
  openCreateSnapshotModal: false,
  currentPage: 1,
  filterCheckboxDisabled: false,
  resetPagination: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default function Snapshots() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const setData = (data) => {
    dispatch({ type: "SET_DATA", payload: data });
  };
  const renderTooltip = (props, data) => (
    <Tooltip id="button-tooltip" {...props}>
      {data}
    </Tooltip>
  );

  //   <OverlayTrigger
  //   placement="right"
  //   delay={{ show: 250, hide: 400 }}
  //   overlay={renderTooltip}
  // >
  //   <Button variant="success">Hover me to see</Button>
  // </OverlayTrigger>
  const columns = [
    {
      name: "Snapshot ID",
      selector: (row) => CommonTooltip(row.snapshotId),
       
    },
    {
      name: "Snapshot Name",
      selector: (row) => CommonTooltip(row.snapshotName),
       
    },

    {
      name: "Volume Size",
      selector: (row) => CommonTooltip(row.description),
       
    },
    {
      name: "Description",
      selector: (row) => CommonTooltip(row.description),
       
    },
    {
      name: "Status ",
      selector: (row) => row.status,
       
    },
    {
      name: "Volume ID",
      selector: (row) => CommonTooltip(row.volumeId),
       
    },
    {
      name: "Encrypted",
      selector: (row) => row.encrypted,
       
    },

    {
      name: "Account Number",
      selector: (row) => row.accountNumber,
       
    },

    {
      name: "Account Name",
      selector: (row) => row.accountName,
       
    },
    {
      name: "Encrypted",
      selector: (row) => row.encrypted,
       
    },
    {
      name: "Start Time",
      selector: (row) => row.startTime,
       
    },
    {
      name: "Progress",
      selector: (row) => row.progress,
       
    },
  ];

  const getTableData = (snapShotId) => {
    setData({ loading: true, tableData: [] });
    let apiURL = API_URLS.infra.storage.snapShot.getSnapShotTable;
    if (snapShotId) {
      apiURL += `?snapshotId=${snapShotId}`;
    }

    axios
      .get(apiURL)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setData({ tableDataRaw: response?.data?.data[0] });
          let result = response?.data?.data[0];
          result = result?.map((data) => {
            let newItem = {
              snapshotId: data?.snapshotId || "",
              snapshotName: data?.snapshotName || "",
              volumeSize: data?.volumeSize || "",
              description: data?.description || "",
              status: data?.status || "",
              volumeId: data?.volumeId || "",
              owner: data?.owner || "",
              encrypted: data?.encrypted || "",
              accountNumber: data?.accountId || "",
              accountName: data?.accountName || "",
              regionCode: data?.regionCode || "",
              regionName: data?.regionName || "",
              project: data?.project || "",
              environment: data?.environment || "",
              startTime: moment(data?.startTime).format("MM-DD-YYYY HH:mm:ss"),
              progress: data?.progress || "",
            };
            return newItem;
          });
          setData({ tableData: result });
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      )
      .finally(() => setData({ loading: false }));
  };

  const onChangeFilter = (val, col) => {
    if (val) {
      let updatedData = state.tableData.filter((data, index) =>
        data[col].toLowerCase().includes(val.toLowerCase())
      );
      let mergedData = [SearchObject, ...updatedData];
      setData({ tableData: mergedData });
    } else {
      const result = state.tableDataRaw.map((data) => {
        let newItem = {
          snapshotId: data?.snapshotId || "",
          snapshotName: data?.snapshotName || "",
          volumeSize: data?.volumeSize || "",
          description: data?.description || "",
          status: data?.status || "",
          volumeId: data?.volumeId || "",
          owner: data?.owner || "",
          encrypted: data?.encrypted || "",
          accountNumber: data?.accountNumber || "",
          accountName: data?.accountName || "",
          regionCode: data?.regionCode || "",
          regionName: data?.regionName || "",
          project: data?.project || "",
          environment: data?.environment || "",
          startTime: moment(data?.startTime).format("MM-DD-YYYY HH:mm:ss"),
          progress: data?.progress || "",
        };
        return newItem;
      });
      let mergedData = [SearchObject, ...result];
      setData({ tableData: mergedData });
    }
  };

  const SearchObject = {
    snapshotId: (
      <TableColumnSearch
        column={"snapshotId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    snapshotName: (
      <TableColumnSearch
        column={"snapshotName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeSize: (
      <TableColumnSearch
        column={"volumeSize"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    description: (
      <TableColumnSearch
        column={"description"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    status: (
      <TableColumnSearch
        column={"status"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    volumeId: (
      <TableColumnSearch
        column={"volumeId"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    owner: (
      <TableColumnSearch
        column={"owner"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    encrypted: (
      <TableColumnSearch
        column={"encrypted"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountNumber: (
      <TableColumnSearch
        column={"accountNumber"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    accountName: (
      <TableColumnSearch
        column={"accountName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionCode: (
      <TableColumnSearch
        column={"regionCode"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    regionName: (
      <TableColumnSearch
        column={"regionName"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    project: (
      <TableColumnSearch
        column={"project"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    environment: (
      <TableColumnSearch
        column={"environment"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
    startTime: (
      <TableColumnSearch
        column={"startTime"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),

    progress: (
      <TableColumnSearch
        column={"progress"}
        onChange={(val, col) => {
          onChangeFilter(val, col);
        }}
      ></TableColumnSearch>
    ),
  };

  const OnSnapshotOperation = (data) => {
    axios
      .post(API_URLS?.storage?.snapShot?.snapshotOperation, data)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          ToastSuccess(response?.data?.statusMessage, toastIds.successToastId);
          getTableData();
        } else {
          ToastError(response?.data?.statusMessage, toastIds.errorToastId);
        }
      })
      .catch((error) =>
        ToastError(error?.response?.data?.statusMessage, toastIds.errorToastId)
      );
  };

  const OnFilterClick = () => {
    if (state.currentPage == 1) {
      const onlyObjectsWithStrings = state.tableData.filter((data) => {
        return Object.values(data).some((innerData) => {
          return typeof innerData !== "object";
        });
      });

      if (state.isAdded) {
        setData({
          tableData: onlyObjectsWithStrings,
          filterCheckboxDisabled: false,
        });
      } else {
        setData({
          tableData: [SearchObject, ...onlyObjectsWithStrings],
          filterCheckboxDisabled: true,
        });
      }
    } else {
      if (state.isAdded) {
        setData({ filterCheckboxDisabled: false });
      } else {
        setData({
          tableData: [SearchObject, ...state.tableData],
          filterCheckboxDisabled: true,
        });
      }
    }
    setData({ isAdded: !state.isAdded, resetPagination: true });
  };

  useEffect(() => {
    getTableData();
  }, []);

  useEffect(() => {
    const selectedSnapshotIds = state.selectedRowsTable?.selectedRows.map(
      (data) => {
        return data.snapshotId;
      }
    );
    setData({
      selectedRow: state.tableDataRaw.filter((data) =>
        selectedSnapshotIds.includes(data?.snapshotId)
      ),
    });
  }, [state.selectedRowsTable]);

  return (
    <div className=" commmon_stye  mt-3">
      <h5 className=" text-start ms-1">Snapshots</h5>
      <DeleteModal
        selectedRows={state.selectedRow}
        onDelete={(data) => OnSnapshotOperation(data)}
        show={state.openModalDelete}
        onHide={() => setData({ openModalDelete: false })}
      ></DeleteModal>
      <TagSearchModal
        type={"Snapshot Id"}
        show={state.openModalTagSearch}
        onHide={() => setData({ openModalTagSearch: false })}
        getKeyValPairs={(data) => {
          getTableData(data);
        }}
      ></TagSearchModal>
      <CreateSnapshotModal
        isCreateImage
        selectedRows={state.selectedRow}
        onCreateImage={(data) => OnSnapshotOperation(data)}
        show={state.openCreateSnapshotModal}
        onHide={() => setData({ openCreateSnapshotModal: false })}
      />
      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap">
            {state.selectedRowsTable?.selectedCount === 1 ? (
              <TableButton
                icon={
                  <img
                    className="snapshot_tableIcons"
                    src={cameraIcon}
                    alt="cameraIcon"
                    onClick={() =>
                      setData({
                        openCreateSnapshotModal: !state.openCreateSnapshotModal,
                      })
                    }
                  ></img>
                }
                title={"Create Image"}
              />
            ) : (
              <TableButton
                icon={
                  <img
                    className="snapshot_tableIcons"
                    src={cameraIconDisabled}
                    alt="cameraIcon"
                  ></img>
                }
                title={"Create Image"}
              />
            )}
            {state.selectedRowsTable?.selectedCount > 0 ? (
              <TableButton
                icon={
                  <img
                    className="snapshot_tableIcons"
                    src={deleteIcon}
                    alt="deleteIcon"
                    onClick={() =>
                      setData({ openModalDelete: !state.openModalDelete })
                    }
                  ></img>
                }
                title={"Delete"}
              />
            ) : (
              <TableButton
                icon={
                  <img
                    className="snapshot_tableIcons"
                    src={deleteIconDisabled}
                    alt="deleteIcon"
                  ></img>
                }
                title={"Delete"}
              />
            )}
          </div>
          <div className="d-flex flex-wrap">
            <TableButton
              icon={
                <img
                  className="snapshot_tableIcons"
                  src={searchIcon}
                  alt="searchIcon"
                  onClick={() =>
                    setData({ openModalTagSearch: !state.openModalTagSearch })
                  }
                ></img>
              }
              title={"Tag Search"}
            />
            <TableButton
              icon={
                <img
                  className="snapshot_tableIcons"
                  src={filterIcon}
                  alt="filterIcon"
                  onClick={() => OnFilterClick()}
                ></img>
              }
              title={"Filter"}
            />
            <TableButton
              icon={
                <img
                  className="snapshot_tableIcons"
                  src={refreshIcon}
                  alt="refreshIcon"
                  onClick={() => {
                    getTableData();
                  }}
                ></img>
              }
              title={"Refresh"}
            />
          </div>
        </div>

        <CommonTable
          removeSearchCheckbox={state.filterCheckboxDisabled}
          onChangePage={(data) => {
            setData({ resetPagination: false, currentPage: data });
          }}
          resetPagination={state.resetPagination}
          columns={columns}
          onSelectedRowsChange={(data) => setData({ selectedRowsTable: data })}
          data={state.tableData}
          selectableRows={true}
          loading={state.loading}
        />
      </div>
    </div>
  );
}
