import React, { useEffect, useState } from "react";
// src\style
import "../../../../../style/Infra/serverGroupName.scss";
import { Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import CommonTable from "../../../../../components/Infra/Table/table";

export default function ServerGroupsName() {
  const columns = [
    {
      name: "Instance ID",
      selector: (row) => row.id,
       
    },
    {
      name: "Instance Name",
      selector: (row) => row.name,
       
    },
    {
      name: "Account Name",
      selector: (row) => row.instance,
       
    },
    {
      name: "Account Number",
      selector: (row) => row.size,
       
    },
    {
      name: "rgion Name",
      selector: (row) => row.type,
       
    },
  ];
  const data = [
    {
      id: "1-ios",
      name: "clm",
      instance: "test",
      size: "1988855",
    },
  ];
  
  return (
    <div className="container-fluid serverGroupName">
      <h6 className=" text-start ms-1 header">
        Server Groups <span className="subheader"> / demo</span>
      </h6>
      <div className="d-flex justify-content-around shadow pt-4">
        <div className="">
          <label className="titles">Name</label>
          <p className="subdata">demo name</p>
        </div>
        <div className="">
          <label className="titles">Description</label>
          <p className="subdata">deno description</p>
        </div>
        <div className="">
          <label className="titles">Instance Count</label>
          <p className="subdata">1 instance</p>
        </div>
      </div>
      <div className="mt-4">
        <Tabs defaultActiveKey="Offers" className="mb-3">
          <Tab eventKey="Offers" title="Instances" className="tab">
          </Tab>
        </Tabs>
      </div>
      <div className="container_style">
        <div className="d-flex flex-wrap justify-content-between top-container mt-4">
          <div className="d-flex gap-3 flex-wrap"></div>
          <div className="d-flex gap-3 flex-wrap ">
            <lable className="top-content">Filter</lable>
            <lable className="top-content">Refresh</lable>
          </div>
        </div>
        <CommonTable columns={columns} data={data} selectableRows={true} />
      </div>
    </div>
  );
}
