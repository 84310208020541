import React from "react";

import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";



import "../../style/Infra/header.scss";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="mx-3">
      <Nav
        variant="tabs"
      >
        <Nav.Item >
          <Nav.Link active={window.location.pathname.includes('/inventory')} onClick={() => navigate("/inventory")}>Inventory</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={window.location.pathname.includes('/compute')} onClick={() => navigate("/compute")}>Compute</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={window.location.pathname.includes('/storage')} onClick={() => navigate("/storage")}>Storage</Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}
