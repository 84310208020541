import React, { createContext, useEffect, useState } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "./components/Sidebar/index";
import "react-multi-email/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./App.scss";
// import Router from "./Router";
import { LayoutConfig } from "./LayoutConfig";
import RoutesWrapper from "./Router";
import { ROUTE_PATH } from "./constants/Routes";
import { useSelector } from "react-redux";

const TenantContext = createContext();

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarFixedAndOpen, setisSidebarFixedAndOpen] = useState(false);
  const [isSmallScreenSidebarOpen, setIsSmallScreenSidebarOpen] =
    useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarFixedAndOpen);
    setisSidebarFixedAndOpen(!isSidebarFixedAndOpen);
  };

  const sidebarOpen = useSelector((state) => state.openSidebar.sidebarOpen);

  const toggleSmallScreenSidebar = () => {
    setIsSmallScreenSidebarOpen(!isSmallScreenSidebarOpen);
  };
  
  const { loginResponse } = useSelector((data) => data.auth);

  useEffect(() => {
    setIsSmallScreenSidebarOpen(sidebarOpen);
  }, [sidebarOpen]);
  // useEffect(() => {
  //   const disableRightClick = (e) => {
  //     e.preventDefault();
  //   };

  //   const disableCtrlShiftI = (e) => {
  //     if (
  //       ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "I" ||e.key === "i"
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", disableRightClick);
  //   document.addEventListener("keydown", disableCtrlShiftI);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //     document.removeEventListener("keydown", disableCtrlShiftI);
  //   };
  // }, []);
  useEffect(() => {
    if (!loginResponse?.token) {
      setIsSidebarOpen(false);
    }
  }, [loginResponse]);

  return (
    <Router>
      {loginResponse?.token ? (
        <div
          className={`Layout d-flex flex-column flex-grow-1 ${
            isSidebarFixedAndOpen ? "maximised" : "minimised"
          } ${
            !isSidebarFixedAndOpen && isSidebarOpen ? "hovered" : "unhovered"
          }`}
        >
          <div className="d-flex flex-row flex-column flex-grow-1">
            <Sidebar
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isSidebarFixedAndOpen={isSidebarFixedAndOpen}
              toggleSmallScreenSidebar={toggleSmallScreenSidebar}
              isSmallScreenSidebarOpen={isSmallScreenSidebarOpen}
            />
            <div
              className={`${
                !window.location?.pathname.includes(ROUTE_PATH?.CXO?.dashboard)
                  ? "Layout__wrapper d-flex flex-column flex-fill"
                  : "Layout__wrapper headerHidden d-flex flex-column flex-fill"
              }`}
            >
              <div className="Layout__wrapper-content flex-grow-1 ">
                <RoutesWrapper
                  isSmallScreenSidebarOpen={isSmallScreenSidebarOpen}
                  toggleSmallScreenSidebar={toggleSmallScreenSidebar}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <RoutesWrapper
          toggleSmallScreenSidebar={toggleSmallScreenSidebar}
          isSmallScreenSidebarOpen={isSmallScreenSidebarOpen}
        />
      )}
    </Router>
  );
};

export { TenantContext };

export default App;
