import React from "react";
import { Chart, ArcElement, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import '../../../style/Infra/Charts/doughnut.scss'
Chart.register(ArcElement, Legend, Tooltip);

const DoughnutChart = (props) => {
  const Doughnutoptions = {
    borderWidth:7,
    hoverBorderWidth:1,
    cutout: props.cutout,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: "Sample Text", // Add your desired text here
            font: {
              size: 20,
              weight: "bold",
            },
          },
        ],
      },
      legend: {
        pointStyle:'circle',
        display: props.display, // Optional: hide the legend if needed
        position: props.position,
        labels: {
          boxWidth: 15, // Customize the width of the legend box here
          boxHeight: 15,
        },
      },
    },
  };
  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "top";
        var text = totalResourcesCount(),
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2.8;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];
  const totalResourcesCount=()=>{
    if(props?.total){
      const data =props?.data?.datasets;

      let count =0;
      data.forEach((item) => {
        count += item.data.reduce((sum, value) => sum + value, 0);
      });
      return count;
    }
  }
  return (
    <div className={props.className || "doughnut"}>
      <Doughnut
        data={props.data}
        options={Doughnutoptions}
        plugins={props?.total ? plugins : []}
       
      />
    </div>
  );
};
export default DoughnutChart;
