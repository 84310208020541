import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  checkPeriods: [],
  cloudeAccount:'',
  tagValue:'Tagged-UnTagged'
};
const periodCheckboxSlice = createSlice({
  name: "periodCheckbox",
  initialState,
  reducers: {
    selectPeriod: (state, action) => {
      state.checkPeriods = action.payload;
    },
    cloudeAccount:(state,action)=>{
      state.cloudeAccount = action.payload
    },
    tagsValue:(state,action)=>{
      state.tagValue = action.payload
    }
  },
});

export const { selectPeriod,cloudeAccount,tagsValue } = periodCheckboxSlice.actions;
export default periodCheckboxSlice.reducer;

