import React, { useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { IconButton } from '@mui/material';
import Button from 'react-bootstrap/Button';

import { FirstLetterCapital } from '../../../utils/utils';

import './CreateUpdateModal.scss';

function CreateUpdateModal(props) {
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordType,setPasswordType]=useState(false);
    const [confPasswordType,setConfPasswordType]=useState(false);
    const [userNameError, setUserNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const onsubmit = () => {
        if (!userName || userName.trim() === '') {
            setUserNameError('Please enter a valid username.');
            return;
        }
        else {
            setUserNameError();
        }

        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        else {
            setEmailError();
        }


        if (props?.actionType === 'create') {
            if (!password || password.length < 6) {
                setPasswordError('Password should be at least 6 characters long.');
                return;
            }
            else {
                setPasswordError();
            }
            if (!confirmPassword || confirmPassword.length < 6) {
                setConfirmPasswordError('Password should be at least 6 characters long.');
                return;
            }
            else {
                setConfirmPasswordError();
            }
            if (password !== confirmPassword) {
                setConfirmPasswordError("Passwords don't match.");
                return;
            }
            else {
                setConfirmPasswordError();
            }
            props?.getData({
                userName: userName,
                email: email,
                password: password
            })
        }
        else {
            props?.getData({
                userName: userName,
                email: email,
            })
        }
        props?.onHide();

    }

    useEffect(() => {
        const updatedData = props?.updateData;
        if (props?.actionType != 'create') {
            setUserName(updatedData.empUsername)
            setEmail(updatedData?.empEmail)
            setPassword(updatedData?.password)
        }
    }, [props?.actionType, props?.updateData])


    return (
        <Modal
            scrollable
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {FirstLetterCapital(props?.actionType)} User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='createUser'>
                    <form action="" className="form">
                        <div className="Group">
                            <label htmlFor="UserName">User Name</label>
                            <input value={userName} onChange={(e) => setUserName(e?.target?.value)} id="UserName" type="UserName" />
                            {
                                userNameError && <p className='error-msg'>{userNameError}</p>
                            }
                        </div>
                        <div className="Group">
                            <label htmlFor="Email">Email</label>
                            <input value={email} onChange={(e) => setEmail(e?.target?.value)} id="Email" type="email" />
                            {
                                emailError && <p className='error-msg'>{emailError}</p>
                            }</div>
                        {
                            props?.actionType === 'create' &&
                            <>
                                <div className="Group">
                                    <label htmlFor="Password">Password</label>

                                    <div className='createUser_passwordInputWrapper'>
                                        <IconButton className='createUser_passwordInputWrapper_eyeBtn' onClick={()=>setPasswordType(!passwordType)} size='small' aria-label="delete" color="primary">
                                            <i className={passwordType?"fa fa-eye-slash":"fa fa-eye"} aria-hidden="true"></i>
                                        </IconButton>
                                        <input value={password} type={passwordType?'text':'password'} onChange={(e) => setPassword(e?.target?.value)} id="Password" />
                                        {
                                            passwordError && <p className='error-msg'>{passwordError}</p>
                                        }
                                    </div>
                                </div>

                                <div className="Group">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <div className='createUser_passwordInputWrapper'>
                                        <IconButton  onClick={()=>setConfPasswordType(!confPasswordType)} className='createUser_passwordInputWrapper_eyeBtn' size='small' aria-label="delete" color="primary">
                                            <i className={confPasswordType?"fa fa-eye-slash":"fa fa-eye"} aria-hidden="true"></i>
                                        </IconButton>
                                        <input value={confirmPassword} type={confPasswordType?'text':'password'} onChange={(e) => setConfirmPassword(e?.target?.value)} id="confirmPassword" />
                                        {
                                            confirmPasswordError && <p className='error-msg'>{confirmPasswordError}</p>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant='outline-primary'>Close</Button>
                <Button onClick={() => onsubmit()}>{FirstLetterCapital(props?.actionType)}</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default CreateUpdateModal;